import EmailIcon from '@mui/icons-material/Email';
import Badge from '@mui/material/Badge';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import React from 'react';
import { IActiveProgramCategoryDto, IProgramCategoryDto } from 'src/types/programs';

interface IProgramCategoriesMessages {
  programCategories?: IProgramCategoryDto[];
  activeProgramCategory: IActiveProgramCategoryDto;
}

const ProgramCategoriesMessages: React.FC<IProgramCategoriesMessages> = (props) => {
  const { programCategories, activeProgramCategory } = props;

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 12,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#FFEAAE',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#FFEAAE',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 10,
      borderRadius: 5,
    },
  }));

  return (
    <Stepper
      alternativeLabel
      activeStep={activeProgramCategory?.sortOrder}
      connector={<QontoConnector />}
    >
      {programCategories &&
        programCategories.map((item, index) => (
          <Step key={index}>
            <StepLabel
              StepIconProps={{
                icon: (
                  <Badge color="primary" badgeContent={item?._count.programTopics}>
                    <EmailIcon fontSize="large" color="primary" />
                  </Badge>
                ),
              }}
              sx={{
                '& .MuiStepIcon-root': {
                  width: 50,
                  height: 50,
                },
              }}
            >
              {item?.name}
            </StepLabel>
          </Step>
        ))}
    </Stepper>
  );
};

export default ProgramCategoriesMessages;
