import {
  Container,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Header from "src/components/Header";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { paths } from "src/routes/paths";
import { ResDataType } from "src/api/apiUrls";
import ProgramBeneficiariesTableRow from "src/sections/programs/program-beneficiaries/ProgramBeneficiariesTableRow";
import ContactUsTableRow from "src/sections/contact-us/ContactUsTableRow";
import Card from "@mui/material/Card";

const TABLE_HEAD = [
  { id: "1", label: "Name" },
  { id: "2", label: "Message" },
  { id: "3", label: "Email" },
  { id: "4", label: "Received date" },
  { id: "5", label: "Updated at" },
  { id: "6", label: "Actions" },
];

const AssignedToMeTable = () => {
  const table = useTable({ defaultRowsPerPage: 10 });
  const { useGetListApi } = useApiServices();

  const { data, isLoading } = useGetListApi<ResDataType["contactUs"]>({
    url: API_URLS.ASSIGNED_TO_ME,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
    },
  });

  return (
    <Card>
      <TableContainer>
        <Scrollbar>
          <Table>
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {isLoading
                ? [...Array(table.rowsPerPage)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: 80 }} />
                  ))
                : data!?.items?.map(row => (
                    <ContactUsTableRow key={row.id} row={row} isInAssignedToMe />
                  ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={data?.totalItems!}
        page={data!?.currentPage ? +data!?.currentPage - 1 : 1}
        rowsPerPage={table.rowsPerPage}
        onPageChange={table?.onChangePage}
        onRowsPerPageChange={table?.onChangeRowsPerPage}
      />
    </Card>
  );
};

export default AssignedToMeTable;
