import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import Restricted from "src/PermissionProvider/Restricted";
import BlogsTableRow from "src/sections/blogs/BlogsTableRow";
import BlogsTableToolbar from "src/sections/blogs/BlogsTableToolbar";
import { PrmsEnum } from "src/types/permissions";

const TABLE_HEAD = [
  { id: "1", label: "Blog title" },
  { id: "2", label: "Category" },
  { id: "3", label: "Published by" },
  { id: "4", label: "Status" },
  { id: "5", label: "Last updated" },
  { id: "6", label: "Views" },
  { id: "7", label: "Actions" },
];

export default function Blogs () {
  const settings = useSettingsContext();
  const isLight = settings.themeMode === "light";
  const table = useTable({ defaultRowsPerPage: 10 });
  const { useGetListApi } = useApiServices();
  const methods = useForm({
    defaultValues: {
      blogCategoryId: 0,
      translations: {
        some: {
          title: "",
        },
      },
    },
  });

  console.log(methods.watch());

  const [search, setSearch] = useState({
    blogCategoryId: 0,
    translations: {
      some: {
        title: "",
      },
    },
  });

  console.log(search);

  const { data, isLoading, error, isRefetching } = useGetListApi<
    ResDataType["blogs"]
  >({
    url: API_URLS.BLOGS,
    parameters: {
      "translations.some.title": search?.translations?.some?.title,
      blogCategoryId: search.blogCategoryId
        ? search.blogCategoryId
        : undefined,
    },
  });

  return (
    <>
      <Helmet>
        <title>Blogs</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isRefetching}
          errorMessage={error}
        >
          <Grid container spacing={4} my={1}>
            <Restricted to={PrmsEnum.CREATE_BLOG}>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='flex-end'>
                  <Button variant='contained' color='primary'>
                    <Link
                      style={{
                        color: isLight ? "black" : "white",
                        textDecoration: "none",
                      }}
                      to='new'
                    >
                      Create new post
                    </Link>
                  </Button>
                </Box>
              </Grid>
            </Restricted>
            <Grid item xs={12}>
              <FormProvider methods={methods}>
                <Card>
                  <BlogsTableToolbar
                    values={methods.watch()}
                    setSearch={setSearch}
                    reset={methods.reset}
                  />
                  <TableContainer>
                    <Scrollbar>
                      <Table>
                        <TableHeadCustom headLabel={TABLE_HEAD} />
                        <TableBody>
                          {isLoading ? (
                            [...Array(table.rowsPerPage)].map((i, index) => (
                              <TableSkeleton key={index} sx={{ height: 80 }} />
                            ))
                          ) : (
                            <>
                              {data!.items?.map(row => (
                                <BlogsTableRow key={row.id} row={row} />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                  {data && (
                    <TablePaginationCustom
                      count={data?.totalItems}
                      page={data.currentPage ? +data.currentPage - 1 : 1}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table?.onChangePage}
                      onRowsPerPageChange={table?.onChangeRowsPerPage}
                    />
                  )}
                </Card>
              </FormProvider>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
