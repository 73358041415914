import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// components
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { Link } from "react-router-dom";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import AppButton from "src/components/common/AppButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import { ApplicationType } from "src/types/applications";

// ----------------------------------------------------------------------

type Props = {
  row: ApplicationType;
};

export default function ApplicationTableRow ({ row }: Props) {
  const dialog = useBoolean();
  const confirm = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{`${row.firstName} ${row.lastName}`}</Typography>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
          <Typography>{row.career.title}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.email}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{moment(row.createdAt).format("YYYY-MM-DD")}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Link to={`${row.id}`}>
            <Tooltip title='View' placement='bottom' arrow>
              <IconButton onClick={dialog.onTrue}>
                <Iconify
                  icon='mdi:eye-circle'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
}
