import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import ChangePreferencesDialog from "src/sections/users/users-details/preferences/change-preferences-dialog/ChangePreferencesDialog";
import { PERSONAL_PREFERENCES_LIST } from "src/sections/users/users-details/preferences/PreferencesLists";
import { PrmsEnum } from "src/types/permissions";

type changeType = {
  name: "subscriptionDetails" | "personalPreferences";
  label?: string;
  value?: string;
  options?: any;
  type?: string;
} | null;

const UserPreferences = () => {
  const settings = useSettingsContext();
  const { userId } = useParams();
  const { useGetItemApi } = useApiServices();
  const preferencesDialog = useBoolean();
  const notifications = useForm();
  const [change, setChange] = useState<changeType>(null);

  const {
    data: userData,
    isLoading,
    error,
    refetch,
    isRefetching,
  } = useGetItemApi<ResDataType["userProfile"]>({
    url: API_URLS.USER_PROFILE,
    id: userId,
    enabled: !!userId,
  });

  const handleChangeAllPersonalPreferences = () => {
    setChange({ name: "personalPreferences", label: "personal" });
    preferencesDialog.onTrue();
  };

  return (
    <>
      <Helmet>
        <title>Preferences</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isRefetching}
          errorMessage={error}
        >
          <FormProvider methods={notifications}>
            {/* #region Personal preferences */}
            <Stack spacing={6}>
              <Stack spacing={2} width={{ xs: "100%", sm: "50%" }}>
                <Box
                  display='flex'
                  justifyContent='space-between'
                  width='100%'
                  alignItems='center'
                >
                  <Typography fontWeight={700}>Personal preferences</Typography>
                  <Restricted to={PrmsEnum.UPDATE_USER}>
                    <Typography fontWeight={700}>
                      <Button onClick={handleChangeAllPersonalPreferences}>
                        Change all
                      </Button>
                    </Typography>
                  </Restricted>
                </Box>
                <Divider />
                <Stack spacing={2.5}>
                  {PERSONAL_PREFERENCES_LIST(userData!).map(e => (
                    <PreferenceItem key={e.name} {...e} />
                  ))}
                </Stack>
              </Stack>
            </Stack>
            {/* #endregion Personal preferences */}
          </FormProvider>
          <ChangePreferencesDialog
            open={preferencesDialog.value}
            onClose={preferencesDialog.onFalse}
            change={change}
            userData={userData}
            dialogTitle={`Customize user ${change?.label} preferences`}
          />
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default UserPreferences;

const PreferenceItem = ({
  value,
  name,
}: {
  value: string | Date;
  name: string;
}) => {
  if (name === "weekDay" && !value) return <> </>;
  const capitalizeWordsWithSlicing = (inputString: string) => {
    const words = inputString.split(/(?=[A-Z])/); // Split on capital letters
    const capitalizedWords = words.map(
      word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join(" ");
  };

  let valueCustomized: any = "";
  if (typeof value === "string")
    valueCustomized = value.slice(0, 1) + value.toLowerCase().slice(1);
  else if (!value) valueCustomized = "";
  else valueCustomized = moment(value).format("YYYY-MM-DD");
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      flexDirection={{ xs: "column", md: "row" }}
      textAlign='end'
    >
      <Typography textAlign='start' fontWeight={500}>
        {capitalizeWordsWithSlicing(name)}
      </Typography>
      <Typography>{valueCustomized || "No information provided"}</Typography>
    </Box>
  );
};
