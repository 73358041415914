import { MenuItem, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import { RHFAutocomplete, RHFSelect, RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { useBoolean } from "src/hooks/use-boolean";
import { IBaseStatusDto } from "src/types";

// ----------------------------------------------------------------------

type Props = {
  values: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
  reset: any;
  data: ResDataType["careers"];
};

export default function VacanciesTableToolbar ({
  values,
  setSearch,
  reset,
  data,
}: Props) {
  const openFilters = useBoolean();
  const { useGetListApi } = useApiServices();

  const renderHead = (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant='h6' sx={{ flexGrow: 1 }}>
        Filters
      </Typography>

      <IconButton onClick={openFilters.onFalse}>
        <Iconify icon='mingcute:close-line' />
      </IconButton>
    </Stack>
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: "flex-end", md: "center" }}
        direction={{
          xs: "column",
          md: "row",
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          spacing={2}
          flexGrow={1}
          sx={{ width: 1 }}
        >
          <Stack
            flexDirection='row'
            width={{ xs: "100%", md: "50%" }}
            alignItems='center'
          >
            <RHFTextField name='translations.some.title' />
            <IconButton
              onClick={() =>
                setSearch((preValue: any) => ({
                  ...preValue,
                  title: values.translations.some.title,
                }))
              }
            >
              <Iconify icon='eva:search-fill' />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>

      {/* Filters */}
    </>
  );
}
