import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha, Container, Grid, Stack, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, {
  RHFMultiSelect,
  RHFTextField
} from "src/components/hook-form";
import { Upload } from "src/components/upload";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import { AddProgramFormType } from "src/types/program";
import { UploadFileResponseType } from "src/types/upload-file-res";
import {
  NewProgramFormSchema,
  newProgramFormSchema
} from "./Add-new-program-schema";

const AddNewProgram = () => {
  // #region States
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<AddProgramFormType>();
  const { usePostApi, useGetListApi } = useApiServices();
  const [featureImage, setFeatureImage] = useState<File | string | null>(null);
  const [currentTabValue, setCurrentTabValue] = useState("en");
  // #endregion States

  // #region Services
  const {
    data: supportedLanguages,
    isLoading: isLanguagesLoading,
    error,
  } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.LANGUAGE_TYPE,
    onSuccess: (data: ResDataType["dropDowns"]) => {
      methods.setValue(
        "translations",
        data.map(e => ({
          name: "",
          header: "",
          description: "",
          duration: "",
          locale: e.value,
        }))
      );
      setCurrentTabValue(data.map(e => e.value)[0]);
    },
  });

  const { data: messageMediums, isLoading: areChannelsLoading } = useGetListApi<
    ResDataType["dropDowns"]
  >({
    url: API_URLS.MESSAGE_MEDIUM,
    onSuccess: (data: ResDataType["dropDowns"]) => {
    },
  });

  const { data: topicLengths, isLoading: areTpcLntLoading } = useGetListApi<
    ResDataType["dropDowns"]
  >({
    url: API_URLS.TOPIC_LENGTH,
    onSuccess: (data: ResDataType["dropDowns"]) => {
    },
  });

  const { mutate, isLoading } = usePostApi<AddProgramFormType>({
    url: API_URLS.PROGRAMS,
    onSuccess: () => {
      confirm.onFalse();
    },
  });

  const methods = useForm<AddProgramFormType>({
    defaultValues: newProgramFormSchema,
    resolver: yupResolver(NewProgramFormSchema(supportedLanguages?.length!)),
  });

  console.log(methods.watch())

  const { mutate: uploadPhoto, isLoading: isUploadingPhoto } = usePostApi({
    url: API_URLS.UPLOAD_FILE,
    withFormData: true,
    onSuccess: (data: UploadFileResponseType) => {
      methods.setValue("featureImage", data.filePath);
    },
  });
  // #endregion Services

  // #region form
  const {
    handleSubmit,
    formState: {
      errors: { featureImage: featureImageError },
    },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddProgramFormType) => {
      // @ts-ignore
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const indexOfCurrentLocale = (methods.watch().translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);

  const hasErrors = Object.keys(methods.formState.errors).length;
  // #endregion form

  // #region handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFeatureImage(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue,
      name:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.name! || "",
      header:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.header! || "",
      description:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.description! ||
        "",
      duration:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.duration! ||
        "",
    });
  }, [methods, indexOfCurrentLocale, currentTabValue]);

  useEffect(() => {
    if (featureImage && typeof featureImage !== "string") {
      uploadPhoto(featureImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureImage]);
  // #endregion useEffect

  return (
    <AppLoadingAndErrorWrapper
      isLoading={isLoading || isLanguagesLoading || isUploadingPhoto || areChannelsLoading || areTpcLntLoading}
      errorMessage={error}
    >
      <Container>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <Block label='Choose supported languages'>
                    <RHFMultiSelect
                      checkbox
                      name='language'
                      options={supportedLanguages! || []}
                    />
                  </Block>
                </Grid>
                <Grid item md={4}>
                  <Block label='Message medium'>
                    <RHFMultiSelect
                      checkbox
                      name='messageMedium'
                      options={messageMediums! || []}
                    />
                  </Block>
                </Grid>
                <Grid item md={4}>
                  <Block label='Topic length'>
                    <RHFMultiSelect
                      checkbox
                      name='topicLength'
                      options={topicLengths! || []}
                    />
                  </Block>
                </Grid>
              </Grid>
              <Tabs
                value={currentTabValue}
                onChange={handleChangeTab}
                sx={{
                  px: 3,
                  my: 3,
                  boxShadow: theme =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {supportedLanguages!?.map(tab => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>
              <Stack py={5} spacing={3}>
                <Block label='Upload image'>
                  <Upload
                    file={featureImage}
                    error={!!featureImageError}
                    onDrop={handleDropSingleFile}
                    onDelete={() => {
                      setFeatureImage(null);
                      methods.setValue("featureImage", "");
                    }}
                  />
                </Block>
                <Block label='Program title'>
                  <RHFTextField
                    name={`translations.${+indexOfCurrentLocale!}.name`}
                    sx={{ my: 3 }}
                  />
                </Block>
                <Block label='Program header'>
                  <RHFTextField
                    name={`translations.${+indexOfCurrentLocale!}.header`}
                    sx={{ my: 3 }}
                  />
                </Block>
                <Block label='Duration'>
                  <RHFTextField
                    name={`translations.${+indexOfCurrentLocale!}.duration`}
                    sx={{ my: 3 }}
                  />
                </Block>
                <Block label='Program description'>
                  <RHFTextField
                    name={`translations.${+indexOfCurrentLocale!}.description`}
                    sx={{ my: 3 }}
                    multiline
                    rows={12}
                  />
                </Block>
              </Stack>
            </Grid>
            {hasErrors ? <AddDialogsHelperText /> : null}
            <Grid item xs={12} md={3}>
              <AppLoadingButton label='Add' isLoading={isLoading} />
            </Grid>
          </Grid>
        </FormProvider>
      </Container>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
          }
        }}
        content='Are you sure you want to add this program?'
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </AppLoadingAndErrorWrapper>
  );
};

export default AddNewProgram;
