import { Typography, Stack } from '@mui/material';
import { StackProps } from '@mui/material/Stack';

interface BlockProps extends StackProps {
  label?: string;
  children: React.ReactNode;
  required?: boolean;
}

export function Block({
  label = 'RHFTextField',
  sx,
  children,
  required = true,
}: BlockProps) {
  return (
    <Stack spacing={1} sx={{ width: 1, ...sx }}>
      <Typography
        variant="caption"
        fontWeight={500}
        fontSize="14px"
        sx={{
          textAlign: 'left',
        }}
      >
        {label + (required ? ' *' : '')}
      </Typography>
      {children}
    </Stack>
  );
}
