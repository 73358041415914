import { MenuItem, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { useFormContext } from "react-hook-form/dist/useFormContext";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import {
  RHFAutocomplete,
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { useBoolean } from "src/hooks/use-boolean";
import { IBaseStatusDto } from "src/types";

// ----------------------------------------------------------------------

type Props = {
  values: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
  reset: any;
};

export default function ProgramUsersTableToolbar ({
  values,
  setSearch,
  reset,
}: Props) {
  const openFilters = useBoolean();
  const { useGetListApi } = useApiServices();
  const { data: programStatus } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.PROGRAM_USER_STATUS,
  });

  const { data: messageMediums } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.MESSAGE_MEDIUM,
  });

  const { data: programs } = useGetListApi<ResDataType["programs"]>({
    url: API_URLS.PROGRAMS,
  });

  const { data: categories } = useGetListApi<
    ResDataType["programCategoriesType"]
  >({
    url: API_URLS.PROGRAM_CATEGORIES,
  });

  const { data: users } = useGetListApi<ResDataType["users"]>({
    url: API_URLS.USERS,
  });

  const { programId } = useParams();

  const renderHead = (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant='h6' sx={{ flexGrow: 1 }}>
        Filters
      </Typography>

      <IconButton onClick={openFilters.onFalse}>
        <Iconify icon='mingcute:close-line' />
      </IconButton>
    </Stack>
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: "flex-end", md: "center" }}
        direction={{
          xs: "column",
          md: "row",
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          spacing={2}
          flexGrow={1}
          sx={{ width: 1 }}
        >
          <Stack flexDirection='row' width={{ xs: "100%", md: "50%" }}>
            <RHFAutocomplete
              name='userId'
              fullWidth
              options={
                users!?.items?.map(option => ({
                  label: option.firstName
                    ? option.firstName + option.lastName
                    : option.email!,
                  value: option.id,
                }))! || []
              }
              getOptionLabel={(option: IBaseStatusDto | string) =>
                (option as IBaseStatusDto).label
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
            />
            <IconButton
              onClick={() =>
                setSearch((preValue: any) => ({
                  ...preValue,
                  userId: values?.userId?.value,
                }))
              }
            >
              <Iconify icon='eva:search-fill' />
            </IconButton>
          </Stack>
          <Tooltip title='Filters' placement='bottom' arrow>
            <IconButton onClick={openFilters.onTrue}>
              <Iconify icon='ri:filter-line' width='50' height='50' />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      {/* Filters */}
      <Drawer
        anchor='right'
        open={openFilters.value}
        onClose={openFilters.onFalse}
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 280 },
        }}
      >
        {renderHead}
        <Divider />
        <Scrollbar sx={{ px: 2.5, py: 3 }}>
          <Stack spacing={3}>
            <Block label='Message medium'>
              <RHFSelect name='messageMedium' required={false}>
                {messageMediums?.map(medium => (
                  <MenuItem key={medium.value} value={medium.value}>
                    {medium.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            {!programId && (
              <Block label='Message program' required={false}>
                <RHFSelect name='programId'>
                  {programs?.items?.map(program => (
                    <MenuItem key={program.id} value={program.id}>
                      {program.name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Block>
            )}
            <Block label='Message category' required={false}>
              <RHFSelect name='programCategoryId'>
                {categories?.items.map(program => (
                  <MenuItem key={program.id} value={program.id}>
                    {program.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            <Block label='Message number' required={false}>
              <RHFTextField
                type='number'
                name='messageNumber'
                label='Enter a number'
              />
            </Block>
            <Block label='Message status' required={false}>
              <RHFSelect name='status'>
                {programStatus?.map(program => (
                  <MenuItem key={program.value} value={program.value}>
                    {program.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            <Block label='Start date' required={false}>
              <RHFDatePicker name='startDate' />
            </Block>
            <Block label='End date' required={false}>
              <RHFDatePicker name='endDate' />
            </Block>
            <Stack justifyContent='center' spacing={3} mt={20}>
              <AppButton
                label='Apply filters'
                onClick={() =>
                  setSearch({
                    ...values,
                    startDate: values.startDate
                      ? moment(values.startDate).format("YYYY-MM-DD")
                      : undefined,
                    endDate: values.endDate
                      ? moment(values.endDate).format("YYYY-MM-DD")
                      : undefined,
                  })
                }
              />
              <Typography
                align='center'
                fontSize={18}
                fontWeight={500}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  setSearch({
                    programId,
                  });
                  reset({ programId });
                }}
              >
                Reset all filters
              </Typography>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
