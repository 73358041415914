// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// components
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import { WebsitePageDto } from "src/types/website-pages";

// ----------------------------------------------------------------------

type Props = {
  row: WebsitePageDto;
};

export default function LegalContentTableRow ({ row }: Props) {
  const confirm = useBoolean();

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
        <Typography>{row.name}</Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Typography>{row.value}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
        <Typography>FROM_BE</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Restricted to={PrmsEnum.UPDATE_WEBSITE_PAGE}>
          <Tooltip title='Edit' placement='bottom' arrow>
            <Link to={`legal-content/${row.id}`}>
              <IconButton>
                <Iconify
                  icon='carbon:edit'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Link>
          </Tooltip>
        </Restricted>
      </TableCell>
    </TableRow>
  );
}
