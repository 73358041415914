import axios, { AxiosRequestConfig } from "axios";
// config
import { HOST_API } from "src/config-global";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: "chat",
  kanban: "kanban",
  calendar: "calendar",
  auth: {
    me: "/dashboard/auth-admin/profile",
    login: "/dashboard/auth-admin/login",
    register: "auth/register",
  },
  mail: {
    list: "mail/list",
    details: "mail/details",
    labels: "mail/labels",
  },
  post: {
    list: "post/list",
    details: "post/details",
    latest: "post/latest",
    search: "post/search",
  },
  product: {
    list: "product/list",
    details: "product/details",
    search: "product/search",
  },
};
