import { Button, Grid } from "@mui/material";

interface IProgramButtonsProps {
  programStatus: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const style = {
  fontWeight: "700px",
  fontSize: { md: "16px", xs: "12px" },
  color: "black",
  padding: { md: "16px 28px", xs: "10px" },
  border: "2px solid black",
  borderRadius: "70px",
  lineHeight: "24px",
  textTransform: "capitalize",
};

const ProgramButtons: React.FC<IProgramButtonsProps> = (
  props: IProgramButtonsProps
) => {
  const { programStatus, onClick } = props;

  const renderButton = () => {
    switch (programStatus) {
      case "COMPLETED":
        return (
          <Button
            sx={{ ...style }}
            variant='contained'
            color='secondary'
            // For get new program status
            data-my-attribute='RESTART'
            // For render dialog label
            data-button-key='Set your Restart date'
            onClick={onClick}
          >
            Restart program
          </Button>
        );
      case "UN_SUBSCRIBE":
        return (
          <Button
            sx={{ ...style }}
            variant='contained'
            color='secondary'
            // For get new program status
            data-my-attribute='RESTART'
            // For render dialog label
            data-button-key='Set your subscribe date'
            onClick={onClick}
          >
            Subscribe again
          </Button>
        );
      case "HOLD":
        return (
          <Button
            sx={{ ...style }}
            variant='contained'
            color='secondary'
            // For get new program status
            data-my-attribute='IN_PROGRESS'
            // For render dialog label
            data-button-key='Set your continue date'
            onClick={onClick}
          >
            Continue program
          </Button>
        );
      case "IN_PROGRESS":
        return (
          <Button
            sx={{ ...style }}
            variant='contained'
            color='secondary'
            // For get new program status
            data-my-attribute='HOLD'
            // For render dialog label
            data-button-key='Set your continue date'
            onClick={onClick}
          >
            Hold
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid item>{renderButton()}</Grid>
      {(programStatus === "HOLD" || programStatus === "IN_PROGRESS") && (
        <Grid item>
          <Button
            sx={{ ...style }}
            // For get new program status
            data-my-attribute='RESTART'
            // For render dialog label
            data-button-key='Set your Restart date'
            onClick={onClick}
          >
            Restart program
          </Button>
        </Grid>
      )}
    </>
  );
};

export default ProgramButtons;
