import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useEffect } from "react";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import Scrollbar from "src/components/scrollbar";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import AddProgramCategory from "./AddCategoryBadge";
import CategoryBadgesTableRow from "./CategoryBadgesTableRow";

const TABLE_HEAD = [
  { id: "1", label: "Badge" },
  { id: "2", label: "Program name" },
  { id: "3", label: "Category name" },
  { id: "4", label: "Created date" },
  { id: "5", label: "Last updated " },
  { id: "6", label: "Actions" },
];

const CategoryBadges = ({ setProgramCategoryNumber }: any) => {
  const addDialog = useBoolean();
  const { useGetListApi } = useApiServices();
  const table = useTable({ defaultRowsPerPage: 10 });

  const { data: categoryBadges, isLoading } = useGetListApi<
    ResDataType["badges"]
  >({
    url: API_URLS.BADGES,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
      badgeType: "PROGRAM_CATEGORY",
    },
  });

  useEffect(() => {
    if (categoryBadges) setProgramCategoryNumber(categoryBadges?.items?.length);
  }, [categoryBadges]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Restricted to={PrmsEnum.CREATE_BADGE}>
              <Box display="flex" justifyContent="flex-end" p={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addDialog.onTrue}
                >
                  Add a new badge
                </Button>
              </Box>
            </Restricted>
            <TableContainer>
              <Scrollbar>
                <Table>
                  <TableHeadCustom headLabel={TABLE_HEAD} />
                  <TableBody>
                    {isLoading ? (
                      [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: 80 }} />
                      ))
                    ) : (
                      <>
                        {categoryBadges?.items?.map((row: any) => (
                          <CategoryBadgesTableRow key={row.id} row={row} />
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            {categoryBadges && (
              <TablePaginationCustom
                count={categoryBadges?.totalItems}
                page={categoryBadges?.currentPage - 1}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
              />
            )}
          </Card>
        </Grid>
      </Grid>

      {/* Add Dialog */}
      {addDialog.value && (
        <AddProgramCategory
          open={addDialog.value}
          onClose={addDialog.onFalse}
        />
      )}
    </>
  );
};

export default CategoryBadges;
