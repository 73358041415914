import {
  Box,
  Button, Card,
  Grid,
  Table,
  TableBody,
  TableContainer
} from "@mui/material";
import Container from "@mui/material/Container";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable
} from "src/components/table";
import Restricted from "src/PermissionProvider/Restricted";
import FaqsTableRow from "src/sections/pages/faqs/FaqsTableRow";
import FaqsTableToolbar from "src/sections/pages/faqs/FaqsTableToolbar";
import { PrmsEnum } from "src/types/permissions";

const TABLE_HEAD = [
  { id: "1", label: "FAQs Type" },
  { id: "2", label: "Question" },
  { id: "3", label: "Answer" },
  { id: "4", label: "Last Updated" },
  { id: "5", label: "Actions" },
];

export default function FAQs () {
  const settings = useSettingsContext();
  const isLight = settings.themeMode === "light";
  const table = useTable({ defaultRowsPerPage: 10 });
  const { useGetListApi, useDeleteApi } = useApiServices();

  const methods = useForm({
    defaultValues: {
      type: "",
      translations: {
        some: {
          question: "",
          answer: "",
        },
      },
    },
  });

  const [search, setSearch] = useState({
    type: "",
    translations: {
      some: {
        question: "",
        answer: "",
      },
    },
  });

  const { data, isLoading, error, refetch, isRefetching } = useGetListApi<
    ResDataType["faqs"]
  >({
    url: API_URLS.FAQS,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
      "translations.some.question": search.translations.some.question,
      "translations.some.answer": search.translations.some.answer,
      type: search.type,
    },
  });

  const { mutate, isLoading: isDeleteLoading } = useDeleteApi({
    url: API_URLS.FAQS,
    onSuccess: () => {
      refetch();
    },
  });

  return (
    <>
      <Helmet>
        <title>FAQs</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isDeleteLoading || isRefetching}
          errorMessage={error}
        >
          <Grid container spacing={4} my={1}>
            <Restricted to={PrmsEnum.CREATE_FAQ}>
              <Grid item xs={12}>
                <Box display='flex' justifyContent='flex-end'>
                  <Button variant='contained' color='primary'>
                    <Link
                      style={{
                        color: isLight ? "black" : "white",
                        textDecoration: "none",
                      }}
                      to='faqs/new'
                    >
                      Add a new question
                    </Link>
                  </Button>
                </Box>
              </Grid>
            </Restricted>
            <Grid item xs={12}>
              <FormProvider methods={methods}>
                <Card>
                  <FaqsTableToolbar
                    values={methods.watch()}
                    reset={methods.reset}
                    setSearch={setSearch}
                  />
                  <TableContainer>
                    <Scrollbar>
                      <Table>
                        <TableHeadCustom headLabel={TABLE_HEAD} />
                        <TableBody>
                          {isLoading ? (
                            [...Array(table.rowsPerPage)].map((i, index) => (
                              <TableSkeleton key={index} sx={{ height: 80 }} />
                            ))
                          ) : (
                            <>
                              {data!.items?.map(row => (
                                <FaqsTableRow
                                  key={row.id}
                                  row={row}
                                />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                  {data && (
                    <TablePaginationCustom
                      count={data?.totalItems}
                      page={data.currentPage ? +data.currentPage - 1 : 1}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table?.onChangePage}
                      onRowsPerPageChange={table?.onChangeRowsPerPage}
                    />
                  )}
                </Card>
              </FormProvider>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
