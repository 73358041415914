import "src/utils/highlight";
import ReactQuill from "react-quill";
// @mui
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { alpha } from "@mui/material/styles";
import useApiServices from "src/api/useApiServices";
import { ASSETS_API } from "src/config-global";
import { API_URLS } from "src/api/apiUrls";
//
import { Typography } from "@mui/material";
import { EditorProps } from "./types";
import { StyledEditor } from "./styles";
import Toolbar, { formats } from "./toolbar";
import { LoadingScreen } from "../loading-screen";

// ----------------------------------------------------------------------


export default function Editor ({
  id = "minimal-quill",
  error,
  simple = false,
  helperText,
  sx,
  ...other
}: EditorProps) {
  const [url, setUrl] = useState("");
  const { usePostApi } = useApiServices();

  const { mutate, isLoading: isUploading } = usePostApi({
    url: API_URLS.UPLOAD_FILE,
    onSuccess (data) {
      setUrl(data.filePath);
    },
  });

  const quillRef: any = useRef();

  const imageHandler = useCallback(() => {
    const input: any = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const editor = quillRef.current.getEditor();
      const file = input!?.files[0];
      if (/^image\//.test(file.type)) {
        const formData = new FormData();
        formData.append("file", file);
        mutate(formData);
        editor.insertEmbed(editor.getSelection(), "div", "Loading");
      }
    };
  }, [mutate]);

  useEffect(() => {
    if (url && quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.insertEmbed(
        editor.getSelection(),
        "image",
        `${ASSETS_API}/${url}`
      );
    }
  }, [url]);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${id}`,
        handlers: {
          image: imageHandler,
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      syntax: true,
      clipboard: {
        matchVisual: false,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, imageHandler, url]
  );

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: theme => `solid 1px ${theme.palette.error.main}`,
            "& .ql-editor": {
              bgcolor: theme => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <Toolbar id={id} isSimple={simple} />

        <ReactQuill
          ref={quillRef}
          modules={modules}
          formats={formats}
          placeholder='Write something awesome...'
          {...other}
        />
      </StyledEditor>
      {isUploading && (
        <>
          <Typography>Uploading image</Typography>
          <LoadingScreen />
        </>
      )}
      {helperText && helperText}
    </>
  );
}
