import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import RowItem from "src/components/RowItem";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import ChangePreferencesDialog from "src/sections/users/users-details/preferences/change-preferences-dialog/ChangePreferencesDialog";
import { SUBSCRIPTION_DETAILS_LIST } from "src/sections/users/users-details/subscription-details/subscription-details-list";
import { PrmsEnum } from "src/types/permissions";

type changeType = {
  name: "subscriptionDetails" | "personalPreferences";
  label?: string;
  value?: string;
  options?: any;
  type?: string;
} | null;

const SubscriptionDetails = () => {
  const settings = useSettingsContext();
  const { userId } = useParams();
  const { useGetItemApi } = useApiServices();
  const preferencesDialog = useBoolean();
  const notifications = useForm();
  const [change, setChange] = useState<changeType>(null);

  const {
    data: userData,
    isLoading,
    error,
    isRefetching,
  } = useGetItemApi<ResDataType["userProfile"]>({
    url: API_URLS.USER_PROFILE,
    id: userId,
  });

  const handleChangeAllPersonalPreferences = () => {
    setChange({ name: "subscriptionDetails", label: "subscription details" });
    preferencesDialog.onTrue();
  };

  return (
    <>
      <Helmet>
        <title>Preferences</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isRefetching}
          errorMessage={error}
        >
          <FormProvider methods={notifications}>
            {/* #region Personal preferences */}
            <Stack spacing={2} width={{ xs: "100%", sm: "50%" }}>
              <Box
                display='flex'
                justifyContent='space-between'
                width='100%'
                alignItems='center'
              >
                <Typography fontWeight={700}>Subscription details</Typography>
                <Restricted to={PrmsEnum.UPDATE_USER}>
                  <Typography fontWeight={700}>
                    <Button onClick={handleChangeAllPersonalPreferences}>
                      Change all
                    </Button>
                  </Typography>
                </Restricted>
              </Box>
              <Divider />
              <Stack spacing={2.5}>
                {SUBSCRIPTION_DETAILS_LIST(userData!).map(e => (
                  <RowItem key={e.name} {...e} />
                ))}
              </Stack>
            </Stack>
            {/* #endregion Personal preferences */}
          </FormProvider>
          <ChangePreferencesDialog
            open={preferencesDialog.value}
            onClose={preferencesDialog.onFalse}
            change={change}
            userData={userData}
            dialogTitle={`Customize user ${change?.label} preferences`}
          />
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default SubscriptionDetails;
