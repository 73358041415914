// scroll bar
import "simplebar-react/dist/simplebar.min.css";

import "src/index.css";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// editor
import "react-quill/dist/quill.snow.css";

// ----------------------------------------------------------------------

// routes
import Router from "src/routes/sections";
// theme
import ThemeProvider from "src/theme";
// hooks
import { useScrollToTop } from "src/hooks/use-scroll-to-top";
// components
import ProgressBar from "src/components/progress-bar";
import MotionLazy from "src/components/animate/motion-lazy";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SettingsProvider, SettingsDrawer } from "src/components/settings";
// auth
import { AuthProvider, AuthConsumer } from "src/auth/context/jwt";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider } from "src/components/snackbar";
import PermissionProvider from "./PermissionProvider/PermissionProvider";

// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App () {
  useScrollToTop();
  const permissions = JSON.parse(localStorage.getItem("admin")!)?.role
    ?.permissions;

  return (
    <AuthProvider>
      <PermissionProvider userPermissions={permissions}>
        <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider
              defaultSettings={{
                themeMode: "light", // 'light' | 'dark'
                themeDirection: "ltr", //  'rtl' | 'ltr'
                themeContrast: "default", // 'default' | 'bold'
                themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
                themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                themeStretch: false,
              }}
            >
              <ThemeProvider>
                <SnackbarProvider>
                  <MotionLazy>
                    <SettingsDrawer />
                    <ProgressBar />
                    <AuthConsumer>
                      <Router />
                    </AuthConsumer>
                  </MotionLazy>
                </SnackbarProvider>
              </ThemeProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </PermissionProvider>
    </AuthProvider>
  );
}
