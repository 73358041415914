import { Card, Grid, Table, TableBody, TableContainer } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import RolesTableRow from "src/sections/roles-and-permissions/roles/RolesTableRow";

export default function RolesPage () {
  const table = useTable({ defaultRowsPerPage: 10 });
  const quickDetails = useBoolean();
  const { useGetListApi, useDeleteApi } = useApiServices();

  const methods = useForm({
    defaultValues: {},
  });

  const { data, isLoading, error } = useGetListApi<ResDataType["roles"]>({
    url: API_URLS.ROLES,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
    },
  });

  const { mutate: deleteRole, isLoading: isDeleting } = useDeleteApi({
    url: API_URLS.ROLES,
  });

  const TABLE_HEAD = [
    { id: "1", label: "Role ID" },
    { id: "2", label: "Role name" },
    { id: "3", label: "System criteria" },
    { id: "4", label: "Number" },
    { id: "5", label: "Last updated" },
    { id: "6", label: "Actions" },
  ];
  // .filter(e => (e.condition === undefined ? true : e.condition));

  return (
    <>
      <Helmet>
        <title>Roles</title>
      </Helmet>
      <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
        <FormProvider methods={methods}>
          <Grid container spacing={4} my={1}>
            <>
              <Grid item xs={12}>
                <Card>
                  {/* <PermissionsTableToolbar
                    values={methods.watch()}
                    setSearch={setSearch}
                    reset={methods.reset}
                  /> */}
                  <TableContainer>
                    <Scrollbar>
                      <Table>
                        <TableHeadCustom headLabel={TABLE_HEAD} />
                        <TableBody>
                          {isLoading ? (
                            [...Array(table.rowsPerPage)].map((i, index) => (
                              <TableSkeleton key={index} sx={{ height: 80 }} />
                            ))
                          ) : (
                            <>
                              {data!?.items?.map(row => (
                                <RolesTableRow
                                  key={row.id}
                                  row={row}
                                />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                  {data && (
                    <TablePaginationCustom
                      count={data?.totalItems}
                      page={data ? +data.currentPage - 1 : 1}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table?.onChangePage}
                      onRowsPerPageChange={table?.onChangeRowsPerPage}
                    />
                  )}
                </Card>
              </Grid>
            </>
          </Grid>
        </FormProvider>
      </AppLoadingAndErrorWrapper>
    </>
  );
}
