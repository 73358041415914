import { Icon } from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Container from "@mui/material/Container";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import Slider from "react-slick";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import MessageCard from "src/components/home/MessageCard";
import ProgramCircularProgress from "src/components/home/ProgramCircularProgress";
import ChangePastProgramStatusDialog from "src/components/programs/ChangeProgramStatusDialog";
import ProgramButtons from "src/components/programs/ProgramButtons";
import ProgramCategoriesMessages from "src/components/programs/ProgramCategoriesMessages";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import { IProgramsDto, IProgramDto } from "src/types/programs";

export default function UserPrograms () {
  const settings = useSettingsContext();
  const { useGetListApi } = useApiServices();
  const { userId } = useParams();
  const programButtonAction = useBoolean();
  const [program, setProgram] = useState<IProgramDto>();
  const [newProgramStatus, setNewProgramStatus] = useState<string>();
  const [dialogTitle, setDialogTitle] = useState<string>();
  const [hasCurrentProgram, setHasCurrentProgram] = useState<Boolean>(false);

  const {
    data: userPrograms,
    isLoading,
    error,
  } = useGetListApi<IProgramsDto>({
    url: API_URLS.USER_PROGRAMS,
    parameters: {
      userId,
    },
    onSuccess: (data: IProgramsDto) => {
      setHasCurrentProgram(
        data.items
          .map(e => e.status.value)
          .join("")
          .includes("IN_PROGRESS")
      );
    },
  });

  const sliderSettings = {
    infinite: true,
    speed: 500,
    nextArrow: <Icon icon='carbon:next-outline' color='black' />,
    prevArrow: <> </>,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>Past Programs</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <Grid container spacing={2}>
            {userPrograms &&
              userPrograms.items?.map(item => {
                const programProgress: any =
                  (item.programUserMessageCount &&
                    item.programUserMessageCount * 100) /
                  (item.programTopicCount && item.programTopicCount);

                return (
                  <Grid item xs={12}>
                    <Accordion
                      sx={{
                        border: "1px solid #4F4F4F",
                        borderRadius: 2,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                        sx={{
                          bgcolor: "#FFAB8B",
                          borderRadius: 1,
                          p: 1,
                          pl: 2,
                        }}
                      >
                        <Stack direction='column' spacing={1}>
                          <Typography fontSize={24} fontWeight={500}>
                            {item.program?.name}
                          </Typography>
                          <Typography fontSize={16} fontWeight={400}>
                            {item.status?.label}
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid item container spacing={2} my={2}>
                          <Grid
                            item
                            container
                            justifyContent='center'
                            alignItems='center'
                            padding={3}
                          >
                            <ProgramCircularProgress
                              programName={item.program.name}
                              progressValue={programProgress?.toFixed(0)}
                              progressLabel='Progress'
                              percentageIcon='%'
                            />
                          </Grid>
                          <Grid item xs={12} mt={2} mb={4}>
                            <ProgramCategoriesMessages
                              programCategories={item?.programCategories}
                              activeProgramCategory={item?.programCategory}
                            />
                          </Grid>
                          {/* Messages */}
                          <Grid item container spacing={2}>
                            {item?.programCategories.map(categoryItem => (
                              <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography fontSize={28} fontWeight={500}>
                                    {categoryItem.name}
                                  </Typography>
                                </Grid>
                                <Grid item container spacing={2}>
                                  <Grid item xs={11.5}>
                                    <Slider
                                      {...sliderSettings}
                                      slidesToShow={Math.min(
                                        3,
                                        categoryItem?.programUserMessage?.length
                                      )}
                                      slidesToScroll={Math.min(
                                        1,
                                        categoryItem?.programUserMessage?.length
                                      )}
                                    >
                                      {categoryItem?.programUserMessage?.map(
                                        (messageItem, messageIndex) => (
                                          <Box p={1}>
                                            <MessageCard
                                              key={messageIndex}
                                              messageTitle={
                                                messageItem?.programTopic?.name
                                              }
                                              messageContent={
                                                messageItem?.programTopic
                                                  ?.whatsappMessage
                                              }
                                              withGridContainer={false}
                                            />
                                          </Box>
                                        )
                                      )}
                                    </Slider>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                          <Grid
                            item
                            container
                            justifyContent='center'
                            alignContent='center'
                            spacing={1}
                          >
                            <Restricted to={PrmsEnum.UPDATE_PROGRAM}>
                              <ProgramButtons
                                programStatus={item.status?.value}
                                onClick={event => {
                                  programButtonAction.onTrue();
                                  setDialogTitle(
                                    event.currentTarget.dataset.buttonKey
                                  );
                                  setProgram(item);
                                  setNewProgramStatus(
                                    event.currentTarget.dataset.myAttribute
                                  );
                                }}
                              />
                            </Restricted>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}
          </Grid>
        </AppLoadingAndErrorWrapper>
        {/* Dialog */}
        {programButtonAction.value && (
          <ChangePastProgramStatusDialog
            open={programButtonAction.value}
            onClose={programButtonAction.onFalse}
            program={program!}
            newProgramStatus={newProgramStatus ?? ""}
            dialogTitle={dialogTitle ?? ""}
            hasCurrentProgram={hasCurrentProgram}
          />
        )}
      </Container>
    </>
  );
}
