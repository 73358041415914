import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { alpha, Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import {
  AddBlogCategoryFormType,
  GetBlogCategoryType,
} from "src/types/blogs-categories";

interface AddBlogCategoryDialogPropsType {
  open: boolean;
  onClose: VoidFunction;
  dialogTitle: string;
  id?: string | number;
}

// TODO improvement component

const AddBlogCategory = ({
  open,
  onClose,
  dialogTitle,
  id,
}: AddBlogCategoryDialogPropsType) => {
  const confirm = useBoolean();
  const success = useBoolean();
  const [dataToSend, setDataToSend] = useState<AddBlogCategoryFormType>();
  const { usePostApi, useGetListApi, useGetItemApi, usePatchApi } =
    useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");

  // #region services
  const {
    mutate: addBlogCategory,
    isLoading,
    error,
  } = usePostApi<AddBlogCategoryFormType>({
    url: API_URLS.BLOGS_CATEGORIES,
    onSuccess: () => {
      onClose();
    },
  });

  const { refetch, isRefetching } = useGetItemApi({
    url: API_URLS.BLOGS_CATEGORIES,
    id: id!,
    onSuccess: (data: GetBlogCategoryType) => {
      if (id) {
        methods.setValue(`translations`, data.translations);
      }
    },
  });

  const { data: supportedLanguages } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.LANGUAGE_TYPE,
    onSuccess: (data: ResDataType["dropDowns"]) => {
      methods.setValue(
        "translations",
        data.map(e => ({
          name: "",
          locale: e.value,
        }))
      );
      setCurrentTabValue(data.map(e => e.value)[0]);
    },
  });

  const {
    mutate: editBlogCategory,
    isLoading: isEditing,
    error: patchError,
  } = usePatchApi<AddBlogCategoryFormType>({
    url: API_URLS.BLOGS_CATEGORIES,
    onSuccess: (data: any) => {
      confirm.onFalse();
    },
    id: id!,
  });
  // #endregion services

  // #region Form
  const methods = useForm<AddBlogCategoryFormType>({
    defaultValues: {
      status: "DRAFT",
      translations: [
        {
          name: "",
          locale: "",
        },
      ],
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (data: AddBlogCategoryFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const indexOfCurrentLocale = (methods.watch().translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);

  // #endregion Form

  // #region handlers
  const mutate = id ? editBlogCategory : addBlogCategory;

  const handleChangeTab = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const saveAsDraft = () => {
    methods.setValue("status", "DRAFT");
    confirm.onTrue();
  };

  const publish = () => {
    methods.setValue("status", "PUBLISHED");
    confirm.onTrue();
  };

  const handleClose = () => {
    confirm.onFalse();
    onClose();
    success.onFalse();
  };
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      ...methods.watch().translations!?.[+indexOfCurrentLocale!],
      locale: currentTabValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexOfCurrentLocale, methods, currentTabValue]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // #endregion useEffect

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <AppLoadingAndErrorWrapper
              isLoading={isLoading || isRefetching}
              errorMessage={error}
              sx={{ height: "auto" }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      textAlign='center'
                      fontSize={24}
                      fontWeight={700}
                    >
                      {dialogTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Stack spacing={3}>
                      <Tabs
                        value={currentTabValue}
                        onChange={handleChangeTab}
                        sx={{
                          px: 3,
                          boxShadow: theme =>
                            `inset 0 -2px 0 0 ${alpha(
                              theme.palette.grey[500],
                              0.08
                            )}`,
                        }}
                      >
                        {supportedLanguages!?.map(tab => (
                          <Tab
                            key={tab.value}
                            value={tab.value}
                            label={tab.label}
                          />
                        ))}
                      </Tabs>
                      <Block label='Write category name'>
                        <RHFTextField
                          name={`translations.${+indexOfCurrentLocale!}.name`}
                          value={
                            methods.watch()?.translations!?.[
                              +indexOfCurrentLocale!
                            ]?.name
                              ? methods.watch()?.translations!?.[
                                  +indexOfCurrentLocale!
                                ]?.name
                              : ""
                          }
                        />
                      </Block>
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <AppButton
                      label='Cancel'
                      color='secondary'
                      onClick={handleClose}
                      fullWidth
                      sx={{ height: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton
                      color='secondary'
                      label='Save as draft'
                      isLoading={isLoading}
                      onClick={saveAsDraft}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AppLoadingButton
                      label='Publish'
                      isLoading={isLoading}
                      onClick={publish}
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value && !success.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={`Are you sure you want to ${id ? 'edit' : 'create'} this new category?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading || isEditing}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />

      {/* Success */}
      <ConfirmDialog
        open={success.value && confirm.value}
        onClose={onClose}
        close={() => {
          confirm.onFalse();
          success.onFalse();
        }}
        content='You have created a new category successfully'
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </>
  );
};

export default AddBlogCategory;
