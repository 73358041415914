import { Card, Grid, Table, TableBody, TableContainer } from "@mui/material";
import Container from "@mui/material/Container";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import ProgramFeaturesTableRow from "src/sections/programs/program-features/ProgramFeaturesTableRow";

const TABLE_HEAD = [
  { id: "1", label: "Picture" },
  { id: "2", label: "Description" },
  { id: "3", label: "Created date" },
  { id: "4", label: "Actions" },
];

export default function ProgramFeatures () {
  const { programId } = useParams();
  const settings = useSettingsContext();
  const table = useTable({ defaultRowsPerPage: 10 });
  const { useGetListApi, useDeleteApi } = useApiServices();

  const { data, isLoading, error, refetch, isRefetching } = useGetListApi<
    ResDataType["programFeatures"]
  >({
    url: API_URLS.PROGRAM_FEATURES,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
      programId,
    },
  });

  const { mutate, isLoading: isDeleteLoading } = useDeleteApi({
    url: API_URLS.PROGRAM_FEATURES,
    urlAfterSuccess: window.location.href,
    onSuccess: () => refetch(),
  });

  return (
    <>
      <Helmet>
        <title>Features</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isDeleteLoading || isRefetching}
          errorMessage={error}
        >
          <Grid container spacing={4} my={1}>
            <Grid item xs={12}>
              <Card>
                <TableContainer>
                  <Scrollbar>
                    <Table>
                      <TableHeadCustom headLabel={TABLE_HEAD} />
                      <TableBody>
                        {isLoading ? (
                          [...Array(table.rowsPerPage)].map((i, index) => (
                            <TableSkeleton key={index} sx={{ height: 80 }} />
                          ))
                        ) : (
                          <>
                            {data!?.items?.map(row => (
                              <ProgramFeaturesTableRow
                                key={row.id}
                                row={row}
                                mutateDelete={mutate}
                                isDeleteLoading={isDeleteLoading}
                              />
                            ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>
                {data && (
                  <TablePaginationCustom
                    count={data?.totalItems}
                    page={data.currentPage ? +data.currentPage - 1 : 1}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table?.onChangePage}
                    onRowsPerPageChange={table?.onChangeRowsPerPage}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
