import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React from "react";

interface IStatisticCard {
  cardTitle: string;
  cardCount: number;
  index: number;
}

const StatisticCard: React.FC<IStatisticCard> = props => {
  const { cardTitle, cardCount, index } = props;
  // TODO currently we dont have localization , so when we hane it will be change this way

  const StatisticsDataBgColor = [
    "#D3DCB0",
    "#DDB6FF",
    "#92C6F4",
    "#FFEAAE",
    "#FFAB8A",
    "#AFDFC8",
  ];

  return (
      <Card
        sx={{
          p: 3,
          bgcolor: StatisticsDataBgColor[index],
          color: "black",
          borderRadius: "8px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography fontSize={12} fontWeight={500} paragraph>
            {cardTitle}
          </Typography>
          <Typography fontSize={24} fontWeight={500} gutterBottom>
            {cardCount}
          </Typography>
        </Box>
      </Card>
  );
};

export default StatisticCard;
