import {
  Container,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Header from "src/components/Header";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { paths } from "src/routes/paths";
import { ResDataType } from "src/api/apiUrls";
import ProgramBeneficiariesTableRow from "src/sections/programs/program-beneficiaries/ProgramBeneficiariesTableRow";
import ContactUsTableRow from "src/sections/contact-us/ContactUsTableRow";
import Card from "@mui/material/Card";
import AssignedToMeTable from "./AssignedToMe";

const TABLE_HEAD = [
  { id: "1", label: "Name" },
  { id: "2", label: "Message" },
  { id: "3", label: "Email" },
  { id: "4", label: "Received date" },
  { id: "5", label: "Updated at" },
  { id: "6", label: "Status" },
  { id: "7", label: "Actions" },
];

// TODO:
// 1- Remove/add columns depending on the opened tab
// 2- Add status color

const TABS = [
  {
    value: "allMessages",
    label: "All messages",
    status: "",
  },
  {
    value: "opened",
    label: "Opened",
    status: "OPENED",
  },
  {
    value: "closed",
    label: "Closed",
    status: "CLOSED",
  },
  {
    value: "answered",
    label: "Answered",
    status: "ANSWERED",
  },
  {
    value: "customerReply",
    label: "Customer reply",
    status: "CUSTOMER_REPLAY",
  },
  {
    value: "assignedToMe",
    label: "Assigned to me",
  },
];

const ContactUs = () => {
  const settings = useSettingsContext();
  const table = useTable({ defaultRowsPerPage: 10 });
  const { useGetListApi } = useApiServices();

  const [currentTabValue, setCurrentTabValue] = useState("allMessages");
  const [currentTabStatus, setCurrentTabStatus] = useState("");

  useEffect(() => {
    setCurrentTabStatus(
      TABS.filter(tab => tab.value === currentTabValue)[0]?.status!
    );
  }, [currentTabValue]);

  const { data, isLoading } = useGetListApi<ResDataType["contactUs"]>({
    url: API_URLS.CONTACT_US,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
      status: currentTabStatus || undefined,
    },
  });

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  return (
    <>
      <Helmet>
        <title>Contact us</title>
      </Helmet>
      <Stack spacing={4}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Contact us",
            },
          ]}
        />
        <Container
          maxWidth={settings.themeStretch ? false : "xl"}
          sx={{ height: "100%" }}
        >
          <Stack spacing={4}>
            <Header header={`Today, ${moment().format("DD-MM-YYYY")}`} />
            <Tabs
              value={currentTabValue}
              onChange={handleChangeTab}
              sx={{
                px: 3,
                boxShadow: theme =>
                  `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {TABS.map(tab => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
            {/* #region table */}
            {!(currentTabValue === "assignedToMe") ? (
              <Card>
                <TableContainer>
                  <Scrollbar>
                    <Table>
                      <TableHeadCustom headLabel={TABLE_HEAD} />
                      <TableBody>
                        {isLoading
                          ? [...Array(table.rowsPerPage)].map((i, index) => (
                              <TableSkeleton key={index} sx={{ height: 80 }} />
                            ))
                          : data!?.items?.map(row => (
                              <ContactUsTableRow key={row.id} row={row} />
                            ))}
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                  count={data?.totalItems!}
                  page={data!?.currentPage ? +data!?.currentPage - 1 : 1}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table?.onChangePage}
                  onRowsPerPageChange={table?.onChangeRowsPerPage}
                />
              </Card>
            ) : (
              <AssignedToMeTable />
            )}
            {/* #endregion table */}
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default ContactUs;
