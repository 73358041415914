import { Box, Button, Container, Tab, Tabs } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';
import { useBoolean } from 'src/hooks/use-boolean';
import Restricted from 'src/PermissionProvider/Restricted';
import { PrmsEnum } from 'src/types/permissions';
import AddBeneficiaryDialog from '../../../../sections/programs/program-beneficiaries/AddBeneficiaryDialog';
import AddFeatureDialog from '../../../../sections/programs/program-features/AddFeatureDialog';
import ProgramBeneficiaries from './beneficiaries/ProgramBeneficiaries';
import ProgramFeatures from './features/ProgramFeatures';

const FeaturesAndBeneficiariesPagination = () => {
  const featuresDialog = useBoolean();
  const BeneficiariesDialog = useBoolean();
  const settings = useSettingsContext();

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const [currentTabValue, setCurrentTabValue] = useState('features');

  const handleOpenDialog = () => {
    if (currentTabValue === 'features') {
      featuresDialog.onTrue();
    } else if (currentTabValue === 'beneficiaries') {
      BeneficiariesDialog.onTrue();
    }
  };

  const TABS = [
    {
      value: 'features',
      label: 'Features',
      element: <ProgramFeatures />,
    },
    {
      value: 'beneficiaries',
      label: 'Beneficiaries',
      element: <ProgramBeneficiaries />,
    },
  ];

  const RenderedElement = TABS.filter((tab) => tab.value === currentTabValue)[0]
    .element;

  return (
    <>
      <Helmet>
        <title>Features and beneficiaries</title>
      </Helmet>
      <Container
        maxWidth={settings.themeStretch ? false : 'xl'}
        sx={{ height: '100%' }}
      >
        <Restricted to={PrmsEnum.UPDATE_PROGRAM}>
          <Box display="flex" width="100%" justifyContent="end" py={4}>
            <Button
              variant="contained"
              sx={{ color: 'text.primary' }}
              onClick={handleOpenDialog}
            >
              Add new
            </Button>
          </Box>
        </Restricted>
        <Tabs
          value={currentTabValue}
          onChange={handleChangeTab}
          sx={{
            boxShadow: (theme) =>
              `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </Tabs>
        {RenderedElement}
        <AddFeatureDialog
          open={featuresDialog.value}
          onClose={featuresDialog.onFalse}
          dialogTitle="Add a new feature"
        />
        <AddBeneficiaryDialog
          open={BeneficiariesDialog.value}
          onClose={BeneficiariesDialog.onFalse}
          dialogTitle="Add a new beneficiary"
        />
      </Container>
    </>
  );
};

export default FeaturesAndBeneficiariesPagination;
