import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  useTheme,
  Typography,
  Stack,
  Divider,
} from "@mui/material";
import Container from "@mui/material/Container";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import Chart, { useChart } from "src/components/chart";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import Header from "src/components/Header";
import { useSettingsContext } from "src/components/settings";
import StatisticCard from "src/components/StatisticCard";
import Restricted from "src/PermissionProvider/Restricted";
import { paths } from "src/routes/paths";
import ProgramStatusStatistics from "src/sections/analytics/ProgramStatusStatistics";
import ChartColumnSingle from "src/sections/chart-view/ChartColumnSingle";

export default function Home () {
  const settings = useSettingsContext();
  const { useGetItemApi } = useApiServices();

  const { data, isLoading, error } = useGetItemApi<ResDataType["analytics"]>({
    url: API_URLS.ANALYTICS,
  });
  console.log(isLoading)

  const totalGifts = data!?.globalStatistics
    .filter(gift => gift.label.toLowerCase() === "gifts")
    .map(e => e.count)[0];

  const totalUserTypes = data!?.globalStatistics
    .filter(gift => gift.label.toLowerCase() === "new users")
    .map(e => e.count)[0];

  const totalFrequenciesProgramCount = data!?.programFrequencyStatistics.reduce(
    (acc, frq) => acc + frq.count,
    0
  );

  const theme = useTheme();

  const colors = [
    [theme.palette.primary.light, theme.palette.primary.main],
    [theme.palette.warning.light, theme.palette.warning.main],
  ];

  const CHART_SIZE = { width: 106, height: 106 };

  const chartOptionsCheckIn = useChart({
    fill: {
      type: "gradient",
      gradient: {
        colorStops: ([
          { offset: 0, color: colors[0][0] },
          { offset: 100, color: colors[0][1] },
        ] as any),
      },
    },
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: -9,
        bottom: -9,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize as string,
          },
        },
      },
    },
  });

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
            <CustomBreadcrumbs
              links={[
                {
                  name: "Home",
                  href: paths.dashboard.root,
                },
              ]}
            />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Header header={`Today, ${moment().format("YYYY-MM-DD")}`} />
            </Grid>
            {data?.globalStatistics?.map((e, index) => (
              <Grid item xs={12} md={12 / data.globalStatistics.length}>
                <StatisticCard
                  cardCount={e.count}
                  cardTitle={e.label}
                  index={index}
                />
              </Grid>
            ))}

            {/* Total subscriptions */}

            <Grid item xs={12} md={6} display='flex'>
              <Card sx={{ width: "100%" }}>
                <CardHeader title='Total subscription' />
                <CardContent>
                  <ChartColumnSingle
                    series={[
                      {
                        name: "",
                        data: data!?.totalSubscriptionStatistics.map(
                          e => e.count
                        ),
                      },
                    ]}
                    xaxis={data!?.totalSubscriptionStatistics.map(e => e.month)}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Program status */}

            <Grid item xs={12} md={6} display='flex'>
              <ProgramStatusStatistics
                sx={{ width: "100%" }}
                title='Program status'
                data={data!?.programStatusStatistics.map(e => ({
                  status: e.label!,
                  value: e.count!,
                }))}
              />
            </Grid>

            {/* User type */}

            <Grid item xs={12} display='flex'>
              <Card sx={{ width: "100%" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant='cardHeader'
                    sx={{ opacity: 0.72, width: "fit-content" }}
                  >
                    User Type
                  </Typography>
                  {data?.userTypeStatistics.map(e => (
                    <Stack flexDirection='row' alignItems='center' spacing={2}>
                      <Chart
                        type='radialBar'
                        series={[
                          +((e.count / totalUserTypes) * 100).toFixed(1),
                        ]}
                        options={chartOptionsCheckIn}
                        {...CHART_SIZE}
                      />
                      <div>
                        <Typography variant='body2' sx={{ opacity: 0.72 }}>
                          {e.label}
                        </Typography>
                      </div>
                    </Stack>
                  ))}
                </CardContent>
              </Card>
            </Grid>

            {/* Programs frequency */}

            <Grid item xs={6} display='flex'>
              <Card sx={{ width: "100%" }}>
                <CardContent
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack spacing={4}>
                    <Stack>
                      <Typography
                        variant='cardHeader'
                        sx={{ opacity: 0.72, width: "fit-content" }}
                      >
                        Programs frequency
                      </Typography>
                      <Divider />
                    </Stack>
                    <Stack
                      flexDirection='row'
                      alignItems='center'
                      spacing={2}
                      width='100%'
                      justifyContent='space-evenly'
                    >
                      {data?.programFrequencyStatistics.map(e => (
                        <Stack flexDirection='row' alignItems='center'>
                          <Chart
                            type='radialBar'
                            series={[
                              +(
                                (e.count / totalFrequenciesProgramCount) *
                                100
                              ).toFixed(1),
                            ]}
                            options={chartOptionsCheckIn}
                            {...CHART_SIZE}
                          />
                          <div>
                            <Typography variant='body2' sx={{ opacity: 0.72 }}>
                              {e.label}
                            </Typography>
                          </div>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            {/* Gifts */}

            <Grid item xs={6} display='flex'>
              <Card sx={{ width: "100%" }}>
                <CardContent
                  sx={{
                    width: "100%",
                  }}
                >
                  <Stack spacing={4}>
                    <Stack>
                      <Typography
                        variant='cardHeader'
                        sx={{ opacity: 0.72, width: "fit-content" }}
                      >
                        Gifts
                      </Typography>
                      <Divider />
                    </Stack>
                    <Stack
                      flexDirection='row'
                      alignItems='center'
                      spacing={2}
                      width='100%'
                      justifyContent='space-evenly'
                    >
                      {data?.giftProgramStatistics.map(e => (
                        <Stack flexDirection='row' alignItems='center'>
                          <Chart
                            type='radialBar'
                            series={[
                              +((e._count / totalGifts!) * 100).toFixed(1),
                            ]}
                            options={chartOptionsCheckIn}
                            {...CHART_SIZE}
                          />
                          <div>
                            <Typography variant='body2' sx={{ opacity: 0.72 }}>
                              {e.name}
                            </Typography>
                          </div>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
