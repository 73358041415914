// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/dashboard",
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: "https://mui.com/store/items/minimal-dashboard/",
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgetPassword: `${ROOTS.AUTH}/jwt/forgetPassword`,
      subscribe: `${ROOTS.AUTH}/jwt/subscribe`,
      setPassword: `${ROOTS.AUTH}/jwt/setPassword`,
      confirmInformation: `${ROOTS.AUTH}/jwt/confirmInformation`,
      otpConfirmation: `${ROOTS.AUTH}/jwt/otp`,
      continueSubscription: `${ROOTS.AUTH}/jwt/continueSubscription`,
    },
  },
  // DASHBOARD
  dashboard: {

    root: ROOTS.DASHBOARD,
    roles: `${ROOTS.DASHBOARD}/roles-and-permissions`,
    profile: `${ROOTS.DASHBOARD}/profile`,
    programs: {
      current: `${ROOTS.DASHBOARD}/programs`,
      past: `${ROOTS.DASHBOARD}/programs/past`,
      specificProgram: {
        analytics: "analytics",
        description: "description",
        featuresAndBeneficiaries: "featuresAndBeneficiaries",
        users: "users",
      },
    },
    gifts: {
      received: `${ROOTS.DASHBOARD}/gifts`,
      sent: `${ROOTS.DASHBOARD}/gifts/sent`,
      customize: `${ROOTS.DASHBOARD}/gifts/customize/?programId=:id`,
    },
    settings: `${ROOTS.DASHBOARD}/settings`,
    contactUs: `${ROOTS.DASHBOARD}/contact-us`,
    blogs: `${ROOTS.DASHBOARD}/blogs`,
    users: `${ROOTS.DASHBOARD}/users`,
    pages: `${ROOTS.DASHBOARD}/pages`,
    badges: `${ROOTS.DASHBOARD}/badges`,
    careers: `${ROOTS.DASHBOARD}/careers`,
  },
};
