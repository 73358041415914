// @mui
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Iconify from '../iconify/iconify';

// ----------------------------------------------------------------------
export type ConfirmDialogProps = Omit<DialogProps, 'title' | 'content'> & {
  close?: any;
  title?: React.ReactNode;
  content: React.ReactNode;
  action?: React.ReactNode;
  onClose: any;
  icon?: React.ReactNode;
};

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  close,
  icon,
  ...other
}: ConfirmDialogProps) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      {...other}
      PaperProps={{ sx: { borderRadius: '8px' } }}
    >
      <DialogTitle sx={{ pb: 2 }} display="flex" justifyContent="flex-end">
        <Box display="flex">
          {close && (
            <IconButton onClick={()=>close()}>
              <Iconify icon="material-symbols:cancel-outline" />
            </IconButton>
          )}
          {title && title}
        </Box>
      </DialogTitle>

      {content && (
        <DialogContent>
          <Stack p={5} direction="column" spacing={3}>
            {icon}
            <Typography fontSize={28} fontWeight={500} textAlign="center">
              {content}
            </Typography>
          </Stack>
        </DialogContent>
      )}
      <DialogActions style={{ justifyContent: 'center' }}>{action}</DialogActions>
    </Dialog>
  );
}
