import { Helmet } from "react-helmet-async";
import { Container, Box, Typography, Button, Stack } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import useApiServices from "src/api/useApiServices";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import Divider from "@mui/material/Divider";
import EmptyNotifications from "./EmptyNotifications";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { paths } from "src/routes/paths";

const NotificationsView = () => {
  const settings = useSettingsContext();
  const { useGetListApi } = useApiServices();
  const {
    data: notifications,
    isLoading,
    error,
  } = useGetListApi<ResDataType["notifications"]>({
    url: API_URLS.NOTIFICATIONS,
  });

  if (!isLoading && !notifications?.items.length) return <EmptyNotifications />;

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Notifications",
            },
          ]}
        />
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <Stack spacing={2} width={{ xs: "100%", sm: "50%" }}>
            {notifications!?.items.map(notification => (
              <>
                <Box
                  key={notification.id}
                  display='flex'
                  alignItems='center'
                  width='100%'
                  justifyContent='space-between'
                >
                  <Typography>{notification.message}</Typography>
                </Box>
                <Divider />
              </>
            ))}
          </Stack>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default NotificationsView;
