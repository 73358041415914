import { Grid, Box, Typography } from "@mui/material";
import Iconify from "src/components/iconify/iconify";

const AddDialogsHelperText = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        "padding-top": 0,
      }}
    >
      <Box display='flex' gap={1} alignItems='center'>
        <Iconify icon='simple-line-icons:exclamation' color='#fe6d73' />
        <Typography variant='caption' color='#fe6d73'>
          Make sure that all languages fields are filled
        </Typography>
      </Box>
    </Grid>
  );
};

export default AddDialogsHelperText;
