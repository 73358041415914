import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  alpha,
  Box,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import { ContactUsDetailsDtoType } from "src/types/contact-us";
import {
  AddBeneficiaryDialogPropsType as AssignMessageDialogPropsType,
  AddBeneficiaryFormType,
  GetProgramBeneficiaryDataType,
} from "src/types/program-beneficiaries";

type AssignMSGFormType = {
  adminId: string;
};

const AssignMSGDialog = ({
  open,
  onClose,
  dialogTitle,
  id,
}: AssignMessageDialogPropsType) => {
  // #region States
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<AssignMSGFormType>();
  const { useGetItemApi, usePatchApi, useGetListApi } = useApiServices();
  // #endregion States

  // #region Form
  const methods = useForm<AssignMSGFormType>({
    defaultValues: { adminId: "" },
  });

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (data: AssignMSGFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );
  // #endregion Form

  // #region Services
  const { data: admins } = useGetListApi<ResDataType["admins"]>({
    url: API_URLS.ADMINS,
  });

  const { refetch, isRefetching, error, isLoading } = useGetItemApi({
    url: API_URLS.CONTACT_US,
    id: id!,
    onSuccess: (data: ContactUsDetailsDtoType) => {
      if (id) {
        methods.setValue("adminId", data.adminId);
      }
    },
  });

  const {
    mutate: editAssignment,
    isLoading: isEditLoading,
    error: editError,
  } = usePatchApi<AssignMSGFormType>({
    url: API_URLS.CONTACT_US,
    id: id!,
    onSuccess: () => {
      confirm.onFalse();
      onClose();
    },
  });
  // #endregion Services

  // #region useEffect
  useEffect(() => {
    methods.setValue("adminId", methods.watch().adminId);
  }, [methods]);

  useEffect(() => {
    open && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // #endregion useEffect

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <AppLoadingAndErrorWrapper
              isLoading={isLoading || isRefetching || isEditLoading}
              errorMessage={error || editError}
              sx={{ height: "auto" }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      textAlign='center'
                      fontSize={24}
                      fontWeight={700}
                    >
                      {dialogTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Block label='Admin name'>
                      <RHFSelect name='adminId'>
                        <MenuItem value={null as any}>-----</MenuItem>
                        {admins?.items.map(admin => (
                          <MenuItem key={admin.id} value={admin.id}>
                            {admin.fullName}
                          </MenuItem>
                        ))}
                      </RHFSelect>
                    </Block>
                  </Grid>
                  <Grid item xs={6}>
                    <AppButton
                      label='Cancel'
                      color='secondary'
                      onClick={onClose}
                      sx={{ height: "100%" }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton label='Add' isLoading={isEditLoading} />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content='Are you sure you want to assign this inquiry?'
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading || isRefetching || isEditLoading}
              onClick={() => editAssignment(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default AssignMSGDialog;
