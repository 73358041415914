import { yupResolver } from "@hookform/resolvers/yup";
import {
  alpha,
  Chip,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { useCallback, useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider from "src/components/hook-form";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import {
  AddEmailTopicFormType,
  GetTopicDataType,
} from "src/types/category-topics";
import { ProgramDetailsDtoType } from "src/types/programs-dropdown";
import { newEmailFormSchema, NewEmailFormSchema } from "./Add-new-topic-schema";

const AddTopicEmails = () => {
  // #region States
  const confirm = useBoolean();
  const changeTabDialog = useBoolean();
  const changeTab = useBoolean();
  const editorShort: any = useRef();
  const editorMed: any = useRef();
  const editorLong: any = useRef();
  const { topicId, programId, programCategoryId } = useParams();
  const success = useBoolean();
  const { useGetListApi, useGetItemApi, usePatchApi } = useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");
  const [tempTabValue, setTempTabValue] = useState("en");
  const [emailType, setEmailType] = useState<"short" | "medium" | "large">(
    "short"
  );
  const NOTES = [
    {
      label: "describe brotherhood based on gender",
      value: "subject",
    },
    {
      label: "add the user name",
      value: "first_name",
    },
    {
      label: "add the sending date",
      value: "date_now",
    },
  ];

  const helperText = (
    <Stack component='span'>
      {NOTES.map((note, index) => (
        <Typography textAlign='center' sx={{ mb: 1, wordSpacing: 2 }}>
          {index + 1}- If you want to {note.label}, please write
          <Typography style={{ display: "inline" }}>
            {` {{${note.value}}} `}
          </Typography>{" "}
          wherever you want to add it
        </Typography>
      ))}
    </Stack>
  );
  // #endregion States

  // #region Form
  const emailsForm = useForm<AddEmailTopicFormType>({
    defaultValues: {
      ...newEmailFormSchema,
      programCategoryId: +programCategoryId!,
    },
  });

  const indexOfCurrentLocale = (emailsForm.watch().translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);
  // #endregion Form

  // #region Services
  const { data: prgData, isLoading: isPrgLoading } =
    useGetItemApi<ProgramDetailsDtoType>({
      url: API_URLS.PROGRAMS,
      id: programId!,
      onSuccess: (data: ProgramDetailsDtoType) => {
        emailsForm.setValue(
          "translations",
          data.language.map(e => ({
            name: "",
            description: "",
            duration: "",
            header: "",
            locale: e.value,
          }))
        );
        setCurrentTabValue(data.language.map(e => e.value)[0]);
      },
    });

  const {
    data: topicData,
    isLoading: isItemLoading,
    error,
  } = useGetItemApi({
    url: API_URLS.PROGRAM_TOPICS,
    id: topicId!,
    onSuccess: (data: GetTopicDataType) => {
      if (topicId) {
        emailsForm.setValue(`translations`, data.translations);
      }
    },
    enabled: !!prgData,
  });

  const { mutate: addEmail, isLoading: isAddingEmail } =
    usePatchApi<AddEmailTopicFormType>({
      url: API_URLS.PROGRAM_TOPICS,
      onSuccess: (data: any) => {
        confirm.onFalse();
      },
      id: topicId!,
    });

  const supportedTopicLength = prgData?.topicLength.map(tl => tl.value);
  // #endregion Services

  // #region Handlers
  const onSubmit = useCallback(
    async (data: AddEmailTopicFormType) => {
      addEmail(data);
    },
    [addEmail]
  );

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      changeTabDialog.onTrue();
      setTempTabValue(newValue);
    },
    []
  );

  const exportHtml = (
    ref: any,
    emailDesign: string,
    emailMessage: string,
    emailType: string,
    e: Event
  ) => {
    e.preventDefault();
    ref?.current.editor.exportHtml((data: any) => {
      const { design, html } = data;
      addEmail({
        programCategoryId: +programCategoryId!,
        translations: [
          {
            locale: currentTabValue,
            [emailDesign]: design,
            [emailMessage]: html,
          },
        ],
      });
      setEmailType(emailType as "short");
    });
  };

  const EMAILS = [
    {
      emailType: "short",
      emailLabel: "Short",
      emailMessage: "emailMessageShort",
      emailDesign: "emailDesignShort",
      ref: editorShort,
      permission: supportedTopicLength?.includes("SHORT"),
    },
    {
      emailType: "medium",
      emailLabel: "Medium",
      emailMessage: "emailMessageMedium",
      emailDesign: "emailDesignMedium",
      ref: editorMed,
      permission: supportedTopicLength?.includes("MEDIUM"),
    },
    {
      emailType: "large",
      emailLabel: "Large",
      emailMessage: "emailMessageLarge",
      emailDesign: "emailDesignLarge",
      ref: editorLong,
      permission: supportedTopicLength?.includes("LARGE"),
    },
  ].filter(email => email.permission);
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    emailsForm.setValue(
      `translations.${+indexOfCurrentLocale!}.locale`,
      currentTabValue
    );
    for (const [key, value] of Object.entries(
      emailsForm.watch().translations!?.[+indexOfCurrentLocale!]
    )) {
      emailsForm.setValue(
        `translations.${+indexOfCurrentLocale!}.${key as "locale"}`,
        value || ""
      );
    }
  }, [topicData, emailsForm, indexOfCurrentLocale, currentTabValue]);
  // #endregion useEffect

  return (
    <Container>
      <AppLoadingAndErrorWrapper
        isLoading={isItemLoading || isPrgLoading}
        errorMessage={error}
      >
        <FormProvider
          methods={emailsForm}
          onSubmit={emailsForm.handleSubmit(onSubmit)}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Tabs
                value={currentTabValue}
                onChange={handleChangeTab}
                sx={{
                  px: 3,
                  boxShadow: theme =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {prgData?.language!?.map(tab => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>
            </Grid>
            {/* #region Emails accordion */}
            <Grid item xs={12}>
              <Grid item xs={12}>
                {prgData?.language?.map(
                  lang =>
                    lang.value === currentTabValue && (
                      <Stack spacing={3} key={lang.value}>
                        {EMAILS.map(e => {
                          return (
                            <Block label={e.emailLabel}>
                              <Stack direction='row' justifyContent='flex-end'>
                                <Tooltip
                                  title={helperText}
                                  arrow
                                  PopperProps={{ sx: { color: "red" } }}
                                >
                                  <IconButton color='primary'>
                                    <Iconify
                                      icon='zondicons:exclamation-outline'
                                      width={24}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                              <Card>
                                <EmailEditor
                                  ref={e.ref}
                                  onReady={() =>
                                    (emailsForm!?.watch()!?.translations[
                                      indexOfCurrentLocale
                                    ] as any)!?.[e.emailDesign] &&
                                    e!?.ref!?.current!?.editor!?.loadDesign(
                                      (emailsForm!?.watch()!?.translations[
                                        indexOfCurrentLocale
                                      ] as any)!?.[e.emailDesign]
                                    )
                                  }
                                />
                              </Card>
                              <Stack direction='row' justifyContent='flex-end'>
                                <AppLoadingButton
                                  label='Save email design'
                                  fullWidth={false}
                                  onClick={(event: Event) =>
                                    exportHtml(
                                      e.ref,
                                      e.emailDesign,
                                      e.emailMessage,
                                      e.emailType,
                                      event
                                    )
                                  }
                                  color='error'
                                  isLoading={
                                    isAddingEmail && emailType === e.emailType
                                  }
                                />
                              </Stack>
                            </Block>
                          );
                        })}
                      </Stack>
                    )
                )}
              </Grid>
            </Grid>
            {/* #endregion Emails accordion */}
          </Grid>
        </FormProvider>
      </AppLoadingAndErrorWrapper>
      {/* Change tab */}
      <ConfirmDialog
        open={changeTabDialog.value && !confirm.value && !success.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            changeTabDialog.onFalse();
          }
        }}
        content={
          <Stack spacing={2}>
            <Typography sx={{ mb: 0 }} variant='paragraph'>
              Please make sure that you have clicked all
            </Typography>
            <Chip
              label='Save Design'
              color='error'
              clickable={false}
              sx={{ width: "25%", margin: "auto" }}
            />
            <Typography sx={{ mb: 0 }} variant='paragraph'>
              Buttons before you continue to the next tab if you have made any
              edits, otherwise your edits will be lost
            </Typography>
          </Stack>
        }
        action={
          <>
            <AppButton
              fullWidth
              label='Back'
              color='secondary'
              onClick={() => {
                changeTabDialog.onFalse();
                changeTab.onFalse();
              }}
            />
            <AppButton
              fullWidth
              label='Continue'
              color='error'
              onClick={() => {
                changeTabDialog.onFalse();
                changeTab.onTrue();
                setCurrentTabValue(tempTabValue);
              }}
            />
          </>
        }
      />
    </Container>
  );
};

export default AddTopicEmails;
