import { Container } from "@mui/material";
import moment from "moment";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Header from "src/components/Header";
import { useSettingsContext } from "src/components/settings";
import { paths } from "src/routes/paths";
import ProgramUsers from "../programs/ProgramUsers";

export default function Users () {
  const settings = useSettingsContext();
  const now = moment().format("YYYY-MM-DD");

  return (
    <Container maxWidth={settings.themeStretch ? false : "xl"}>
      <CustomBreadcrumbs
        links={[
          {
            name: "Home",
            href: paths.dashboard.root,
          },
          {
            name: "Users",
            href: paths.dashboard.users,
          },
        ]}
      />
      <Header header={`Today, ${now}`} />
      <ProgramUsers />
    </Container>
  );
}
