import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Stack } from "@mui/material";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import { DeleteDialogPropsType } from "src/types/feature-dialog";

const DeleteDialog = ({
  open,
  onClose,
  name,
  id,
  url,
  onSuccess,
}: DeleteDialogPropsType) => {
  const confirm = useBoolean();
  const success = useBoolean();
  const { useDeleteApi } = useApiServices();

  const { mutate, isLoading: isDeleting } = useDeleteApi({
    url,
    onSuccess: () => {
      onClose();
      onSuccess && onSuccess();
    },
  });

  return (
    <>
      {/* Confirm */}
      <ConfirmDialog
        open={open && !success.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={`Are you sure you want to delete this ${name}?`}
        icon={
          <Stack width='100%' direction='row' justifyContent='center'>
            <Iconify icon='material-symbols:delete-outline' width={30} />
          </Stack>
        }
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isDeleting}
              onClick={() => mutate(id)}
            />
          </>
        }
      />

      {/* Success */}
      <ConfirmDialog
        open={success.value && confirm.value}
        onClose={onClose}
        close={() => {
          confirm.onFalse();
          success.onFalse();
        }}
        content={`You have deleted this ${name} successfully`}
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </>
  );
};

export default DeleteDialog;
