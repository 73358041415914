import { useDropzone } from "react-dropzone";
// @mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
// assets
//
import { ASSETS_API } from "src/config-global";
import Iconify from "../iconify";
//
import RejectionFiles from "./errors-rejection-files";
import MultiFilePreview from "./preview-multi-file";
import SingleFilePreview from "./preview-single-file";
import { UploadProps } from "./types";

// ----------------------------------------------------------------------

export default function Upload ({
  disabled,
  multiple = false,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  ...other
}: UploadProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const hasFile = !!file && !multiple;

  const hasFiles = !!files && multiple && !!files.length;

  const hasError = isDragReject || !!error;

  const renderSinglePreview = (
    <SingleFilePreview
      imgUrl={`${typeof file === "string" ? ASSETS_API + '/' + file : file?.preview}`}
    />
  );

  const removeSinglePreview = hasFile && onDelete && (
    <IconButton
      size='small'
      onClick={onDelete}
      sx={{
        top: 16,
        right: 16,
        zIndex: 9,
        position: "absolute",
        color: theme => alpha(theme.palette.common.white, 0.8),
        bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
        "&:hover": {
          bgcolor: theme => alpha(theme.palette.grey[900], 0.48),
        },
      }}
    >
      <Iconify icon='mingcute:close-line' width={18} />
    </IconButton>
  );

  const renderMultiPreview = hasFiles && (
    <>
      <Box sx={{ my: 3 }}>
        <MultiFilePreview
          files={files}
          thumbnail={thumbnail}
          onRemove={onRemove}
        />
      </Box>

      <Stack direction='row' justifyContent='flex-end' spacing={1.5}>
        {onRemoveAll && (
          <Button
            color='inherit'
            variant='outlined'
            size='small'
            onClick={onRemoveAll}
          >
            Remove All
          </Button>
        )}

        {onUpload && (
          <Button
            size='small'
            variant='contained'
            onClick={onUpload}
            startIcon={<Iconify icon='eva:cloud-upload-fill' />}
          >
            Upload
          </Button>
        )}
      </Stack>
    </>
  );

  return (
    <Box sx={{ width: 1, position: "relative", ...sx }}>
      <Box
        {...getRootProps()}
        sx={{
          p: 5,
          outline: "none",
          borderRadius: 1,
          cursor: "pointer",
          overflow: "hidden",
          position: "relative",
          bgcolor: theme => alpha(theme.palette.grey[500], 0.08),
          border: theme => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
          transition: theme => theme.transitions.create(["opacity", "padding"]),
          "&:hover": {
            opacity: 0.72,
          },
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: "none",
          }),
          ...(hasError && {
            color: "error.main",
            bgcolor: "error.lighter",
            borderColor: "error.light",
          }),
          ...(hasFile && {
            padding: "100px 0",
          }),
        }}
      >
        <input {...getInputProps()} />

        {hasFile ? renderSinglePreview : "+ Upload photo"}
      </Box>

      {removeSinglePreview}

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />

      {renderMultiPreview}
    </Box>
  );
}
