import { Container, Stack, Tab, Tabs } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import { paths } from "src/routes/paths";
import NotificationsSettings from "./notifications/NotificationsSettings";
import ChangePasswordView from "./password/ChangePasswordView";

const SettingsPagination = () => {
  const settings = useSettingsContext();

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const [currentTabValue, setCurrentTabValue] = useState("password");

  const TABS = [
    {
      value: "password",
      label: "Password",
      element: <ChangePasswordView />,
    },
    {
      value: "notifications",
      label: "Notifications",
      element: <NotificationsSettings />,
    },
  ];

  const RenderedElement = TABS.filter(tab => tab.value === currentTabValue)[0]
    .element;

  const CurrentTabLabel = TABS.filter(tab => tab.value === currentTabValue)[0]
    .label;

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Stack spacing={4}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Settings",
            },
            {
              name: CurrentTabLabel,
            },
          ]}
        />
        <Container
          maxWidth={settings.themeStretch ? false : "xl"}
          sx={{ height: "100%" }}
        >
          <Stack spacing={4}>
            <Tabs
              value={currentTabValue}
              onChange={handleChangeTab}
              sx={{
                px: 3,
                boxShadow: theme =>
                  `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {TABS.map(tab => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
            {RenderedElement}
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default SettingsPagination;
