import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha, Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { ConfirmDialog } from "src/components/custom-dialog";
import Editor from "src/components/editor/editor";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import { paths } from "src/routes/paths";
import { VacancyFormSchema } from "src/schemas";
import { AddVacancyFormType, GetVacancyDataType } from "src/types/careers";

const AddVacancy = () => {
  // #region States
  const { vacancyId } = useParams();
  const isEditing = vacancyId;
  const confirm = useBoolean();
  const navigate = useNavigate();
  const [dataToSend, setDataToSend] = useState<AddVacancyFormType>();
  const { usePostApi, useGetListApi, useGetItemApi, usePatchApi } =
    useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");
  // #endregion States

  // #region Services
  const {
    mutate: addVacancy,
    isLoading,
    error,
  } = usePostApi<AddVacancyFormType>({
    url: API_URLS.VACANCIES,
    onSuccess: () => {
      confirm.onFalse();
      navigate("/dashboard/careers/vacancies");
    },
    onError: () => {
      confirm.onFalse();
    },
  });

  const { refetch, isRefetching } = useGetItemApi({
    url: API_URLS.VACANCIES,
    id: vacancyId!,
    onSuccess: (data: GetVacancyDataType) => {
      if (vacancyId) {
        methods.setValue(`translations`, data.translations);
      }
    },
  });

  const { data: supportedLanguages, isLoading: areLanguagesLoading } =
    useGetListApi<ResDataType["dropDowns"]>({
      url: API_URLS.LANGUAGE_TYPE,
      onSuccess: (data: ResDataType["dropDowns"]) => {
        methods.setValue(
          "translations",
          data.map(e => ({
            title: "",
            description: "",
            responsibility: "",
            minmumQualification: "",
            locale: e.value,
          }))
        );
        setCurrentTabValue(data.map(e => e.value)[0]);
      },
    });

  const { mutate: editBeneficiary, isLoading: isEditLoading } =
    usePatchApi<AddVacancyFormType>({
      url: API_URLS.VACANCIES,
      id: vacancyId!,
      onSuccess: () => {
        confirm.onFalse();
      },
    });

  const mutate = isEditing ? editBeneficiary : addVacancy;
  // #endregion Services

  // #region Form
  const methods = useForm<AddVacancyFormType>({
    defaultValues: {
      translations: [
        {
          title: "",
          description: "",
          responsibility: "",
          minmumQualification: "",
          locale: "",
        },
      ],
    },
    resolver: yupResolver(VacancyFormSchema(+supportedLanguages?.length!)),
  });

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (data: AddVacancyFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const indexOfCurrentLocale = (methods.watch()?.translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);
  // #endregion Form

  // #region Handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue,
      title:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.title! || "",
      description:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.description! ||
        "",
      responsibility:
        methods.watch().translations!?.[+indexOfCurrentLocale!]
          ?.responsibility! || "",
      minmumQualification:
        methods.watch().translations!?.[+indexOfCurrentLocale!]
          ?.minmumQualification! || "",
    });
  }, [methods, indexOfCurrentLocale, currentTabValue]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // #endregion useEffect

  return (
    <>
      <Box p={{ md: 8, xs: 3 }}>
        <AppLoadingAndErrorWrapper
          isLoading={
            isLoading || isRefetching || isEditLoading || areLanguagesLoading
          }
          errorMessage={error}
          sx={{ height: "auto" }}
        >
          <CustomBreadcrumbs
            links={[
              {
                name: "Home",
                href: paths.dashboard.root,
              },
              {
                name: "Careers",
                href: paths.dashboard.careers,
              },
              {
                name: "Vacancies management",
              },
            ]}
          />

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography textAlign='center' fontSize={24} fontWeight={700}>
                  {`${vacancyId ? "Edit" : "Add"} vacancy`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Tabs
                  value={currentTabValue}
                  onChange={handleChangeTab}
                  sx={{
                    px: 3,
                    boxShadow: theme =>
                      `inset 0 -2px 0 0 ${alpha(
                        theme.palette.grey[500],
                        0.08
                      )}`,
                  }}
                >
                  {supportedLanguages!?.map(tab => (
                    <Tab key={tab.value} value={tab.value} label={tab.label} />
                  ))}
                </Tabs>
                <Stack spacing={2}>
                  <Block sx={{ py: 2 }} label='Title'>
                    <RHFTextField
                      name={`translations.${indexOfCurrentLocale}.title`}
                    />
                  </Block>
                  <Block sx={{ py: 2 }} label='Description'>
                    <RHFTextField
                      name={`translations.${indexOfCurrentLocale}.description`}
                    />
                  </Block>
                  {supportedLanguages?.map(lang => {
                    const indexOfCurrentLocale = (
                      methods.watch().translations || []
                    )
                      ?.map(trans => trans.locale)
                      .indexOf(lang.value);
                    return (
                      lang.value === currentTabValue && (
                        <Stack spacing={2}>
                          <Block
                            label='Main Duties and Responsibilities'
                            sx={{ py: 2 }}
                          >
                            <Controller
                              name={`translations.${indexOfCurrentLocale}.responsibility`}
                              render={({ field, fieldState }) => (
                                <Editor
                                  value={field.value}
                                  onChange={field.onChange}
                                  id={`full-editor-${lang.value}1`}
                                  error={
                                    !!methods.formState.errors.translations!?.[
                                      indexOfCurrentLocale
                                    ]?.responsibility
                                  }
                                />
                              )}
                            />
                          </Block>
                          <Block label='Minimum Qualifications' sx={{ py: 2 }}>
                            <Controller
                              name={`translations.${indexOfCurrentLocale}.minmumQualification`}
                              render={({ field, fieldState }) => (
                                <Editor
                                  value={field.value}
                                  onChange={field.onChange}
                                  id={`full-editor-${lang.value}2`}
                                  error={
                                    !!methods.formState.errors.translations!?.[
                                      indexOfCurrentLocale
                                    ]?.minmumQualification
                                  }
                                />
                              )}
                            />
                          </Block>
                        </Stack>
                      )
                    );
                  })}
                  {Object.keys(methods.formState.errors).length ? (
                    <AddDialogsHelperText />
                  ) : (
                    <></>
                  )}
                  <Grid item xs={3}>
                    <AppLoadingButton
                      label={vacancyId ? "Edit" : "Add"}
                      isLoading={isLoading || isEditLoading}
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
      </Box>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
          }
        }}
        content={`Are you sure you want to ${
          isEditing ? "edit" : "add"
        } this vacancy?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading || isEditLoading}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default AddVacancy;
