// @mui
import { Card, Grid, Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";
import { API_URLS } from "src/api/apiUrls";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import LongMenu from "src/components/max-height-menu/max-height-menu";
import { useBoolean } from "src/hooks/use-boolean";
import { useRouter } from "src/routes/hook";
import { SortableElement } from "react-sortable-hoc";
// components
import { CategoryTopicType } from "src/types/category-topics";
import { PrmsEnum } from "src/types/permissions";

// ----------------------------------------------------------------------

type Props = {
  row: CategoryTopicType;
  supportedMessageMediums: (
    | "SMS_MESSAGE"
    | "WHATSAPP_MESSAGE"
    | "EMAIL_MESSAGE"
    | "TELEGRAM_MESSAGE"
  )[];
};

const CategoryTopicsCard = SortableElement<Props>(
  ({ row, supportedMessageMediums }: Props) => {
    const confirm = useBoolean();
    const router = useRouter();
    const showEdit =
      supportedMessageMediums.includes("SMS_MESSAGE") ||
      supportedMessageMediums.includes("TELEGRAM_MESSAGE") ||
      supportedMessageMediums.includes("WHATSAPP_MESSAGE");
    const showEmailEdit = supportedMessageMediums.includes("EMAIL_MESSAGE");

    const MENU_OPTIONS = [
      {
        label: "Delete",
        onClick: confirm.onTrue,
        icon: "material-symbols:delete-outline",
        permissions: [PrmsEnum.DELETE_PROGRAM_TOPIC],
      },
      {
        label: "Edit",
        onClick: () => router.push(`${row.id}`),
        icon: "ri:edit-2-line",
        permissions: [PrmsEnum.UPDATE_PROGRAM_TOPIC],
        show: showEdit,
      },
      {
        label: "Emails",
        onClick: () => router.push(`${row.id}/emails`),
        icon: "mdi:email-edit-outline",
        permissions: [PrmsEnum.SHOW_PROGRAM_TOPIC],
        show: showEmailEdit,
      },
    ]
      .filter((option) => (option.show === undefined ? true : option.show))
      .map((option) => {
        delete option.show;
        return option;
      });

    return (
      <Grid item xs={12} md={4}>
        <Card
          sx={{
            p: 3,
            backgroundColor: "background.cards",
            width: "100%",
            height: "100%",
          }}
        >
          <Stack spacing={3}>
            {/* Title */}
            <Card sx={{ p: 1.5 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  {row.sortOrder} - {row.name}
                </Typography>
                {/* Put long menu here */}
                <LongMenu options={MENU_OPTIONS} />
              </Stack>
            </Card>
            {/* Description */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body2">
                Users: {row._count.ProgramUserMessage}
              </Typography>
              <Typography variant="body2">Views: {row._count.users}</Typography>
            </Stack>

            {row.ProgramUserMessage[0] ? (
              <Typography>
                `Last sending: $
                {moment(row.ProgramUserMessage[0].createdAt).format(
                  "YYYY-MM-DD"
                )}
                `
              </Typography>
            ) : (
              <></>
            )}
          </Stack>
          <DeleteDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            name="topic"
            id={row.id}
            url={API_URLS.PROGRAM_TOPICS}
          />
        </Card>
      </Grid>
    );
  }
);

export default CategoryTopicsCard;
