import { m } from "framer-motion";
import { useState } from "react";
// @mui
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// hooks
import { useResponsive } from "src/hooks/use-responsive";
// _mock
import { _notifications } from "src/_mock";
// components
import { varHover } from "src/components/animate";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
//
import { usePopover } from "src/components/custom-popover";
import CustomPopover from "src/components/custom-popover/custom-popover";
import NotificationItem from "./notification-item";
import useApiServices from "src/api/useApiServices";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

export default function NotificationsPopover () {
  const popover = usePopover();
  const [currentTab, setCurrentTab] = useState("all");

  const { useGetListApi } = useApiServices();

  const smUp = useResponsive("up", "sm");

  const { data: notificationsArr } = useGetListApi<
    ResDataType["notifications"]
  >({
    url: API_URLS.NOTIFICATIONS,
  });


  const renderHead = (
    <Stack
      direction='row'
      alignItems='center'
      sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}
    >
      <Typography variant='h6' sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {!smUp && (
        <IconButton onClick={popover.onClose}>
          <Iconify icon='mingcute:close-line' />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notificationsArr?.items?.map(notification => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        color={popover.open ? "primary" : "default"}
        onClick={popover.onOpen}
      >
        <Badge badgeContent={0} color='error'>
          <Iconify icon='solar:bell-bing-bold-duotone' width={24} />
        </Badge>
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 320 }}
      >
        {renderHead}
        <Scrollbar sx={{ height: 320 }}>{renderList}</Scrollbar>
        <Divider />
        <Link to='/dashboard/notifications' style={{color: 'black'}}>
          <Button fullWidth sx={{ borderRadius: "0px 0px 10px 10px;" }}>
            See all
          </Button>
        </Link>
      </CustomPopover>
    </>
  );
}
