import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
  Box,
  Typography,
  Button,
  Stack,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import useApiServices from "src/api/useApiServices";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import { Controller, useForm } from "react-hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { RHFSwitch } from "src/components/hook-form";
import { convertArrayToObject } from "src/utils/array-to-obj";

const NotificationsSettings = () => {
  const settings = useSettingsContext();
  const [settingId, setSettingId] = useState<number | string>("");
  const notifications = useForm();

  const { useGetListApi, usePatchApi } = useApiServices();
  const {
    data: adminSettings,
    isLoading,
    error,
    refetch,
  } = useGetListApi<ResDataType["adminSettings"]>({
    url: API_URLS.ADMIN_SETTINGS,
    onSuccess: (data: ResDataType["adminSettings"]) => {
      notifications.reset(convertArrayToObject(data, "key", "value"));
    },
  });

  const { handleSubmit } = notifications;

  const { mutate, isLoading: isEditing } = usePatchApi({
    url: API_URLS.EDIT_SETTINGS,
    id: settingId,
  });

  const onSubmit = handleSubmit(async (data: any) => {
    let prop: any = "";
    for (prop in data) {
      switch (prop) {
        case "1":
        case true:
          data[prop] = "1";
          break;
        default:
          data[prop] = "0";
          break;
      }
    }
    mutate(data);
  });

  return (
    <>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isEditing}
          errorMessage={error}
        >
          <FormProvider onSubmit={onSubmit} methods={notifications}>
            <Stack spacing={2} width={{ xs: "100%", sm: "50%" }}>
              {adminSettings!?.map(setting => {
                return (
                  <Box
                    key={setting.id}
                    display='flex'
                    alignItems='center'
                    width='100%'
                    justifyContent='space-between'
                  >
                    <Typography>{convertPhrase(setting.key)}</Typography>
                    <Controller
                      name={setting.key}
                      control={notifications.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <div>
                            <FormControlLabel
                              label=''
                              control={
                                <Switch
                                  {...field}
                                  id={`${setting.id}`}
                                  checked={
                                    field.value === "1" || field.value === true
                                  }
                                  sx={{ ml: 0 }}
                                  onClick={() => {
                                    setSettingId(setting.id);
                                    mutate({
                                      value: setting.value === "1" ? "0" : "1",
                                    });
                                  }}
                                />
                              }
                            />
                          </div>
                        );
                      }}
                    />
                  </Box>
                );
              })}
            </Stack>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default NotificationsSettings;

function convertPhrase (phrase: string) {
  const words = phrase.split("_");
  words.forEach((word, index) => {
    words[index] = word[0].toUpperCase() + word.substring(1);
  });
  const convertedPhrase = words.join(" ");
  return convertedPhrase;
}
