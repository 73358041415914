import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { Controller } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import DetailsCard from "src/components/details-card/DetailsCard";
import Image from "src/components/image/image";
import { useSettingsContext } from "src/components/settings";
import { paths } from "src/routes/paths";
import { ContactUsDetailsDtoType } from "src/types/contact-us";
import FormProvider from "src/components/hook-form/form-provider";
import Editor from "src/components/editor/editor";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { useCallback } from "react";
import { useResponsive } from "src/hooks/use-responsive";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReplyToTicketSchema } from "src/schemas";
import { ConfirmDialog } from "src/components/custom-dialog";
import { useBoolean } from "src/hooks/use-boolean";
import AppButton from "src/components/common/AppButton";

type AddReplyType = {
  message: string;
  contactUsId: number;
};

type ChangeToClosed = {
  status: "CLOSED" | "OPENED";
};

export default function ContactUsReply () {
  // #region States
  const settings = useSettingsContext();
  const confirmChange = useBoolean();
  const { contactUsId } = useParams();
  const { useGetListApi, useGetItemApi, usePostApi, usePatchApi } =
    useApiServices();
  const mdUp = useResponsive("up", "md");
  // #endregion States

  // #region Services
  const { data, isLoading, error } = useGetListApi<
    ResDataType["contactUsReplies"]
  >({
    url: API_URLS.CONTACT_US_REPLIES,
    parameters: {
      contactUsId: contactUsId!,
    },
  });

  const { data: contactUsInfo } = useGetItemApi<ContactUsDetailsDtoType>({
    url: API_URLS.CONTACT_US,
    id: contactUsId,
  });

  const isClosed = contactUsInfo?.status.value === "CLOSED";

  const { mutate: makeClosed, isLoading: isClosing } =
    usePatchApi<ChangeToClosed>({
      url: API_URLS.CONTACT_US,
      id: contactUsId!,
      onError: () => confirmChange.onFalse(),
      onSuccess: () => confirmChange.onFalse(),
    });

  const { mutate, isLoading: isAdding } = usePostApi<AddReplyType>({
    url: API_URLS.CONTACT_US_REPLIES,
    onSuccess: _ => methods.setValue("message", ""),
  });
  // #endregion Services

  // #region Form
  const methods = useForm<AddReplyType>({
    defaultValues: {
      contactUsId: +contactUsId!,
      message: "",
    },
    resolver: yupResolver(ReplyToTicketSchema),
  });

  const {
    handleSubmit,
    formState: {
      errors: { message: messageError },
    },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddReplyType) => {
      mutate(data);
    },
    [confirmChange]
  );
  // #endregion Form

  // #region Cards details
  const messageDetails = [
    {
      label: "Email",
      value: contactUsInfo?.email!,
    },
    {
      label: "Last updated",
      value: moment(contactUsInfo?.updatedAt!).format("YYYY-MM-DD"),
    },
    {
      label: "Assigned to",
      value: contactUsInfo?.admin.fullName!,
    },
  ];

  const inquiryDetails = [
    {
      label: "Subject",
      value: contactUsInfo?.subject!,
    },
    {
      label: "Status",
      value: contactUsInfo?.status.label!,
    },
  ];
  // #endregion Cards details

  return (
    <>
      <Helmet>
        <title>Contact us details</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Contact us",
              href: paths.dashboard.contactUs,
            },
            {
              name: contactUsInfo?.status.label,
            },
            {
              name: "View message",
            },
          ]}
        />
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4} my={1}>
              <Grid item xs={12} md={6}>
                <DetailsCard
                  items={messageDetails}
                  cardTitle='Message Details'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DetailsCard
                  items={inquiryDetails}
                  cardTitle='Inquiry Details'
                />
              </Grid>
              <Restricted to={PrmsEnum.SHOW_CONTACT_US_REPLAY}>
                {data?.items.map(msg => {
                  const name = msg.adminId
                    ? msg.admin?.fullName
                    : msg.user?.email;
                  return (
                    <Grid item xs={12}>
                      <MessageView
                        name={name!}
                        date={msg.createdAt}
                        message={msg.message}
                        isAdmin={!!msg.adminId}
                      />
                    </Grid>
                  );
                })}
              </Restricted>
              <Restricted to={PrmsEnum.CREATE_CONTACT_US_REPLAY}>
                <Grid item xs={12}>
                  <Block label='New reply'>
                    <Controller
                      name='message'
                      render={({ field, fieldState }) => (
                        <Editor
                          value={field.value}
                          onChange={field.onChange}
                          id='full-editor'
                          error={!!messageError}
                        />
                      )}
                    />
                  </Block>
                </Grid>
              </Restricted>
              <Grid item xs={12}>
                <Stack
                  direction='row'
                  justifyContent={{ md: "flex-end", xs: "stretch" }}
                  spacing={2}
                >
                  <AppButton
                    sx={{ width: 200 }}
                    label={isClosed ? "Open ticket" : "Close ticket"}
                    color='secondary'
                    onClick={confirmChange.onTrue}
                    fullWidth={!mdUp}
                  />
                  <AppLoadingButton
                    sx={{ width: 200 }}
                    label='Send reply'
                    isLoading={isAdding}
                    fullWidth={!mdUp}
                  />
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
      </Container>
      {/* Confirm changing status */}
      <ConfirmDialog
        open={confirmChange.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirmChange.onFalse();
          }
        }}
        content={`Are you sure you want to change this ticket to ${
          isClosed ? "opened" : "closed"
        }`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirmChange.onFalse();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isClosing}
              onClick={() =>
                makeClosed({
                  status: isClosed ? "OPENED" : "CLOSED",
                })
              }
            />
          </>
        }
      />
    </>
  );
}

const MessageView = ({
  name,
  date,
  message,
  isAdmin,
}: {
  name: string;
  date: string;
  message: string;
  isAdmin: boolean;
}) => {
  return (
    <Card sx={{ p: 3 }}>
      {/* User name */}
      <Stack direction='row' width='100%' justifyContent='space-between'>
        <Typography pt={1.5} px={2} variant='body2' fontWeight={500} mb={0}>
          {name}
        </Typography>
        <Typography pt={1.5} px={2} variant='body2' fontWeight={500} mb={0}>
          {moment(date).format("YYYY-MM-DD [  ] hh:mm")}
        </Typography>
      </Stack>
      <Typography pb={1.5} px={2} variant='caption' fontWeight={400}>
        {isAdmin ? "Admin" : "User"}
      </Typography>
      <Divider />
      <Box dangerouslySetInnerHTML={{ __html: message }} p={2} />
    </Card>
  );
};
