import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import AppButton from 'src/components/common/AppButton';

interface IHeader {
  withButton?: boolean;
  buttonLabel?: string;
  onCLick?: () => void;
  header: string;
}

const Header: React.FC<IHeader> = (props) => {
  const { withButton = false, buttonLabel, onCLick, header } = props;

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      py={2}
    >
      <Typography fontSize={24} fontWeight={400}>
        {header}
      </Typography>
      {withButton && (
        <AppButton
          label={buttonLabel}
          onClick={onCLick}
          startIcon={<BorderColorOutlinedIcon />}
        />
      )}
    </Stack>
  );
};

export default Header;
