import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useEffect } from "react";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import Scrollbar from "src/components/scrollbar";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import AddProgramBadge from "./AddProgramBadge";
import ProgramBadgesTableRow from "./ProgramBadgesTableRow";

const TABLE_HEAD = [
  { id: "1", label: "Badge" },
  { id: "2", label: "Program name" },
  { id: "3", label: "Created date" },
  { id: "4", label: "Last updated " },
  { id: "5", label: "Actions" },
];

const ProgramBadges = ({ setProgramBadgesNumber }: any) => {
  const addDialog = useBoolean();
  const { useGetListApi } = useApiServices();
  const table = useTable({ defaultRowsPerPage: 10 });

  const { data: programBadges, isLoading } = useGetListApi<
    ResDataType["badges"]
  >({
    url: API_URLS.BADGES,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
      badgeType: "PROGRAM",
    },
  });

  useEffect(() => {
    if (programBadges) setProgramBadgesNumber(programBadges?.items?.length);
  }, [programBadges]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Restricted to={PrmsEnum.CREATE_BADGE}>
              <Restricted to={PrmsEnum.CREATE_BADGE}>
                <Box display="flex" justifyContent="flex-end" p={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addDialog.onTrue}
                  >
                    Add a new badge
                  </Button>
                </Box>
              </Restricted>
            </Restricted>
            <TableContainer>
              <Scrollbar>
                <Table>
                  <TableHeadCustom headLabel={TABLE_HEAD} />
                  <TableBody>
                    {isLoading ? (
                      [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: 80 }} />
                      ))
                    ) : (
                      <>
                        {programBadges?.items?.map((row: any) => (
                          <ProgramBadgesTableRow key={row.id} row={row} />
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
            {programBadges && (
              <TablePaginationCustom
                count={programBadges?.totalItems}
                page={programBadges?.currentPage - 1}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
              />
            )}
          </Card>
        </Grid>
      </Grid>

      {/* Add Dialog */}
      {addDialog.value && (
        <AddProgramBadge open={addDialog.value} onClose={addDialog.onFalse} />
      )}
    </>
  );
};

export default ProgramBadges;
