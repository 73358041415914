// components
import Chart, { useChart } from "src/components/chart";

// ----------------------------------------------------------------------

type Props = {
  series: {
    name: string;
    data: number[];
  }[];
  xaxis: string[];
};

export default function ChartColumnSingle ({ series, xaxis }: Props) {
  const chartOptions = useChart({
    plotOptions: {
      bar: {
        columnWidth: "16%",
      },
    },
    stroke: {
      show: false,
    },
    xaxis: {
      categories: xaxis,
    },
    tooltip: {
      y: {
        formatter: (value: number) => `${value}`,
      },
    },
  });

  return (
    <Chart
      dir='ltr'
      type='bar'
      series={series}
      options={chartOptions}
      height={320}
    />
  );
}
