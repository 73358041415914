import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, MenuItem, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, { RHFSelect } from "src/components/hook-form";
import { Upload } from "src/components/upload";
import { useBoolean } from "src/hooks/use-boolean";
import { AddProgramBadgeSchema, NewPrgBadgeSchema } from "src/schemas";
import { BadgeDetailsDtoType, IAddProgramBadge } from "src/types/badges";
import { UploadFileResponseType } from "src/types/upload-file-res";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";

interface IAddProgramBadgeProps {
  open: boolean;
  onClose: VoidFunction;
  id?: string | number;
}

const AddProgramBadge: React.FC<IAddProgramBadgeProps> = props => {
  // #region States
  const confirm = useBoolean();
  const { open, onClose, id } = props;
  const isEditing = !(id === null || id === undefined);
  const { usePostApi, useGetListApi, useGetItemApi, usePatchApi } =
    useApiServices();
  const [featureImage, setFeatureImage] = useState<File | string | null>(null);
  const [dataToSend, setDataToSend] = useState<IAddProgramBadge>();
  // #endregion States

  // #region Services
  const { data: programs } = useGetListApi<ResDataType["programs"]>({
    url: API_URLS.PROGRAMS,
  });

  const {
    refetch,
    isRefetching,
    isLoading: isLoadingItem,
  } = useGetItemApi({
    url: API_URLS.BADGES,
    id: id!,
    onSuccess: (data: BadgeDetailsDtoType) => {
      if (id) {
        methods.setValue(`featureImage`, data.featureImage);
        methods.setValue("programId", data.programId);
        setFeatureImage(data.featureImage);
      }
    },
  });

  const {
    mutate: uploadPhoto,
    isLoading: isUploadingPhoto,
    error: uploadError,
  } = usePostApi({
    url: API_URLS.UPLOAD_FILE,
    withFormData: true,
    onSuccess: (data: UploadFileResponseType) => {
      methods.setValue("featureImage", data.filePath);
    },
  });

  const {
    mutate: addBadge,
    isLoading: isAdding,
    error: addError,
  } = usePostApi<IAddProgramBadge>({
    url: API_URLS.BADGES,
    onSuccess: () => {
      onClose();
      confirm.onFalse();
      methods.reset();
      setFeatureImage("");
    },
  });

  const {
    mutate: editBadge,
    isLoading: isEditLoading,
    error: editError,
  } = usePatchApi<IAddProgramBadge>({
    url: API_URLS.BADGES,
    id: id!,
    onSuccess: () => {
      confirm.onFalse();
      onClose();
    },
  });

  const mutate = isEditing ? editBadge : addBadge;
  // #endregion Services

  // #region Form
  const methods = useForm<IAddProgramBadge>({
    defaultValues: {
      featureImage: "",
      programId: undefined,
    },
    resolver: yupResolver(NewPrgBadgeSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  // #endregion Form

  // #region Handlers
  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFeatureImage(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);

  const onSubmit = useCallback(
    async (data: IAddProgramBadge) => {
      if (!isEditing) data.badgeType = "PROGRAM";
      confirm.onTrue();
      setDataToSend(data);
    },
    [confirm]
  );
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    if (featureImage && open && typeof featureImage !== "string") {
      uploadPhoto(featureImage);
      methods.clearErrors("featureImage");
    }
  }, [featureImage]);

  useEffect(() => {
    open && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // #endregion useEffect

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "4px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <AppLoadingAndErrorWrapper
              isLoading={
                isAdding ||
                isEditLoading ||
                isRefetching ||
                isLoadingItem ||
                isUploadingPhoto
              }
              errorMessage={addError || editError || uploadError}
              sx={{ height: "auto" }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography fontSize={28} fontWeight={500} align='center'>
                      Add new Program Badge
                    </Typography>
                  </Grid>
                  {!isEditing && (
                    <Grid item xs={12}>
                      <Block label='Program Name'>
                        <RHFSelect name='programId'>
                          {programs?.items.map(program => (
                            <MenuItem key={program.id} value={program.id}>
                              {program.name}
                            </MenuItem>
                          ))}
                        </RHFSelect>
                      </Block>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Block label='Upload badge'>
                      <Upload
                        file={featureImage}
                        error={!!errors.featureImage}
                        onDrop={handleDropSingleFile}
                        onDelete={() => {
                          setFeatureImage(null);
                          methods.setValue("featureImage", "");
                        }}
                      />
                    </Block>
                  </Grid>
                  <Grid item xs={6}>
                    <AppButton
                      label={"Cancel"}
                      color='secondary'
                      onClick={onClose}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton
                      label={"Upload & Save"}
                      isLoading={isAdding || isEditLoading}
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={`Are you sure you want to ${
          isEditing ? "edit" : "add"
        } this badge?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isAdding || isEditLoading}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default AddProgramBadge;
