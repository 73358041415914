import { Card, Grid, Table, TableBody, TableContainer } from "@mui/material";
import Container from "@mui/material/Container";
import moment from "moment";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Header from "src/components/Header";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import StatisticCard from "src/components/StatisticCard";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { paths } from "src/routes/paths";
import GiftsTableRow from "src/sections/gifts/GiftsTableRow";
import GiftsTableToolbar from "src/sections/gifts/GiftsTableToolbar";

const TABLE_HEAD = [
  { id: "1", label: "Gift" },
  { id: "2", label: "Sender Name" },
  { id: "3", label: "Recipient Name" },
  { id: "4", label: "Program" },
  { id: "5", label: "Type" },
  { id: "6", label: "Status" },
  { id: "7", label: "Receive date" },
  { id: "8", label: "Actions" },
];

export default function Gifts () {
  const settings = useSettingsContext();
  const isInGift = window.location.href.includes("gift");
  const table = useTable({ defaultRowsPerPage: 10 });
  const { useGetListApi, useDeleteApi } = useApiServices();
  const { userId } = useParams();
  const methods = useForm({
    defaultValues: {
      sendingType: "",
      programId: "",
      messageMedium: "",
      status: "",
      senderId: {},
      recipientId: {},
      userId: null,
    },
  });

  const [search, setSearch] = useState<any>({
    sendingType: "",
    programId: "",
    messageMedium: "",
    status: "",
    senderId: {},
    recipientId: {},
    userId,
  });

  const { data, isLoading, error, refetch, isRefetching } = useGetListApi<
    ResDataType["gifts"]
  >({
    url: API_URLS.GIFTS,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
      sendingType: search.sendingType || undefined,
      programId: search.programId || undefined,
      messageMedium: search.messageMedium || undefined,
      status: search.status || undefined,
      senderId: search.senderId?.value || undefined,
      recipientId: search.recipientId?.value || undefined,
      userId: search.userId,
    },
  });

  const { data: statistics } = useGetListApi<ResDataType["giftsStatistics"]>({
    url: API_URLS.GIFTS_STATISTICS,
  });

  const { mutate, isLoading: isDeleteLoading } = useDeleteApi({
    url: API_URLS.GIFTS,
    urlAfterSuccess: window.location.href,
    onSuccess: () => refetch(),
  });

  return (
    <>
      <Helmet>
        <title>Gifts</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        {isInGift && (
          <CustomBreadcrumbs
            links={[
              {
                name: "Home",
                href: paths.dashboard.root,
              },
              {
                name: "Gifts",
                href: paths.dashboard.gifts.received,
              },
            ]}
          />
        )}
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isDeleteLoading || isRefetching}
          errorMessage={error}
        >
          {isInGift && (
            <Header header={`Today, ${moment().format("YYYY-MM-DD")}`} />
          )}
          <Grid container spacing={4} my={1}>
            {isInGift &&
              statistics?.map((e: any, index: any) => (
                <Grid item xs={12} md={3}>
                  <StatisticCard
                    cardCount={e.count}
                    cardTitle={e.label}
                    index={index}
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <FormProvider methods={methods}>
                <Card>
                  <GiftsTableToolbar
                    values={methods.watch()}
                    setSearch={setSearch}
                    reset={methods.reset}
                    data={data!}
                  />
                  <TableContainer>
                    <Scrollbar>
                      <Table>
                        <TableHeadCustom headLabel={TABLE_HEAD} />
                        <TableBody>
                          {isLoading ? (
                            [...Array(table.rowsPerPage)].map((i, index) => (
                              <TableSkeleton key={index} sx={{ height: 80 }} />
                            ))
                          ) : (
                            <>
                              {data!?.items?.map((row: any) => (
                                <GiftsTableRow
                                  key={row.id}
                                  row={row}
                                />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                  {data && (
                    <TablePaginationCustom
                      count={data?.totalItems}
                      page={data.currentPage ? +data.currentPage - 1 : 1}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table?.onChangePage}
                      onRowsPerPageChange={table?.onChangeRowsPerPage}
                    />
                  )}
                </Card>
              </FormProvider>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
