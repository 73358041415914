import React from "react";
import { Stack, MenuItem } from "@mui/material";
import useApiServices from "src/api/useApiServices";
import { API_URLS } from "src/api/apiUrls";
import { Block } from "src/components/Block";
import {
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { IBaseStatusDto } from "src/types";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import Container from "@mui/material/Container";

const PersonalPreferencesForm = () => {
  const { useGetListApi } = useApiServices();

  const {
    data: genderType,
    isLoading: isTopicLengthLoading,
    error: topicLengthError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.GENDER_TYPE,
  });

  const {
    data: islamicStatus,
    isLoading: isLanguagesLoading,
    error: languagesError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.ISLAMIC_STATUS_TYPE,
  });

  const isLoading = isTopicLengthLoading || isLanguagesLoading;

  const errorMessage = topicLengthError || languagesError;

  return (
    <AppLoadingAndErrorWrapper
      isLoading={isLoading}
      errorMessage={errorMessage}
    >
      <Container sx={{ py: 2 }}>
        <Stack spacing={2} textAlign='left'>
          <Stack spacing={2}>
            <Block label='Date of birth'>
              <RHFDatePicker name='dateOfBirth' maxDate={new Date()} />
            </Block>
            <Block label='Gender'>
              <RHFSelect name='gender'>
                {(genderType || [])?.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            <Block label='Islamic status'>
              <RHFSelect name='islamicStatus'>
                {(islamicStatus || [])?.map((option, index) => (
                  <MenuItem key={index} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Block>
            <Block label='Specialized'>
              <RHFTextField name='specialized' />
            </Block>
          </Stack>
        </Stack>
      </Container>
    </AppLoadingAndErrorWrapper>
  );
};

export default PersonalPreferencesForm;
