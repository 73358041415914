import { Card, Grid, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import DetailsCard from "src/components/details-card/DetailsCard";
import Image from "src/components/image/image";
import { useSettingsContext } from "src/components/settings";
import { ASSETS_API } from "src/config-global";
import { paths } from "src/routes/paths";
import { ApplicationDetailsDtoType } from "src/types/applications";

export default function ApplicationDetails () {
  const settings = useSettingsContext();
  const { applicantId } = useParams();
  const { useGetItemApi } = useApiServices();

  const { data, isLoading, error } = useGetItemApi<ApplicationDetailsDtoType>({
    url: API_URLS.APPLICATIONS,
    id: applicantId,
  });

  const senderInfo = [
    {
      label: "Name",
      value: data?.firstName! + data?.lastName!,
    },
    {
      label: "Applied vacancy",
      value: data?.career.title!,
    },
    {
      label: "Email",
      value: data?.email!,
    },
    {
      label: "Phone number",
      value: data?.mobilePhone!,
    },
    {
      label: "Application date",
      value: moment(data?.createdAt!).format("YYYY-MM-DD"),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Application details</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Careers",
              href: paths.dashboard.careers,
            },
            {
              name: "Applicant tracking",
            },
          ]}
        />
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <Grid container spacing={4} my={1}>
            <Grid item xs={12}>
              <DetailsCard items={senderInfo} cardTitle='Sender information' />
            </Grid>
            <Grid item xs={12}>
              <Container
                maxWidth={settings.themeStretch ? false : "xl"}
                sx={{ height: "100%" }}
              >
                <Card>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ px: 4, py: 2 }}
                  >
                    <Typography variant='h4'>CV</Typography>
                    <Link to={`${ASSETS_API}/${data?.resume}`} target='_blank'>
                      <AppButton label='Download' onClick={() => {}} />
                    </Link>
                  </Stack>
                </Card>
              </Container>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
