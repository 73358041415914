import {
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Box,
  Button,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import LegalContentTableRow from "src/sections/pages/legal-content/LegalContentTableRow";

const TABLE_HEAD = [
  { id: "1", label: "Legal content type" },
  { id: "2", label: "Content in English" },
  { id: "3", label: "Last updated" },
  { id: "4", label: "Actions" },
];

export default function LegalContent () {
  const settings = useSettingsContext();
  const isLight = settings.themeMode === "light";
  const table = useTable({ defaultRowsPerPage: 10 });
  const { useGetListApi, useDeleteApi } = useApiServices();
  const success = useBoolean();

  const methods = useForm({
    defaultValues: {
      type: "",
      translations: {
        some: {
          question: "",
          answer: "",
        },
      },
    },
  });

  const { data, isLoading, error, isRefetching } = useGetListApi<
    ResDataType["websitePages"]
  >({
    url: API_URLS.WEBSITE_PAGES,
  });

  return (
    <>
      <Helmet>
        <title>FAQs</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isRefetching}
          errorMessage={error}
        >
          <Grid container spacing={4} my={1}>
            <Grid item xs={12}>
              <FormProvider methods={methods}>
                <Card>
                  <TableContainer>
                    <Scrollbar>
                      <Table>
                        <TableHeadCustom headLabel={TABLE_HEAD} />
                        <TableBody>
                          {isLoading ? (
                            [...Array(table.rowsPerPage)].map((i, index) => (
                              <TableSkeleton key={index} sx={{ height: 80 }} />
                            ))
                          ) : (
                            <>
                              {data!.items?.map(row => (
                                <LegalContentTableRow key={row.value} row={row} />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                  {data && (
                    <TablePaginationCustom
                      count={data?.totalItems}
                      page={data.currentPage ? +data.currentPage - 1 : 1}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table?.onChangePage}
                      onRowsPerPageChange={table?.onChangeRowsPerPage}
                    />
                  )}
                </Card>
              </FormProvider>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
