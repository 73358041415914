import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Header from "src/components/Header";
import { useSettingsContext } from "src/components/settings";
import usePermission from "src/PermissionProvider/usePermission";
import { paths } from "src/routes/paths";
import CategoryBadges from "src/sections/badges/categoryBadges/CategoryBadges";
import ProgramBadges from "src/sections/badges/programBadges/ProgramBadges";
import { PrmsEnum } from "src/types/permissions";

export default function BadgesPage () {
  const settings = useSettingsContext();

  const [currentTabValue, setCurrentTabValue] = useState<string>("1");
  const [programBadgesNumber, setProgramBadgesNumber] = useState<number>(0);
  const [categoryBadgesNumber, setProgramCategoryNumber] = useState<number>(0);
  const [, isCreateBadgeAllowd] = usePermission(PrmsEnum.CREATE_BADGE);
  const [, ] = usePermission(PrmsEnum.SHOW_BADGE);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  return (
    <>
      <Helmet>
        <title>Badges</title>
      </Helmet>
      <Stack spacing={4}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Badges",
            },
          ]}
        />
        <Container
          maxWidth={settings.themeStretch ? false : "xl"}
          sx={{ height: "100%" }}
        >
          <Stack spacing={4}>
            <Header header={`Today, ${moment().format("DD-MM-YYYY")}`} />

            <TabContext value={currentTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChangeTab}>
                  <Tab label='Badges' value='1' />
                  <Tab label='Categories' value='2' />
                </TabList>
              </Box>
              <TabPanel value='1'>
                <ProgramBadges
                  setProgramBadgesNumber={setProgramBadgesNumber}
                />
              </TabPanel>
              <TabPanel value='2'>
                <CategoryBadges
                  setProgramCategoryNumber={setProgramCategoryNumber}
                />
              </TabPanel>
            </TabContext>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
