import { IBaseStatusDto } from "src/types";
import { AdminSettingsDtoType } from "src/types/admin-settings";
import { AdminsDtoType } from "src/types/admins";
import { AnalyticsDataType } from "src/types/analytics";
import { ApplicationsDtoType } from "src/types/applications";
import { IBadges } from "src/types/badges";
import { BlogsDataType } from "src/types/blogs";
import { BlogsCategoriesDataType } from "src/types/blogs-categories";
import { CareersDtoType } from "src/types/careers";
import { CategoryTopicsDataType } from "src/types/category-topics";
import { ContactUsDto } from "src/types/contact-us";
import { ContactUsRepliesDtoType } from "src/types/contact-us-replies";
import { CurrentProgramDataType } from "src/types/currentProgram";
import { FAQsDataType } from "src/types/faqs";
import { GiftsDataType } from "src/types/gifts";
import { NotificationsDtoType } from "src/types/notifications";
import { PermissionsDtoType } from "src/types/permissions";
import { ProgramAdminDSType } from "src/types/program";
import { ProgramAnalyticsDtoType } from "src/types/program-analytics";
import { ProgramBeneficiariesDataType } from "src/types/program-beneficiaries";
import { ProgramCategoriesDataType } from "src/types/program-categories";
import { ProgramFeaturesDataType } from "src/types/program-features";
import { ProgramUsersDataType } from "src/types/program-users";
import { ProgramsDataType } from "src/types/programs-dropdown";
import { RolesDtoType } from "src/types/roles";
import { SocialMediaLinksDtoType } from "src/types/social-media-links";
import { IStatisticsDto } from "src/types/statistics";
import { UserBadgesDtoType } from "src/types/user-badges";
import { UserProfileDataType } from "src/types/userProfile";
import { UsersDataType } from "src/types/users";
import { WebsitePagesDtoType } from "src/types/website-pages";

export const API_URLS = {
  AUTH_USER_PROFILE: "/dashboard/auth-admin/profile",
  // Notifications
  // Programs
  CURRENT_USER_PROGRAM: "program-users/in-progrss-program-with-count",
  LATEST_MESSAGES: "program-users/latest-message-program",
  PAST_PROGRAMS: "program-users",
  PROGRAMS_WITHOUT_AUTH: "programs",
  CHANGE_PROGRAM_STATUS: "program-users",
  SUGGESTED_UNSUBSCRIBED_PROGRAMS: "programs/random-unsubscribed-programs",
  // Gifts
  RECEIVED_GIFTS: "gifts/received",
  SENT_GIFTS: "gifts/sent",
  LATEST_GIFTS: "gifts/latest-gift-received",
  // Profile
  ADMIN_PROFILE: "/dashboard/auth-admin/profile",
  USER_PROFILE: "/dashboard/users",
  // Enums
  MESSAGE_MEDIUM: "app/message-medium-type",
  GENDER_TYPE: "app/gender-type",
  ISLAMIC_STATUS_TYPE: "app/islamic-status",
  TOPIC_LENGTH: "app/topic-length",
  LANGUAGE_TYPE: "app/language-type",
  MESSAGES_FREQUENCY: "app/message-frequency-type",
  WEEK_DAYS: "app/week-days",
  GIFT_STATUS: "app/gift-status",
  SENDING_TYPE_GIFT: "app/sending-type-gift",
  PROGRAM_USER_STATUS: "app/program-user-status",
  // Admin dashboard
  PROGRAM_USERS: "/dashboard/program-users",
  PROGRAM_FEATURES: "/dashboard/program-features",
  PROGRAM_BENEFICIARIES: "/dashboard/program-beneficiaries",
  PROGRAMS: "/dashboard/programs",
  PROGRAM_CATEGORIES: "/dashboard/program-categories",
  UPLOAD_FILE: "app/upload-file",
  PROGRAM_TOPICS: "/dashboard/program-topics",
  SORT_PROGRAM_TOPICS: (id: number) => `/dashboard/program-topics/${id}/sort-order`,
  USERS: "/dashboard/users",
  GIFTS: "/dashboard/gifts",
  GIFTS_STATISTICS: "/dashboard/gifts/statistic",
  GIFTS_STATUS: "app/gift-status",
  BLOGS: "/dashboard/blogs",
  BLOGS_CATEGORIES: "/dashboard/blog-categories",
  FAQS: "/dashboard/faqs",
  USERS_COUNT: "/dashboard/users/users-count",
  ANALYTICS: "admin/home",
  USER_PROGRAMS: "/dashboard/program-users/with-count",
  BLOGS_STATISTICS: "/dashboard/blogs/blogs-count",
  CONTACT_US: "/dashboard/contact-us",
  ASSIGNED_TO_ME: "/dashboard/contact-us/assigned-to-me",
  WEBSITE_PAGES: "/dashboard/website-pages",
  BADGES: "/dashboard/badges",
  USER_BADGES: (id: string) => `/dashboard/users/${id}/badges`,
  VACANCIES: "/dashboard/careers",
  APPLICATIONS: "/dashboard/careers/applications",
  ADMIN_SETTINGS: "/dashboard/website-settings/admin-settings",
  EDIT_SETTINGS: "/dashboard/website-settings",
  CHANGE_PASSWORD: "/dashboard/auth-admin/change-password",
  NOTIFICATIONS: "/dashboard/notifications",
  PROGRAM_ANALYTICS: "/dashboard/programs/program-count-info",
  SM_LINKS_SETTINGS: "/dashboard/website-settings",
  ADMINS: "/dashboard/admins",
  CONTACT_US_REPLIES: "/dashboard/contact-us-replaies",
  ROLES: "/dashboard/roles",
  PERMISSIONS: "/dashboard/roles/permissions",
};

// Define a type that represents the response type of each endpoint
export type ResDataType = {
  dropDowns: IBaseStatusDto[];
  programs: ProgramsDataType;
  currentProgram: CurrentProgramDataType;
  programUsers: ProgramUsersDataType;
  programFeatures: ProgramFeaturesDataType;
  programBeneficiaries: ProgramBeneficiariesDataType;
  programAdminType: ProgramAdminDSType;
  programCategoriesType: ProgramCategoriesDataType;
  programCategoryTopicsType: CategoryTopicsDataType;
  users: UsersDataType;
  gifts: GiftsDataType;
  giftsStatistics: IStatisticsDto[];
  blogs: BlogsDataType;
  blogCategories: BlogsCategoriesDataType;
  userProfile: UserProfileDataType;
  faqs: FAQsDataType;
  analytics: AnalyticsDataType;
  contactUs: ContactUsDto;
  websitePages: WebsitePagesDtoType;
  badges: IBadges;
  careers: CareersDtoType;
  applications: ApplicationsDtoType;
  adminSettings: AdminSettingsDtoType[];
  notifications: NotificationsDtoType;
  programAnalytics: ProgramAnalyticsDtoType;
  socialMediaLinks: SocialMediaLinksDtoType;
  userBadges: UserBadgesDtoType;
  admins: AdminsDtoType;
  contactUsReplies: ContactUsRepliesDtoType;
  roles: RolesDtoType;
  permissions: PermissionsDtoType;
};
