import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';

interface IMessageCard {
  messageTitle: string;
  messageContent: string;
  withGridContainer?: boolean;
}

const MessageCard: React.FC<IMessageCard> = (props) => {
  const { messageTitle, messageContent, withGridContainer = true } = props;

  // TODO Implement it in a better way
  if (withGridContainer) {
    return (
      <Grid item xs={12} md={4}>
        <Box sx={{ border: '1px solid #4F4F4F' }} borderRadius={1}>
          <Box bgcolor="#FFEAAE" padding={2} borderRadius={1} height={48}>
            <Typography color='black' fontSize={20} fontWeight={400}>
              {messageTitle}
            </Typography>
          </Box>
          <Box padding={2}>
            <Typography fontSize={14} fontWeight={400} textAlign="justify">
              {messageContent}
            </Typography>
          </Box>
        </Box>
      </Grid>
    );
  }

  return (
    <Box sx={{ border: '1px solid #4F4F4F' }} borderRadius={1}>
      <Box bgcolor="#FFEAAE" padding={2} borderRadius={1} height={48}>
        <Typography color='black' fontSize={20} fontWeight={400}>
          {messageTitle}
        </Typography>
      </Box>
      <Box padding={2}>
        <Typography fontSize={14} fontWeight={400} textAlign="justify">
          {messageContent}
        </Typography>
      </Box>
    </Box>
  );
};

export default MessageCard;
