import * as Yup from "yup";

export const newAdminFormSchema = {
  fullName: "",
  email: "",
  mobilePhone: "",
  password: "",
  confirmPassword: "",
  roleId: 0,
  active: true,
};

export const NewAdminFormSchemaResolver = (adminId: string) =>
  Yup.object().shape(
    !adminId
      ? {
          fullName: Yup.string().required("Name is required"),
          email: Yup.string().required("Email is required"),
          mobilePhone: Yup.string().required("Mobile phone is required"),
          active: Yup.boolean().required("Status is required"),
          roleId: Yup.number().required("Select the admin role"),
          password: Yup.string().required("Password is required").min(8, 'Must contain 8 chars at least'),
          confirmPassword: Yup.string()
            .required("Please confirm your password")
            .oneOf([Yup.ref("password"), ""], "Does not match with field1!"),
        }
      : {
          fullName: Yup.string().required("Name is required"),
          email: Yup.string().required("Email is required"),
          mobilePhone: Yup.string().required("Mobile phone is required"),
          active: Yup.boolean().required("Status is required"),
          roleId: Yup.number().required("Select the admin role"),
        }
  );
