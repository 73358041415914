import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import Restricted from 'src/PermissionProvider/Restricted';
import Iconify from '../iconify/iconify';

const ITEM_HEIGHT = 48;

type MaxHeightMenuPropsType = {
  options: { label: string; onClick: () => void; icon?: string; permissions?: string[] }[];
};

export default function LongMenu({ options }: MaxHeightMenuPropsType) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (onClick: () => void) => {
    onClick();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      >
        {options.map((option) => {
          const menuItem = (
            <MenuItem key={option.label} onClick={() => handleChoose(option.onClick)}>
              <Stack direction="row" spacing={1} alignItems="center">
                {option.icon && (
                  <Iconify
                    icon={option.icon}
                    color="primary.darker"
                    sx={{ backgroundColor: 'primary.lighter', borderRadius: 1, p: 0.4 }}
                    width={27}
                    height={27}
                  />
                )}
                <Typography>{option.label}</Typography>
              </Stack>
            </MenuItem>
          );
          return option.permissions ? (
            <Restricted to={option.permissions}>{menuItem}</Restricted>
          ) : (
            menuItem
          );
        })}
      </Menu>
    </div>
  );
}
