import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// @mui
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Link } from "react-router-dom";
import { ConfirmDialog } from "src/components/custom-dialog";
import Iconify from "src/components/iconify/iconify";
// components
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { ContactType } from "src/types/contact-us";
import { PrmsEnum } from "src/types/permissions";
import AssignMSGDialog from "./AssignMSGDialog";

// ----------------------------------------------------------------------

type Props = {
  row: ContactType;
  isInAssignedToMe?: boolean;
};

export default function ContactUsTableRow ({
  row,
  isInAssignedToMe = false,
}: Props) {
  const assignDialog = useBoolean();
  const confirm = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row?.email}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row?.subject}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row?.email}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{moment(row?.createdAt).format("YYYY-MM-DD")}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{moment(row?.updatedAt).format("YYYY-MM-DD")}</Typography>
        </TableCell>
        {!isInAssignedToMe && (
          <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
            <Typography>{row?.status.label}</Typography>
          </TableCell>
        )}
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Tooltip title='View' placement='bottom' arrow>
            <Link to={`${row.id}`}>
              <IconButton onClick={confirm.onTrue}>
                <Iconify
                  icon='mdi:eye-circle'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Link>
          </Tooltip>
          <Restricted to={[PrmsEnum.SHOW_ADMIN, PrmsEnum.UPDATE_CONTACT_US]} andSchema>
            <Tooltip
              title={row.adminId ? "Assigned" : "Assign"}
              placement='bottom'
              arrow
            >
              <IconButton onClick={() => assignDialog.onTrue()}>
                <Iconify
                  icon={row.adminId ? "lucide:user" : "simple-line-icons:plus"}
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
        </TableCell>
      </TableRow>
      <Restricted to={[PrmsEnum.SHOW_ADMIN, PrmsEnum.UPDATE_CONTACT_US]} andSchema>
        <AssignMSGDialog
          open={assignDialog.value}
          onClose={assignDialog.onFalse}
          dialogTitle='Assign message to'
          id={row.id}
        />
      </Restricted>
    </>
  );
}
