import {
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Box,
  Button,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import AddBlogCategory from "src/sections/blogs-categories/AddBlogCategory";
import BlogsCategoriesTableRow from "src/sections/blogs-categories/BlogsCategoriesTableRow";
import BlogsCategoriesTableToolbar from "src/sections/blogs-categories/BlogsCategoriesTableToolbar";
import { PrmsEnum } from "src/types/permissions";

const TABLE_HEAD = [
  { id: "1", label: "Blog category" },
  { id: "2", label: "Pusblished by" },
  { id: "4", label: "Last updated" },
  { id: "5", label: "Actions" },
];

export default function BlogsCategories () {
  const settings = useSettingsContext();
  const table = useTable({ defaultRowsPerPage: 10 });
  const dialog = useBoolean();
  const { useGetListApi } = useApiServices();
  const methods = useForm({
    defaultValues: {
      translations: {
        some: {
          name: "",
        },
      },
    },
  });

  const [search, setSearch] = useState({
    translations: {
      some: {
        name: "",
      },
    },
  });

  const { data, isLoading, error, refetch, isRefetching } = useGetListApi<
    ResDataType["blogCategories"]
  >({
    url: API_URLS.BLOGS_CATEGORIES,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
      "translations.some.name": search.translations.some.name,
    },
  });

  return (
    <>
      <Helmet>
        <title>Categories</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isRefetching}
          errorMessage={error}
        >
          <Grid container spacing={4} my={1}>
            <Grid item xs={12}>
              <Restricted to={PrmsEnum.CREATE_BLOG_CATEGORY}>
                <Box display='flex' justifyContent='flex-end'>
                  <Button
                    onClick={dialog.onTrue}
                    variant='contained'
                    color='primary'
                  >
                    Create new category
                  </Button>
                </Box>
              </Restricted>
            </Grid>
            <Grid item xs={12}>
              <FormProvider methods={methods}>
                <Card>
                  {/* <BlogsCategoriesTableToolbar
                    values={methods.watch()}
                    setSearch={setSearch}
                    reset={methods.reset}
                    data={data!}
                  /> */}
                  <TableContainer>
                    <Scrollbar>
                      <Table>
                        <TableHeadCustom headLabel={TABLE_HEAD} />
                        <TableBody>
                          {isLoading ? (
                            [...Array(table.rowsPerPage)].map((i, index) => (
                              <TableSkeleton key={index} sx={{ height: 80 }} />
                            ))
                          ) : (
                            <>
                              {data!.items?.map(row => (
                                <BlogsCategoriesTableRow
                                  key={row.id}
                                  row={row}
                                />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                  {data && (
                    <TablePaginationCustom
                      count={data?.totalItems}
                      page={data.currentPage ? +data.currentPage - 1 : 1}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table?.onChangePage}
                      onRowsPerPageChange={table?.onChangeRowsPerPage}
                    />
                  )}
                </Card>
              </FormProvider>
            </Grid>
          </Grid>
          <AddBlogCategory
            open={dialog.value}
            onClose={dialog.onFalse}
            dialogTitle='Create a new category'
          />
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
