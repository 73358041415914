import moment from "moment";
import { ResDataType } from "src/api/apiUrls";
import * as Yup from "yup";

export const PERSONAL_PREFERENCES_LIST = (
  userData: ResDataType["userProfile"]
) => [
  {
    label: "Date of birth",
    name: "dateOfBirth",
    value: moment(userData?.dateOfBirth).toDate(),
    type: "date",
  },
  {
    label: "Gender",
    name: "gender",
    value: userData?.gender.value,
    type: "dropdown",
    options: "GENDER_TYPE",
  },
  {
    label: "Islamic status",
    name: "islamicStatus",
    value: userData?.islamicStatus.value,
    type: "dropdown",
    options: "ISLAMIC_STATUS_TYPE",
  },
  {
    label: "Specialized",
    name: "specialized",
    value: userData?.specialized,
    type: "text",
  },
];

export const personalPreferencesDefaultValues = (
  userData: ResDataType["userProfile"]
) => ({
  dateOfBirth: moment(userData?.dateOfBirth).toDate(),
  gender: userData?.gender.value,
  islamicStatus: userData?.islamicStatus.value,
  specialized: userData?.specialized,
});

export const personalPreferencesSchema = Yup.object().shape({
  dateOfBirth: Yup.date().required("This field is required"),
  gender: Yup.string().required("This field is required"),
  islamicStatus: Yup.string().required("This field is required"),
  specialized: Yup.string().required("This field is required"),
});