// @mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
// hooks
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useAuthContext } from 'src/auth/hooks';
import { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify/iconify';
import { useRouter } from 'src/routes/hook';

// ----------------------------------------------------------------------

export default function LogoutPopup({ dialog }: { dialog: any }) {
  const { logout } = useAuthContext();

  const popover = usePopover();

  const router = useRouter();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog open={dialog.value} maxWidth="sm" onClose={dialog.onFalse}>
      <DialogTitle textAlign="end" p="30px">
        <IconButton onClick={dialog.onFalse}>
          <Iconify
            icon="carbon:close-outline"
            color="background.contrast"
            width={30}
            height={30}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center" spacing={4} px={3} py={6} textAlign="center">
          {/* <Image width="25%" src={popupImage.src} /> */}
          <Iconify icon="grommet-icons:logout" width="72px" />
          <Typography fontWeight={600} fontSize="32px" lineHeight="120%">
            Are you sure you want to logout from Days of Iman?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box display="flex" gap={2} width="100%" justifyContent="center">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogout}
          >
            Yes
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => dialog.onFalse()}
          >
            No
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
