import { IconProps, SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";

interface IAppButton {
  label: string | undefined;
  onClick?: () => void;
  variant?: "contained" | "outlined" | "text";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  sx?: SxProps;
  startIcon?: React.ReactElement<IconProps>;
  fullWidth?: boolean;
  size?: "small" | "large" | "medium";
}

const AppButton: React.FC<IAppButton> = (props) => { 
  const {
    label,
    onClick,
    variant = "contained",
    color = "primary",
    sx,
    startIcon,
    size = "small",
    fullWidth = false,
  } = props;

  return (
    <Button
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      size={size}
      startIcon={startIcon}
      sx={{
        fontWeight: "700px",
        fontSize: { md: "16px", xs: "12px" },
        color: "black",
        padding: { md: "16px 28px", xs: "10px" },
        border: "2px solid black",
        borderRadius: "70px",
        lineHeight: "24px",
        textTransform: "capitalize",
        height: "100%",
        ...sx,
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default AppButton;
