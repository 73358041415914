// @mui
import { alpha } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import Card, { CardProps } from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
// utils
import { fShortenNumber } from "src/utils/format-number";

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  data: {
    status: string;
    value: number
  }[];
}

export default function ProgramStatusStatistics ({
  title,
  subheader,
  data,
  ...other
}: Props) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Stack spacing={3} sx={{ p: 3 }}>
        {data?.map(progress => (
          <Stack key={progress.status}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ mb: 1 }}
            >
              <Box sx={{ typography: "overline" }}>{progress.status}</Box>
              <Box sx={{ typography: "subtitle1" }}>
                {fShortenNumber(progress.value)}
              </Box>
            </Stack>

            <LinearProgress
              variant='determinate'
              value={progress.value}
              color={
                ((progress.status.toLowerCase() === "inprogress" ||
                  progress.status.toLowerCase() === "hold" ||
                  progress.status.toLowerCase() === "restart") &&
                  "warning") ||
                (progress.status.toLowerCase() === "unsubscribe" && "error") ||
                "success"
              }
              sx={{
                height: 8,
                bgcolor: theme => alpha(theme.palette.grey[500], 0.16),
              }}
            />
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}
