import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  Card,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import Scrollbar from "src/components/scrollbar";
import { TableHeadCustom, TableSkeleton, useTable } from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import { paths } from "src/routes/paths";
import { NewRoleSchema } from "src/schemas";
import PermissionsTableRow from "src/sections/roles-and-permissions/permissions/PermissionsTableRow";
import { RoleType } from "src/types/roles";

type AddNewRole = {
  name: string;
  displayName: string;
  permissions: string[];
};

const TABLE_HEAD = [
  { id: "1", label: "System criteria" },
  { id: "2", label: "View" },
  { id: "3", label: "Create" },
  { id: "4", label: "Edit" },
  { id: "5", label: "Delete" },
];

const AddNewRole = () => {
  // #region States
  const { roleId } = useParams();
  const confirm = useBoolean();
  const table = useTable({ defaultRowsPerPage: 10 });
  const [dataToSend, setDataToSend] = useState<AddNewRole>();
  const { usePostApi, useGetListApi, useGetItemApi, usePatchApi } =
    useApiServices();
  // #endregion States

  // #region Services
  const { mutate: addRole, isLoading } = usePostApi<AddNewRole>({
    url: API_URLS.ROLES,
    onSuccess: () => {
      confirm.onFalse();
    },
  });

  const { mutate: editRole, isLoading: isEditing } = usePatchApi<AddNewRole>({
    url: API_URLS.ROLES,
    id: roleId!,
    onSuccess: () => {
      confirm.onFalse();
    },
  });

  const {
    data,
    isLoading: arePrmsLoading,
    error,
  } = useGetListApi<ResDataType["permissions"]>({
    url: API_URLS.PERMISSIONS,
  });

  const {
    data: roleData,
    isLoading: isRoleLoading,
    error: roleError,
    isRefetching
  } = useGetItemApi<RoleType>({
    url: API_URLS.ROLES,
    id: roleId,
    onSuccess: (data: RoleType) => {
      if (roleId) {
        methods.setValue("permissions", data.permissions);
        methods.setValue("name", data.name);
        methods.setValue("displayName", data.displayName);
      } else {
        methods.setValue("permissions", []);
      }
    },
  });

  const methods = useForm<AddNewRole>({
    defaultValues: {
      name: "",
      displayName: "",
      permissions: [],
    },
    resolver: yupResolver(NewRoleSchema),
  });

  const mutate = roleId ? editRole : addRole;
  // #endregion Services

  // #region form
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddNewRole) => {
      // @ts-ignore
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );
  // #endregion form

  useEffect(() => {
    if (roleData?.permissions) {
      methods.setValue("permissions", roleData?.permissions!);
    }
  }, [roleData]);

  return (
    <AppLoadingAndErrorWrapper
      isLoading={isRoleLoading || arePrmsLoading || isRefetching}
      errorMessage={error || roleError}
    >
      <CustomBreadcrumbs
        links={[
          {
            name: "Home",
            href: paths.dashboard.root,
          },
          {
            name: "Roles",
            href: paths.dashboard.roles,
          },
          {
            name: roleId ? "Edit role" : "Create new role",
          },
        ]}
      />
      <Container>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Stack py={5} spacing={3}>
                <Block label='Role name'>
                  <RHFTextField name={`name`} />
                </Block>
                <Block label='Role display name'>
                  <RHFTextField name={`displayName`} />
                </Block>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Card
                sx={errors.permissions ? { border: "1px solid #E17678" } : {}}
              >
                <TableContainer>
                  <Scrollbar>
                    <Table>
                      <TableHeadCustom headLabel={TABLE_HEAD} />
                      <TableBody>
                        {data!?.map(row => (
                          <PermissionsTableRow key={row.section} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  </Scrollbar>
                </TableContainer>
              </Card>
              {errors.permissions && (
                <Typography variant='caption' color='error'>
                  Permissions should be one at least
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <AppLoadingButton
                label={roleId ? "Edit" : "Add"}
                isLoading={false}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Container>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
          }
        }}
        content={`Are you sure you want to ${
          roleId ? "edit" : "add"
        } this role?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading || isEditing}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </AppLoadingAndErrorWrapper>
  );
};

export default AddNewRole;
