import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { Upload } from "src/components/upload";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import { NewFeatureSchema } from "src/schemas";
import {
  AddFeatureDialogPropsType,
  AddFeatureFormType,
  EditFeatureFormType,
} from "src/types/feature-dialog";
import { GetProgramFeatureDataType } from "src/types/program-features";
import { ProgramDetailsDtoType } from "src/types/programs-dropdown";
import { UploadFileResponseType } from "src/types/upload-file-res";

const AddFeatureDialog = ({
  open,
  onClose,
  dialogTitle,
  id,
}: AddFeatureDialogPropsType) => {
  // #region States
  const isEditing = !(id === null || id === undefined);
  const { programId } = useParams();
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<AddFeatureFormType>();
  const { usePostApi, useGetListApi, usePatchApi, useGetItemApi } =
    useApiServices();
  const [featureImage, setFeatureImage] = useState<File | string | null>(null);
  const [currentTabValue, setCurrentTabValue] = useState("en");
  const [languagesCount, setLanguagesCount] = useState<number>(0);
  // #endregion States

  // #region Form
  const methods = useForm<AddFeatureFormType>({
    defaultValues: {
      featureImage: "",
      programId: +programId!,
      translations: [
        {
          description: "",
          locale: "",
        },
      ],
    },
    resolver: yupResolver(NewFeatureSchema(languagesCount)),
  });
  const {
    handleSubmit,
    formState: {
      errors: { featureImage: imageError },
    },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddFeatureFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const indexOfCurrentLocale = (methods.watch()?.translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);
  // #endregion Form

  // #region services
  const {
    mutate: addFeature,
    isLoading,
    error,
  } = usePostApi<AddFeatureFormType>({
    url: API_URLS.PROGRAM_FEATURES,
    onSuccess: () => {
      onClose();
      confirm.onFalse();
      methods.reset();
      setFeatureImage("");
    },
  });

  const { data: supportedLanguages } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.LANGUAGE_TYPE,
    onSuccess: (data: ResDataType["dropDowns"]) => {
      methods.setValue(
        "translations",
        data.map(e => ({
          description: "",
          locale: e.value,
        }))
      );
      setCurrentTabValue(data.map(e => e.value)[0]);
      setLanguagesCount(+data.length!);
    },
  });

  const { refetch, isRefetching } = useGetItemApi({
    url: API_URLS.PROGRAM_FEATURES,
    id: id!,
    onSuccess: (data: GetProgramFeatureDataType) => {
      if (id) {
        methods.setValue(`translations`, data.translations);
        methods.setValue("featureImage", data.featureImage);
        setFeatureImage(data.featureImage);
      }
    },
  });

  const { mutate: uploadPhoto, isLoading: isUploadingPhoto } = usePostApi({
    url: API_URLS.UPLOAD_FILE,
    withFormData: true,
    onSuccess: (data: UploadFileResponseType) => {
      methods.setValue("featureImage", data.filePath);
    },
  });

  const {
    mutate: editFeature,
    isLoading: isEditLoading,
    error: isEditError,
  } = usePatchApi<EditFeatureFormType>({
    url: API_URLS.PROGRAM_FEATURES,
    id: id!,
    onSuccess: () => {
      confirm.onFalse();
      onClose();
    },
  });

  const mutate = isEditing ? editFeature : addFeature;
  // #endregion services

  // #region handlers
  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFeatureImage(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);

  const handleChangeTab = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion handlers

  // #region useEffect
  // Set the locale value when the user selects a new tab
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue,
      description:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.description! ||
        "",
    });
  }, [methods, indexOfCurrentLocale, currentTabValue]);

  // Upload photo to the server when it's selected
  useEffect(() => {
    if (featureImage && open && typeof featureImage !== "string") {
      uploadPhoto(featureImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureImage]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // #endregion useEffect

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <AppLoadingAndErrorWrapper
              isLoading={isLoading || isEditLoading || isRefetching}
              errorMessage={error || isEditError}
              sx={{ height: "auto" }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      textAlign='center'
                      fontSize={24}
                      fontWeight={700}
                    >
                      {dialogTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Tabs
                      value={currentTabValue}
                      onChange={handleChangeTab}
                      sx={{
                        px: 3,
                        boxShadow: theme =>
                          `inset 0 -2px 0 0 ${alpha(
                            theme.palette.grey[500],
                            0.08
                          )}`,
                      }}
                    >
                      {supportedLanguages!?.map(tab => (
                        <Tab
                          key={tab.value}
                          value={tab.value}
                          label={tab.label}
                        />
                      ))}
                    </Tabs>
                    <Block sx={{ py: 2 }} label='Description'>
                      <RHFTextField
                        name={`translations.${indexOfCurrentLocale}.description`}
                      />
                    </Block>
                    <Card>
                      <CardHeader title='Upload feature image' />
                      <CardContent>
                        <Upload
                          file={featureImage}
                          onDrop={handleDropSingleFile}
                          onDelete={() => {
                            setFeatureImage(null);
                            methods.setValue("featureImage", "");
                          }}
                          error={!!imageError}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  {!!Object.keys(methods.formState.errors).length && (
                    <AddDialogsHelperText />
                  )}
                  <Grid item xs={6}>
                    <AppButton
                      label='Cancel'
                      color='secondary'
                      onClick={onClose}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton
                      label={isEditing ? "Edit" : "Add"}
                      isLoading={isLoading || isEditLoading || isUploadingPhoto}
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={`Are you sure you want to ${isEditing ? "edit" : "add"} this ${
          !isEditing ? "new" : ""
        } feature?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading || isEditLoading}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default AddFeatureDialog;
