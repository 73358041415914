import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import { useBoolean } from "src/hooks/use-boolean";

// ----------------------------------------------------------------------

type Props = {
  values: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
  reset: any;
};

export default function FaqsTableToolbar ({ values, setSearch, reset }: Props) {
  const openFilters = useBoolean();
  const renderHead = (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant='h6' sx={{ flexGrow: 1 }}>
        Filters
      </Typography>

      <IconButton onClick={openFilters.onFalse}>
        <Iconify icon='mingcute:close-line' />
      </IconButton>
    </Stack>
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: "flex-end", md: "center" }}
        direction={{
          xs: "column",
          md: "row",
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          spacing={2}
          flexGrow={1}
          sx={{ width: 1 }}
        >
          <Box display='flex' gap={2} alignItems='center'>
            <RHFTextField name='translations.some.question' />
            <IconButton
              onClick={() =>
                setSearch({
                  translations: {
                    some: {
                      question: values.translations.some.question,
                    },
                  },
                })
              }
            >
              <Iconify icon='eva:search-fill' sx={{ color: "text.disabled" }} />
            </IconButton>
          </Box>

          <Tooltip title='Filters' placement='bottom' arrow>
            <IconButton onClick={openFilters.onTrue}>
              <Iconify icon='ri:filter-line' width='50' height='50' />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      {/* Filters */}
      <Drawer
        anchor='right'
        open={openFilters.value}
        onClose={openFilters.onFalse}
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 280 },
        }}
      >
        {renderHead}
        <Divider />
        <Scrollbar sx={{ px: 2.5, py: 3 }}>
          <Stack spacing={3}>
            <Block label='Answer'>
              <RHFTextField name='translations.some.answer' />
            </Block>
            <Block label='Type'>
              <RHFTextField name='type' />
            </Block>
            <Stack justifyContent='center' spacing={3}>
              <AppButton
                label='Apply filters'
                onClick={() =>
                  setSearch({
                    ...values,
                  })
                }
              />
              <Typography
                align='center'
                fontSize={18}
                fontWeight={500}
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  reset();
                }}
              >
                Reset all filters
              </Typography>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
