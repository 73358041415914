// @mui
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
//
import LinkItem from "./link-item";
import { CustomBreadcrumbsProps } from "./types";

// ----------------------------------------------------------------------

export default function CustomBreadcrumbs ({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx = { mb: { xs: 3, md: 4 } },
  ...other
}: CustomBreadcrumbsProps) {
  const filteredLinks = links.filter(link =>
    link.condition !== undefined ? link.condition : true
  );
  const lastLink = filteredLinks[filteredLinks.length - 1].name;

  return (
    <Box sx={{ ...sx }}>
      <Stack direction='row' alignItems='center'>
        <Box sx={{ flexGrow: 1 }}>
          {/* HEADING */}
          {heading && (
            <Typography variant='h4' gutterBottom>
              {heading}
            </Typography>
          )}

          {/* BREADCRUMBS */}
          {!!filteredLinks.length && (
            <Breadcrumbs separator='›' {...other}>
              {filteredLinks.map(link => (
                <LinkItem
                  key={link.name || ""}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                />
              ))}
            </Breadcrumbs>
          )}
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map(href => (
            <Link
              key={href}
              href={href}
              variant='body2'
              target='_blank'
              rel='noopener'
              sx={{ display: "table" }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}