import React from "react";
// components
import { Controller, useFormContext } from "react-hook-form";
//
import { Stack, Typography } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { useSettingsContext } from "./settings";
import { Block } from "./Block";

const PhoneNumberInputField = ({ name }: { name: string }) => {
  const { control } = useFormContext();
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === "light";

  const style = isLight
    ? {
        WebkitBoxShadow: "0 0 0 1000px #F9F9F9 inset",
        WebkitTextFillColor: "black",
        "&::after": { display: "none" },
        "&::before": { display: "none" },
      }
    : {
        WebkitBoxShadow: "0 0 0 1000px #242424 inset",
        "&::after": { display: "none" },
        "&::before": { display: "none" },
        WebkitTextFillColor: "white",
      };

  return (
    <Block label="Mobile phone">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Stack textAlign="left">
            <MuiPhoneNumber
              id={name}
              className="phone-input"
              size="medium"
              {...field}
              defaultCountry="sa"
              style={{
                border: "1px solid",
                padding: "10px",
                borderRadius: "16px",
                // eslint-disable-next-line no-nested-ternary
                borderColor: error
                  ? "red"
                  : themeMode === "dark"
                  ? "#35383A"
                  : "#EBEEF0",
              }}
              sx={{
                "&:MuiInputBase-root-MuiInput-root::before": {
                  display: "none",
                },
              }}
              inputProps={{ style }}
              error={!!error}
            />
            <Typography color="red" variant="caption" ml="12px" mt={1}>
              {error?.message}
            </Typography>
          </Stack>
        )}
      />
    </Block>
  );
};

export default PhoneNumberInputField;
