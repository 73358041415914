import { Controller, useFormContext } from "react-hook-form";
// @mui
import { TextFieldProps } from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  label?: string;
  isRequired?: boolean;
  minutesStep?: number;
};

export default function RHFTimePicker ({
  name,
  label,
  isRequired = true,
  helperText,
  type,
  minutesStep,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          {...field}
          value={field.value || null}
          minutesStep={minutesStep && minutesStep}
          onChange={newValue => {
            const timeFormat = moment(newValue).format("HH:mm:ssZZ");
            field.onChange(timeFormat);
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "16px",
              },
            },
          }}
          slotProps={{
            textField: {
              error: !!error,
            },
          }}
        />
      )}
    />
  );
}
