import { Card, Grid, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import DetailsCard from "src/components/details-card/DetailsCard";
import Image from "src/components/image/image";
import { useSettingsContext } from "src/components/settings";
import { paths } from "src/routes/paths";
import { GiftDetailsDataType } from "src/types/gift-details";

export default function GiftDetails () {
  const settings = useSettingsContext();
  const { giftId } = useParams();
  const { useGetItemApi } = useApiServices();

  const { data, isLoading, error } = useGetItemApi<GiftDetailsDataType>({
    url: API_URLS.GIFTS,
    id: giftId,
  });

  console.log(data)

  const senderInfo = [
    {
      label: "Name",
      value: data?.sender ? data!.sender.firstName + data!.sender.lastName : "",
    },
    {
      label: "Email",
      value: data?.sender ? data!.sender.email : "",
    },
    {
      label: "Phone number",
      value: data?.sender ? data!.sender.mobilePhone : "",
    },
    {
      label: "Sending type",
      value: data?.sendingType ? data!.sendingType.value : "",
    },
  ];

  const recipientInfo = [
    {
      label: "Name",
      value: data?.recipient
        ? data!.recipient.firstName + data!.recipient.lastName
        : "",
    },
    {
      label: "Email",
      value: data?.recipient ? data!.recipient.email : "",
    },
    {
      label: "Program",
      value: data?.program ? data!.program.name : "",
    },
    {
      label: "Medium",
      value: data?.recipient.messageMedium
        ? data?.recipient.messageMedium.map(e => e.label).join(",")
        : "",
    },
    {
      label: "Send date",
      value: data?.sendingDate ? data?.sendingDate : "",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Gift details</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Gifts",
              href: paths.dashboard.gifts.received,
            },
            {
              name: "Details",
            },
          ]}
        />
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <Grid container spacing={4} my={1}>
            <Grid item xs={12} md={6}>
              <DetailsCard
                items={recipientInfo}
                cardTitle='Recipient information'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailsCard items={senderInfo} cardTitle='Sender information' />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Typography variant='h6'>Gift message</Typography>
                <Card sx={{ py: 5, px: 3 }}>
                  <Typography variant='subtitle2' fontWeight={400}>
                    {data?.message}
                  </Typography>
                </Card>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Typography variant='h6'>Gift card</Typography>
                <Image
                  src={
                    data?.cardImage ||
                    "https://climate.onep.go.th/wp-content/uploads/2020/01/default-image.jpg"
                  }
                  sx={{ borderRadius: 2 }}
                />
              </Stack>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
