import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha, Container, Grid, Stack, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import Editor from "src/components/editor/editor";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import { AddFaqFormType, GetFaqDataType } from "src/types/faqs";
import {
  NewFaqFormSchema,
  newFaqFormSchema,
} from "../../../../sections/pages/faqs/Add-new-faq-schema";

const AddFaq = () => {
  // #region States
  const confirm = useBoolean();
  const { faqId } = useParams();
  const navigate = useNavigate();
  const [dataToSend, setDataToSend] = useState<AddFaqFormType>();
  const { usePostApi, useGetListApi, usePatchApi, useGetItemApi } =
    useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");
  // #endregion States

  // #region Services
  const { data: supportedLanguages, isLoading: isLanguagesLoading } =
    useGetListApi<ResDataType["dropDowns"]>({
      url: API_URLS.LANGUAGE_TYPE,
      onSuccess: (data: ResDataType["dropDowns"]) => {
        methods.setValue(
          "translations",
          data.map(e => ({
            question: "",
            answer: "",
            locale: e.value,
          }))
        );
        setCurrentTabValue(data.map(e => e.value)[0]);
      },
    });

  const {
    data: faqData,
    isLoading: isFaqLoading,
    error,
  } = useGetItemApi<GetFaqDataType>({
    url: API_URLS.FAQS,
    id: faqId!,
    onSuccess: (data: GetFaqDataType) => {
      if (faqId) {
        methods.setValue(`translations`, data.translations);
      }
    },
  });

  const { mutate: add, isLoading } = usePostApi<AddFaqFormType>({
    url: API_URLS.FAQS,
    onSuccess: _ => {
      confirm.onFalse();
      navigate("/dashboard/pages");
    },
  });

  const { mutate: patch, isLoading: isPatching } = usePatchApi<AddFaqFormType>({
    url: API_URLS.FAQS,
    onSuccess: (data: any) => {
      confirm.onFalse();
    },
    id: faqId!,
  });

  const mutate = faqId ? patch : add;
  // #endregion Services

  // #region form
  const methods = useForm<AddFaqFormType>({
    defaultValues: newFaqFormSchema,
    resolver: yupResolver(NewFaqFormSchema(supportedLanguages?.length!)),
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddFaqFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const indexOfCurrentLocale = (methods.watch().translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);
  // #endregion form

  // #region handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion handlers
  // #region useEffect
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue,
      question:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.question! ||
        "",
      answer:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.answer! || "",
    });
  }, [faqData, methods, indexOfCurrentLocale, currentTabValue]);
  // #endregion useEffect
  return (
    <Container>
      <AppLoadingAndErrorWrapper
        isLoading={
          isLoading || isLanguagesLoading || isFaqLoading || isPatching
        }
        errorMessage={error}
        sx={{ height: "auto" }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Tabs
                value={currentTabValue}
                onChange={handleChangeTab}
                sx={{
                  px: 3,
                  boxShadow: theme =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {supportedLanguages!?.map(tab => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>
              <Stack py={5} spacing={3}>
                <Block label='Question'>
                  <RHFTextField
                    name={`translations.${indexOfCurrentLocale}.question`}
                  />
                </Block>
                <Block label='Answer'>
                  <Controller
                    name={`translations.${indexOfCurrentLocale}.answer`}
                    render={({ field, fieldState }) => (
                      <Editor
                        value={field.value}
                        error={
                          !!errors.translations!?.[indexOfCurrentLocale]?.answer
                        }
                        onChange={field.onChange}
                        id='full-editor'
                      />
                    )}
                  />
                </Block>
              </Stack>
            </Grid>
            {Object.keys(errors).length ? <AddDialogsHelperText /> : <></>}
            <Grid item xs={12} md={3}>
              <AppLoadingButton
                label={faqId ? "Edit" : "Add"}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </FormProvider>
        {/* Confirm */}
        <ConfirmDialog
          open={confirm.value}
          onClose={(
            event: React.SyntheticEvent<Element, Event>,
            reason: string
          ) => {
            if (reason && reason === "backdropClick") {
              confirm.onFalse();
            }
          }}
          content={`Are you sure you want to ${
            faqId ? "edit" : "add"
          } this FAQ?`}
          icon={
            <BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />
          }
          action={
            <>
              <AppButton
                fullWidth
                label='No'
                color='secondary'
                onClick={() => {
                  confirm.onFalse();
                }}
              />
              <AppLoadingButton
                label='Yes'
                isLoading={isLoading}
                onClick={() => mutate(dataToSend!)}
              />
            </>
          }
        />
      </AppLoadingAndErrorWrapper>
    </Container>
  );
};

export default AddFaq;
