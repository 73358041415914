import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
  Stack,
  Box,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import useApiServices from "src/api/useApiServices";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import { useForm } from "react-hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { RHFTextField } from "src/components/hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangePasswordSchema, defaultValues } from "./change-password-schema";
import { Block } from "src/components/Block";
import { useBoolean } from "src/hooks/use-boolean";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { paths } from "src/routes/paths";
import AppLoadingButton from "src/components/common/AppLoadingButton";

const ChangePasswordView = () => {
  const settings = useSettingsContext();
  const { usePostApi } = useApiServices();
  const dialog = useBoolean();

  const password = useForm({
    resolver: yupResolver(ChangePasswordSchema),
    defaultValues: defaultValues,
  });

  const { mutate, isLoading: isChanging } = usePostApi({
    url: API_URLS.CHANGE_PASSWORD,
    onSuccess: _ => {
      dialog.onTrue();
      password.reset();
    },
  });

  const { handleSubmit } = password;

  const onSubmit = handleSubmit(async (data: any) => {
    mutate(data);
  });

  const INPUT_FIELDS = [
    {
      label: "Current password",
      name: "currentPassword",
      showPassword: useBoolean(),
    },
    {
      label: "New password",
      name: "password",
      showPassword: useBoolean(),
    },
    {
      label: "Confirm new password",
      name: "confirmPassword",
      showPassword: useBoolean(),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <Container sx={{ py: 4 }} maxWidth={settings.themeStretch ? false : "xl"}>
        <FormProvider methods={password} onSubmit={onSubmit}>
          <Stack spacing={2.5} width={{ xs: "100%", md: "50%" }}>
            {INPUT_FIELDS.map(field => (
              <Block key={field.name} label={field.label}>
                <RHFTextField
                  type={field.showPassword.value ? "text" : "password"}
                  size='medium'
                  name={field.name}
                  InputProps={{
                    sx: { borderRadius: "16px" },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={field.showPassword.onToggle}
                          edge='end'
                        >
                          {field.showPassword.value ? (
                            <VisibilityOutlinedIcon fontSize='small' />
                          ) : (
                            <VisibilityOffOutlinedIcon fontSize='small' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Block>
            ))}
            <Box
              display='flex'
              gap={2}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <AppLoadingButton isLoading={isChanging} label='Change & Save' />
            </Box>
          </Stack>
        </FormProvider>
      </Container>
    </>
  );
};

export default ChangePasswordView;
