// @mui
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { API_URLS } from "src/api/apiUrls";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import Iconify from "src/components/iconify/iconify";
// components
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import { ProgramBeneficiaryType } from "src/types/program-beneficiaries";
import AddBeneficiaryDialog from "./AddBeneficiaryDialog";

// ----------------------------------------------------------------------

type Props = {
  row: ProgramBeneficiaryType;
  mutate: any;
  isDeleteLoading: boolean;
};

export default function ProgramBeneficiariesTableRow ({
  row,
  mutate,
  isDeleteLoading,
}: Props) {
  const dialog = useBoolean();
  const confirm = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row?.description}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{moment(row?.createdAt).format("YYYY-MM-DD")}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Restricted to={PrmsEnum.UPDATE_PROGRAM}>
            <Tooltip title='Delete' placement='bottom' arrow>
              <IconButton onClick={confirm.onTrue}>
                <Iconify
                  icon='material-symbols:delete-outline'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
          <Restricted to={PrmsEnum.UPDATE_PROGRAM}>
            <Tooltip title='Edit' placement='bottom' arrow>
              <IconButton onClick={() => dialog.onTrue()}>
                <Iconify
                  icon='carbon:edit'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
        </TableCell>
        <AddBeneficiaryDialog
          open={dialog.value}
          onClose={dialog.onFalse}
          dialogTitle='Edit beneficiary'
          id={row.id}
        />
      </TableRow>
      {/* Confirm */}
      <DeleteDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        url={API_URLS.PROGRAM_BENEFICIARIES}
        name="beneficiary"
        id={row.id}
      />
    </>
  );
}
