// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Image from "src/components/image/image";
// components
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { API_URLS } from "src/api/apiUrls";
import Iconify from "src/components/iconify/iconify";
import { ASSETS_API } from "src/config-global";
import { useBoolean } from "src/hooks/use-boolean";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import { UserBadgeType } from "src/types/user-badges";

// ----------------------------------------------------------------------

type Props = {
  row: UserBadgeType;
};

export default function UserBadgesTableRow ({ row }: Props) {
  const dialog = useBoolean();
  const confirm = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Image
            src={`${ASSETS_API}${row.badge.featureImage}`}
            sx={{ width: 71 }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row.badge.program.name}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row.badge.program.name}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{moment(row.createdAt).format("YYYY-MM-DD")}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Tooltip title='Delete' placement='bottom' arrow>
            <IconButton onClick={() => confirm.onTrue()}>
              <Iconify
                icon='material-symbols:delete-outline'
                width='27'
                height='27'
                color='#FFAB8B'
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      {/* Confirm delete */}
      <DeleteDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        name='badge'
        url={API_URLS.USER_BADGES(row.userId)}
        id={row.badge.id}
      />
    </>
  );
}
