import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// components
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { API_URLS } from "src/api/apiUrls";
import { ConfirmDialog } from "src/components/custom-dialog";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { FAQType } from "src/types/faqs";
import { PrmsEnum } from "src/types/permissions";
import moment from "moment";

// ----------------------------------------------------------------------

type Props = {
  row: FAQType;
};

export default function FaqsTableRow ({ row }: Props) {
  const confirm = useBoolean();
  const success = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row?.type}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.question}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row.answer}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{moment(row.updatedAt).format('YYYY-MM-DD')}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Restricted to={PrmsEnum.DELETE_FAQ}>
            <Tooltip title='Delete' placement='bottom' arrow>
              <IconButton onClick={() => confirm.onTrue()}>
                <Iconify
                  icon='material-symbols:delete-outline'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
          <Restricted to={PrmsEnum.UPDATE_FAQ}>
            <Tooltip title='Edit' placement='bottom' arrow>
              <Link to={`faqs/${row.id}`}>
                <IconButton>
                  <Iconify
                    icon='carbon:edit'
                    width='27'
                    height='27'
                    color='#FFAB8B'
                  />
                </IconButton>
              </Link>
            </Tooltip>
          </Restricted>
        </TableCell>
      </TableRow>
      {/* Confirm */}
      <DeleteDialog
        url={API_URLS.FAQS}
        name='FAQ'
        open={confirm.value}
        onClose={confirm.onFalse}
        id={row.id}
      />
      {/* Success */}
      <ConfirmDialog
        open={!!success.value && !confirm.value}
        onClose={() => {}}
        close={() => {
          confirm.onFalse();
          success.onFalse();
        }}
        content='You have deleted this FAQ successfully'
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </>
  );
}
