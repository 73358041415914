import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import Container from "@mui/material/Container";
import moment from "moment";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Header from "src/components/Header";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import { useSettingsContext } from "src/components/settings";
import StatisticCard from "src/components/StatisticCard";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { paths } from "src/routes/paths";
import AddVacancy from "src/sections/careers/vacancies/AddVacancy";
import VacanciesTableRow from "src/sections/careers/vacancies/VacanciesTableRow";
import VacanciesTableToolbar from "src/sections/careers/vacancies/VacanciesTableToolbar";
import { PrmsEnum } from "src/types/permissions";

const TABLE_HEAD = [
  { id: "1", label: "Vacancy Title" },
  { id: "2", label: "Main Duties and Responsibilities" },
  { id: "3", label: "Last updated" },
  { id: "4", label: "Actions" },
];

export default function VacanciesPage () {
  const dialog = useBoolean();
  const settings = useSettingsContext();
  const table = useTable({ defaultRowsPerPage: 10 });
  const { useGetListApi, useDeleteApi } = useApiServices();
  const methods = useForm({
    defaultValues: {
      translations: {
        some: {
          title: "",
        },
      },
    },
  });
  const [search, setSearch] = useState({
    title: "",
  });

  const { data, isLoading, error, refetch, isRefetching } = useGetListApi<
    ResDataType["careers"]
  >({
    url: API_URLS.VACANCIES,
    parameters: {
      page: table.page,
      pageSize: table.rowsPerPage,
      "translations.some.title": search.title,
    },
  });

  const { isLoading: isDeleteLoading } = useDeleteApi({
    url: API_URLS.VACANCIES,
    urlAfterSuccess: window.location.href,
    onSuccess: () => refetch(),
  });

  return (
    <>
      <Helmet>
        <title>Vacancies management</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Careers",
              href: paths.dashboard.careers,
            },
            {
              name: "Vacancies management",
            },
          ]}
        />
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isDeleteLoading || isRefetching}
          errorMessage={error}
        >
          <Header header={`Today, ${moment().format("YYYY-MM-DD")}`} />
          <Restricted to={PrmsEnum.CREATE_CAREER}>
            <Box display='flex' width='100%' justifyContent='end' py={4}>
              <Link to='new'>
                <Button
                  variant='contained'
                  sx={{ color: "text.primary" }}
                  onClick={dialog.onTrue}
                >
                  Add new
                </Button>
              </Link>
            </Box>
          </Restricted>
          <Grid container spacing={4} my={1}>
            <Grid item xs={12}>
              <FormProvider methods={methods}>
                <Card>
                  <VacanciesTableToolbar
                    values={methods.watch()}
                    setSearch={setSearch}
                    reset={methods.reset}
                    data={data!}
                  />
                  <TableContainer>
                    <Scrollbar>
                      <Table>
                        <TableHeadCustom headLabel={TABLE_HEAD} />
                        <TableBody>
                          {isLoading ? (
                            [...Array(table.rowsPerPage)].map((i, index) => (
                              <TableSkeleton key={index} sx={{ height: 80 }} />
                            ))
                          ) : (
                            <>
                              {data!?.items?.map(row => (
                                <VacanciesTableRow
                                  key={row.id}
                                  row={row}
                                />
                              ))}
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </Scrollbar>
                  </TableContainer>
                  {data && (
                    <TablePaginationCustom
                      count={data?.totalItems}
                      page={data.currentPage ? +data.currentPage - 1 : 1}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table?.onChangePage}
                      onRowsPerPageChange={table?.onChangeRowsPerPage}
                    />
                  )}
                </Card>
              </FormProvider>
            </Grid>
          </Grid>
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
}
