import { useEffect, useMemo, useState } from "react";
// routes
import { paths } from "src/routes/paths";
// components
import SvgColor from "src/components/svg-color";
import Iconify from "src/components/iconify/iconify";
import useApiServices from "src/api/useApiServices";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import { PrmsEnum } from "src/types/permissions";
import usePermission from "src/PermissionProvider/usePermission";
import { axiosApi } from "src/api/axios";
import { ProgramsDataType } from "src/types/programs";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  dashboard: icon("ic_dashboard"),
  programs: icon("ic_program"),
  gifts: icon("ic_gifts"),
  badges: icon("ic_badges"),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const [, allowed] = usePermission(PrmsEnum.CREATE_PROGRAM);
  const [, viewPrgs] = usePermission(PrmsEnum.SHOW_PROGRAM, true);
  const { useGetListApi } = useApiServices();
  const { data: programs, refetch } = useGetListApi<ProgramsDataType>({
    url: API_URLS.PROGRAMS,
    enabled: viewPrgs,
  });

  useEffect(() => {
    viewPrgs && refetch();
  }, [viewPrgs]);

  const data = useMemo(
    () => [
      // ----------------------------------------------------------------------
      {
        subheader: "",
        items: [
          { title: "Home", path: paths.dashboard.root, icon: ICONS.dashboard },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "App user management",
            path: paths.dashboard.users,
            icon: <Iconify icon="lucide:user" width="24px" height="24px" />,
            permission: PrmsEnum.SHOW_USER,
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Programs",
            path: paths.dashboard.programs.current,
            icon: ICONS.programs,
            permission: [PrmsEnum.SHOW_PROGRAM, PrmsEnum.CREATE_PROGRAM],
            children: [
              ...(programs?.items || []).map((program: any) => ({
                title: program.name,
                path: `${paths.dashboard.programs.current}/${program.id}/analytics`,
                permission: undefined,
              })),
              {
                path: "programs/new",
                title: "Add a program",
                icon: <Iconify icon="mdi:add" width="24px" height="24px" />,
                permission: allowed,
              },
            ].filter((e) =>
              e.permission === undefined ? true : e!?.permission
            ),
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Gifts",
            path: paths.dashboard.gifts.received,
            icon: ICONS.gifts,
            permission: PrmsEnum.SHOW_GIFT,
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Badges",
            path: paths.dashboard.badges,
            icon: ICONS.badges,
            permission: PrmsEnum.SHOW_BADGE,
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Dashboard roles and users",
            path: paths.dashboard.roles,
            icon: <Iconify icon="mi:users" width="24px" height="24px" />,
            permission: [PrmsEnum.SHOW_ROLE, PrmsEnum.SHOW_ADMIN],
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Pages",
            path: paths.dashboard.pages,
            permission: [
              PrmsEnum.SHOW_WEBSITE_PAGE,
              PrmsEnum.SHOW_FAQ,
              PrmsEnum.SHOW_WEBSITE_SETTING,
            ],
            icon: (
              <Iconify
                icon="fluent-mdl2:page-edit"
                width="24px"
                height="24px"
              />
            ),
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Blog posts",
            path: paths.dashboard.blogs,
            icon: <Iconify icon="carbon:blog" width="24px" height="24px" />,
            permission: [PrmsEnum.SHOW_BLOG, PrmsEnum.SHOW_BLOG_CATEGORY],
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Careers",
            permission: PrmsEnum.SHOW_CAREER,
            path: paths.dashboard.careers,
            icon: (
              <Iconify
                icon="clarity:building-line"
                width="24px"
                height="24px"
              />
            ),
            children: [
              {
                path: "careers/vacancies",
                title: "Vacancies Management",
              },
              {
                path: "careers/applicant-tracking",
                title: "Applicant Tracking",
              },
            ],
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Contact us",
            path: paths.dashboard.contactUs,
            icon: <Iconify icon="ic:outline-call" width="24px" height="24px" />,
            permission: PrmsEnum.SHOW_CONTACT_US,
          },
        ],
      },
      {
        subheader: "",
        items: [
          {
            title: "Settings",
            path: paths.dashboard.settings,
            permission: PrmsEnum.SHOW_WEBSITE_SETTING,
            icon: (
              <Iconify
                icon="fluent:settings-20-regular"
                width="24px"
                height="24px"
              />
            ),
          },
        ],
      },
    ],
    [programs, allowed, viewPrgs]
  );

  return data;
}
