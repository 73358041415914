// @mui
import { IconButton, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Iconify from "src/components/iconify/iconify";
// components
import Label, { LabelColor } from "src/components/label";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import { ProgramUser } from "src/types/program-users";

// ----------------------------------------------------------------------

type Props = {
  row: ProgramUser;
  onRowDetails?: VoidFunction;
};

export default function ProgramUsersTableRow ({ row, onRowDetails }: Props) {
  let color: LabelColor = "warning";
  const { programId } = useParams();

  if (row?.status?.value === "IN_PROGRESS") {
    color = "warning";
  } else if (row?.status?.value === "COMPLETED") {
    color = "success";
  } else {
    color = "error";
  }

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        {row.user?.firstName
          ? row.user!.firstName! + row.user!.lastName!
          : row.user?.email}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>
          {moment(row?.startDate).toDate().toDateString()}
        </Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>
          {row?.endDate ? moment(row?.endDate).toDate().toDateString() : ""}
        </Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        {row.messageMedium.map(e => e.label).join(", ")}
      </TableCell>
      {!programId && (
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          {row.program.name}
        </TableCell>
      )}
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        {row.programCategory.name}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        {row.messageNumber}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Label variant='soft' color={color}>
          {row.status.label}
        </Label>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Restricted to={PrmsEnum.SHOW_USER}>
          <Tooltip title='View' placement='bottom' arrow>
            <Link to={`/dashboard/users/${row.userId}`}>
              <IconButton>
                <Iconify
                  icon='mdi:eye-circle'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Link>
          </Tooltip>
        </Restricted>
      </TableCell>
    </TableRow>
  );
}
