import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  alpha,
  Box,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { ConfirmDialog } from "src/components/custom-dialog";
import { useBoolean } from "src/hooks/use-boolean";
import { paths } from "src/routes/paths";
import { useSettingsContext } from "src/components/settings";
import { getNthInstancePos } from "src/utils/get-nth-instance";
import { ProgramDetailsDtoType } from "src/types/programs-dropdown";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import { PrmsEnum } from "src/types/permissions";
import Restricted from "src/PermissionProvider/Restricted";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";

const LINKS = [
  {
    title: "Analytics",
    href: "analytics",
  },
  {
    title: "Description",
    href: "description",
    permission: PrmsEnum.UPDATE_PROGRAM,
  },
  {
    title: "Features & Beneficiaries",
    href: "featuresAndBeneficiaries",
  },
  {
    title: "Users",
    href: "users",
    permission: PrmsEnum.SHOW_PROGRAM,
  },
  {
    title: "Topics",
    href: "topics",
  },
];

const ProgramsPagination = () => {
  const settings = useSettingsContext();
  // #region States
  const { programId, programCategoryId } = useParams();
  const { useGetListApi, useDeleteApi, usePatchApi, useGetItemApi } =
    useApiServices();
  const indexOfCurrentTab = getNthInstancePos(window.location.href, "/", 6);
  const stopPosition = getNthInstancePos(window.location.href, "/", 7);
  const [currentTabValue, setCurrentTabValue] = useState("analytics");
  const [isPublished, setIsPublished] = useState<boolean>(false);

  const dialog = useBoolean();
  const confirmDelete = useBoolean();
  const confirmPublish = useBoolean();
  const navigate = useNavigate();
  // #endregion States

  // #region Handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion Handlers

  // #region Services
  const { data: programs, isLoading } = useGetListApi<ResDataType["programs"]>({
    url: API_URLS.PROGRAMS,
  });

  const { data: category, isLoading: areCtgsLoading } = useGetListApi<
    ResDataType["programCategoriesType"]
  >({
    url: API_URLS.PROGRAM_CATEGORIES,
  });

  const {
    data: prgData,
    isLoading: isPrgLoading,
    refetch,
    isRefetching,
  } = useGetItemApi<ProgramDetailsDtoType>({
    url: API_URLS.PROGRAMS,
    id: programId!,
    onSuccess: (data: ProgramDetailsDtoType) => setIsPublished(data.published),
  });

  const { mutate: publish, isLoading: isPublishing } = usePatchApi({
    url: API_URLS.PROGRAMS,
    id: programId!,
    onSuccess: confirmPublish.onFalse,
  });

  // Get the current program name and current program category after checking them from the BE
  const currentProgramName = programs!?.items.filter(
    program => +program.id === +programId!
  )[0]?.name;

  const currentProgramCategoryName = category!?.items.filter(
    prCategory => +prCategory.id === +programCategoryId!
  )[0]?.name;
  // #endregion Services

  // #region useEffect
  useEffect(() => {
    setCurrentTabValue(
      LINKS.map(link => link.href).includes(
        window.location.href.slice(indexOfCurrentTab + 1, stopPosition)
      )
        ? window.location.href.slice(indexOfCurrentTab + 1, stopPosition)
        : "analytics"
    );
  }, [window.location.href]);

  useEffect(() => {
    refetch();
  }, [programId]);
  // #endregion useEffect

  return (
    <Container maxWidth={settings.themeStretch ? false : "xl"}>
      <CustomBreadcrumbs
        links={[
          {
            name: "Home",
            href: paths.dashboard.root,
          },
          {
            name: "Programs",
          },
          {
            name: currentProgramName!,
            condition: !!programId,
          },
          {
            name: currentProgramCategoryName,
            condition: !!currentProgramCategoryName,
          },
        ]}
      />
      <AppLoadingAndErrorWrapper
        isLoading={isLoading || areCtgsLoading || isPrgLoading}
      >
        <Box display='flex' alignItems='flex-start'>
          <Stack spacing={3} width='100%'>
            {programId && (
              <Stack
                alignItems='flex-end'
                direction='row'
                justifyContent='flex-end'
                spacing={2}
              >
                <Restricted to={PrmsEnum.DELETE_PROGRAM}>
                  <AppLoadingButton
                    label='Delete Program'
                    onClick={confirmDelete.onTrue}
                    isLoading={false}
                    fullWidth={false}
                    color='error'
                  />
                </Restricted>
                <Restricted to={PrmsEnum.UPDATE_PROGRAM}>
                  <AppLoadingButton
                    label={
                      isPublished ? "Change to on hold" : "Publish program"
                    }
                    onClick={confirmPublish.onTrue}
                    isLoading={isPublishing}
                    fullWidth={false}
                    color='secondary'
                  />
                </Restricted>
              </Stack>
            )}
            {programId && (
              <Stack
                flexDirection='row'
                spacing={2}
                width='100%'
                alignItems='center'
              >
                <Tabs
                  value={currentTabValue}
                  onChange={handleChangeTab}
                  sx={{
                    boxShadow: theme =>
                      `inset 0 -2px 0 0 ${alpha(
                        theme.palette.grey[500],
                        0.08
                      )}`,
                    width: "100%",
                  }}
                >
                  {LINKS.map(tab => (
                    <Restricted
                      key={tab.href}
                      to={tab.permission!}
                      style={
                        tab.href === currentTabValue
                          ? {
                              margin: "0px 20px",
                              borderBottom: "2px solid black",
                            }
                          : {
                              margin: "0px 20px",
                            }
                      }
                    >
                      <Tab
                        value={tab.href}
                        label={tab.title}
                        onClick={() =>
                          navigate(
                            `/dashboard/programs/${programId}/${tab.href}`
                          )
                        }
                      />
                    </Restricted>
                  ))}
                </Tabs>
              </Stack>
            )}
            {!programId && (
              <Typography variant='h4'>
                Choose a program from the sidebar
              </Typography>
            )}
          </Stack>
        </Box>
      </AppLoadingAndErrorWrapper>
      <Outlet />
      {/* Confirm delete */}
      <DeleteDialog
        open={confirmDelete.value}
        onClose={confirmDelete.onFalse}
        url={API_URLS.PROGRAMS}
        name='program'
        id={programId!}
        onSuccess={() => {
          confirmDelete.onFalse();
          navigate(`/dashboard/programs`);
        }}
      />
      {/* Confirm publish */}
      <ConfirmDialog
        open={confirmPublish.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirmPublish.onFalse();
          }
        }}
        content={`Are you sure you want to ${
          isPublished ? "change this program to draft" : "publish this program"
        }?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirmPublish.onFalse();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isPublishing}
              onClick={() => publish({ published: !prgData?.published })}
            />
          </>
        }
      />
    </Container>
  );
};
export default ProgramsPagination;
