import { Container, Stack, Tab, Tabs } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import usePermission from "src/PermissionProvider/usePermission";
import { paths } from "src/routes/paths";
import { PrmsEnum } from "src/types/permissions";
import FAQs from "./faqs/FAQs";
import LegalContent from "./legal-content/LegalContent";
import SocialMediaLinks from "./social-media-links/SocialMediaLinks";

const PagesPagination = () => {
  const settings = useSettingsContext();

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const [currentTabValue, setCurrentTabValue] = useState("faqs");

  const TABS = [
    {
      value: "faqs",
      label: "FAQs",
      element: <FAQs />,
      permission: usePermission(PrmsEnum.SHOW_FAQ)[1],
    },
    {
      value: "legals",
      label: "Legal content",
      element: <LegalContent />,
      permission: usePermission(PrmsEnum.SHOW_WEBSITE_PAGE)[1],
    },
    {
      value: "social-media-links",
      label: "Social Media Links",
      element: <SocialMediaLinks />,
      permission: usePermission(PrmsEnum.SHOW_WEBSITE_SETTING)[1],
    },
  ];

  const RenderedElement = TABS.filter(tab => tab.value === currentTabValue)[0]
    .element;

  const CurrentTabLabel = TABS.filter(tab => tab.value === currentTabValue)[0]
    .label;

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Stack spacing={4}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: CurrentTabLabel,
            },
          ]}
        />
        <Container
          maxWidth={settings.themeStretch ? false : "xl"}
          sx={{ height: "100%" }}
        >
          <Stack spacing={4}>
            <Tabs
              value={currentTabValue}
              onChange={handleChangeTab}
              sx={{
                px: 3,
                boxShadow: theme =>
                  `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {TABS.map(
                tab =>
                  tab.permission && (
                    <Tab key={tab.value} value={tab.value} label={tab.label} />
                  )
              )}
            </Tabs>
            {RenderedElement}
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default PagesPagination;
