import { Box, Button, Container, Stack, Tab, Tabs } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import AdminsPage from "./admins/AdminsPage";
import { paths } from "src/routes/paths";
import RolesPage from "./roles/RolesPage";
import { useBoolean } from "src/hooks/use-boolean";
import AppButton from "src/components/common/AppButton";
import { Link } from "react-router-dom";
import { PrmsEnum } from "src/types/permissions";
import Restricted from "src/PermissionProvider/Restricted";
import usePermission from "src/PermissionProvider/usePermission";

const RolesPagination = () => {
  const settings = useSettingsContext();
  const newRoleDilaog = useBoolean();
  const newAdminDilaog = useBoolean();

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const handleOpenDialog = () => {
    if (currentTabValue === "roles") {
      newRoleDilaog.onTrue();
    } else if (currentTabValue === "users") {
      newAdminDilaog.onTrue();
    }
  };

  const [currentTabValue, setCurrentTabValue] = useState("roles");

  const TABS = [
    {
      value: "roles",
      label: "Roles",
      element: <RolesPage />,
      permission: usePermission(PrmsEnum.SHOW_ROLE)[1],
    },
    {
      value: "users",
      label: "Users",
      element: <AdminsPage />,
      permission: usePermission(PrmsEnum.SHOW_ADMIN)[1],
    },
  ];

  const RenderedElement = TABS.filter(tab => tab.value === currentTabValue)[0]
    .element;

  const CurrentTabLabel = TABS.filter(tab => tab.value === currentTabValue)[0]
    .label;

  return (
    <>
      <Helmet>
        <title>{CurrentTabLabel}</title>
      </Helmet>
      <Stack spacing={4}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: CurrentTabLabel,
            },
          ]}
        />
        <Container
          maxWidth={settings.themeStretch ? false : "xl"}
          sx={{ height: "100%" }}
        >
          <Restricted to={[PrmsEnum.CREATE_ADMIN, PrmsEnum.CREATE_ROLE]}>
            <Box display='flex' width='100%' justifyContent='end'>
              <Link
                to={(currentTabValue === "roles" ? "roles" : "admins") + "/new"}
              >
                <AppButton
                  variant='contained'
                  sx={{ color: "text.primary" }}
                  onClick={handleOpenDialog}
                  label={`Add new ${
                    currentTabValue === "roles" ? "role" : "admin"
                  }`}
                />
              </Link>
            </Box>
          </Restricted>
          <Stack spacing={4}>
            <Tabs
              value={currentTabValue}
              onChange={handleChangeTab}
              sx={{
                px: 3,
                boxShadow: theme =>
                  `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {TABS.map(
                tab =>
                  tab.permission && (
                    <Tab key={tab.value} value={tab.value} label={tab.label} />
                  )
              )}
            </Tabs>
            {RenderedElement}
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default RolesPagination;
