// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// theme
import { hideScroll } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import { NavSectionMini } from 'src/components/nav-section';
//
import LogoutPopup from 'src/sections/auth/LogoutPopup';
import { useBoolean } from 'src/hooks/use-boolean';
import Button from '@mui/material/Button/Button';
import Logo from 'src/components/mini-nav-logo/logo';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common';

// ----------------------------------------------------------------------

export default function NavMini() {
  const dialog = useBoolean();

  const { user } = useMockedUser();

  const navData = useNavData();

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          justifyContent: 'space-between',
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Box>
          <Logo sx={{ mx: 'auto', my: 2 }} />

          <NavSectionMini
            data={navData}
            config={{
              currentRole: user?.role || 'admin',
            }}
          />
        </Box>
        <Button sx={{mx: 1}} onClick={() => dialog.onTrue()} variant="outlined">
          Logout
        </Button>
      </Stack>
      <LogoutPopup dialog={dialog} />
    </Box>
  );
}
