// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// components
import IconButton from "@mui/material/IconButton";
import { API_URLS } from "src/api/apiUrls";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { BlogsCategoryType } from "src/types/blogs-categories";
import { PrmsEnum } from "src/types/permissions";
import DeleteDialog from "../../components/delete-dialog/DeleteDialog";
import AddBlogCategory from "./AddBlogCategory";
import moment from "moment";

// ----------------------------------------------------------------------

type Props = {
  row: BlogsCategoryType;
};

export default function BlogsCategoriesTableRow ({ row }: Props) {
  const deleteDialog = useBoolean();
  const editDialog = useBoolean();

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
        <Typography>{row?.name}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>{row?.publishedBy?.email}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>{row?.updatedAt ? moment(row?.updatedAt).format('YYYY-MM-DD') : 'Not available'}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Restricted to={PrmsEnum.DELETE_BLOG_CATEGORY}>
          <Tooltip title='Delete' placement='bottom' arrow>
            <IconButton onClick={deleteDialog.onTrue}>
              <Iconify
                icon='material-symbols:delete-outline'
                width='27'
                height='27'
                color='#FFAB8B'
              />
            </IconButton>
          </Tooltip>
        </Restricted>
        <Restricted to={PrmsEnum.UPDATE_BLOG_CATEGORY}>
          <Tooltip title='Edit' placement='bottom' arrow>
            <IconButton onClick={editDialog.onTrue}>
              <Iconify
                icon='ri:edit-2-line'
                width='27'
                height='27'
                color='#FFAB8B'
              />
            </IconButton>
          </Tooltip>
        </Restricted>
      </TableCell>
      <AddBlogCategory
        open={editDialog.value}
        onClose={editDialog.onFalse}
        dialogTitle='Edit category'
        id={row.id}
      />
      {/* Confirm */}
      <DeleteDialog
        open={deleteDialog.value}
        onClose={deleteDialog.onFalse}
        url={API_URLS.BLOGS_CATEGORIES}
        name='blog category'
        id={row.id}
      />
    </TableRow>
  );
}
