import { Card } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { arrayMoveImmutable } from "array-move";
import { useState } from "react";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import { TablePaginationCustom, useTable } from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import CategoryTopicsList from "./CategoryTopicsList";
import CategoryTopicsTableToolbar from "./CategoryTopicsTableToolbar";

const CategoryTopicsTable = () => {
  const [nameSearch, setNameSearch] = useState("");
  const queryClient = useQueryClient();
  const [queryName, setQueryName] = useState("");
  const { useGetListApi, usePatchApi } = useApiServices();
  const viewAll = useBoolean();
  const table = useTable({ defaultRowsPerPage: 10 });
  const { programCategoryId } = useParams();
  const [cardsList, setCardsList] =
    useState<ResDataType["programCategoryTopicsType"]["items"]>();
  const [draggedItemId, setDraggedItemId] = useState<number>();

  const { data, isLoading, error, isRefetching } = useGetListApi<
    ResDataType["programCategoryTopicsType"]
  >({
    url: API_URLS.PROGRAM_TOPICS,
    parameters: {
      page: table.page,
      pageSize: viewAll.value ? -1 : table.rowsPerPage,
      programCategoryId,
      "translations.some.name": queryName,
    },
    onSuccess: (data: ResDataType["programCategoryTopicsType"]) => {
      setCardsList(data.items);
    },
  });

  const { mutate: editSort, isLoading: isSorting } = usePatchApi<{
    sortOrder: number;
  }>({
    url: API_URLS.SORT_PROGRAM_TOPICS(draggedItemId!),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["/dashboard/program-topics"],
      });
    },
  });

  const handleSortEnd = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) return;
    setCardsList((preArr) => arrayMoveImmutable(preArr!, oldIndex, newIndex));
    const draggedItemId = data?.items[oldIndex].id;
    setDraggedItemId(draggedItemId!);
    editSort({ sortOrder: newIndex + 1 });
  };

  return (
    <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
      <Card sx={{ p: 1 }}>
        <CategoryTopicsTableToolbar
          values={nameSearch}
          setSearch={(e) => setNameSearch(e.target.value)}
          setQuery={setQueryName}
          viewAll={viewAll}
        />
        <CategoryTopicsList
          topics={cardsList!}
          isSorting={isRefetching || isSorting}
          onSortEnd={(sort) => handleSortEnd(sort.oldIndex, sort.newIndex)}
          axis="xy"
        />
        {data && (
          <TablePaginationCustom
            count={data?.totalItems}
            page={data.currentPage ? +data.currentPage - 1 : 1}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table?.onChangePage}
            onRowsPerPageChange={table?.onChangeRowsPerPage}
          />
        )}
      </Card>
    </AppLoadingAndErrorWrapper>
  );
};

export default CategoryTopicsTable;
