import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { Container, Stack, Tab, Tabs } from "@mui/material";
import { alpha } from "@mui/material/styles";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import { ConfirmDialog } from "src/components/custom-dialog";
import Iconify from "src/components/iconify/iconify";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { paths } from "src/routes/paths";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import { PrmsEnum } from "src/types/permissions";
import { UserType } from "src/types/users";
import AddBeneficiaryDialog from "../../../../sections/programs/program-beneficiaries/AddBeneficiaryDialog";
import AddFeatureDialog from "../../../../sections/programs/program-features/AddFeatureDialog";
import Gifts from "../../gifts/Gifts";
import UserBadges from "./badges/UserBadges";
import UserPreferences from "./preferences/UserPreferences";
import UserPrograms from "./programs/UserPrograms";
import SubscriptionDetails from "./subscription-details/SubscriptionDetails";

const UsersDetails = () => {
  // #region States
  const featuresDialog = useBoolean();
  const BeneficiariesDialog = useBoolean();
  const settings = useSettingsContext();
  const { userId } = useParams();
  const confirmDelete = useBoolean();
  const confirmEdit = useBoolean();
  const navigate = useNavigate();
  const { useGetItemApi, useDeleteApi, usePatchApi } = useApiServices();
  // eslint-disable-next-line no-nested-ternary
  const [currentTabValue, setCurrentTabValue] = useState(
    "subscripitionDetails"
  );
  // #endregion States

  //#region Services
  const { data: userData } = useGetItemApi<UserType>({
    url: API_URLS.USERS,
    id: userId,
  });

  const { mutate, isLoading: isEditing } = usePatchApi({
    url: API_URLS.USERS,
    id: userId!,
    onSuccess: _ => confirmEdit.onFalse(),
  });
  // #endregion Services

  const userName = userData?.firstName
    ? userData.firstName + userData.lastName
    : userData?.email
    ? userData.email
    : "Details";

  // #region Handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion Handlers

  // #region Tabs
  const TABS = [
    {
      value: "subscripitionDetails",
      label: "Subscription details",
      element: <SubscriptionDetails />,
    },
    {
      value: "preferences",
      label: "Preferences",
      element: <UserPreferences />,
    },
    {
      value: "programs",
      label: "Programs",
      element: <UserPrograms />,
    },
    {
      value: "gifts",
      label: "Gifts",
      element: <Gifts />,
    },
    {
      value: "badges",
      label: "Badges",
      element: <UserBadges />,
    },
  ];

  const RenderedElement = TABS.filter(tab => tab.value === currentTabValue)[0]
    .element;
  // #endregion Tabs

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Container
        maxWidth={settings.themeStretch ? false : "xl"}
        sx={{ height: "100%" }}
      >
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: "Users",
              href: paths.dashboard.users,
            },
            {
              name: userName,
            },
          ]}
        />
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          <Restricted to={PrmsEnum.UPDATE_USER}>
            <AppButton
              sx={{ width: "150px" }}
              color='warning'
              label={userData?.active ? "Deactivate" : "Activate"}
              onClick={confirmEdit.onTrue}
              startIcon={
                <Iconify
                  icon={
                    userData?.active
                      ? "line-md:switch-off-filled"
                      : "line-md:switch-filled-to-switch-off-filled-transition"
                  }
                />
              }
            />
          </Restricted>
          <Restricted to={PrmsEnum.DELETE_USER}>
            <AppButton
              sx={{ width: "150px" }}
              color='error'
              label='Delete'
              onClick={confirmDelete.onTrue}
              startIcon={<Iconify icon='material-symbols:delete-outline' />}
            />
          </Restricted>
        </Stack>
        <Stack spacing={2}>
          <Tabs
            value={currentTabValue}
            onChange={handleChangeTab}
            sx={{
              px: 3,
              boxShadow: theme =>
                `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS.map(tab => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
          {RenderedElement}
        </Stack>
        <AddFeatureDialog
          open={featuresDialog.value}
          onClose={featuresDialog.onFalse}
          dialogTitle='Add a new feature'
        />
        <AddBeneficiaryDialog
          open={BeneficiariesDialog.value}
          onClose={BeneficiariesDialog.onFalse}
          dialogTitle='Add a new beneficiary'
        />
      </Container>
      {/* Confirm change status */}
      <ConfirmDialog
        open={confirmEdit.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirmEdit.onFalse();
          }
        }}
        content={`Are you sure you want to ${
          userData?.active ? "deactivate" : "activate"
        } this user?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirmEdit.onFalse();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isEditing}
              onClick={() => mutate({ active: !userData?.active })}
            />
          </>
        }
      />
      {/* Confirm delete */}
      <DeleteDialog
        open={confirmDelete.value}
        onClose={confirmDelete.onFalse}
        url={API_URLS.USERS}
        name='user'
        id={userId!}
        onSuccess={() => navigate("/dashboard/users")}
      />
    </>
  );
};

export default UsersDetails;
