import * as Yup from "yup";

export const existingTopicFormSchema = {
  translations: [
    {
      name: "",
      thesisStatment: "",
      smsMessageShort: "",
      whatsappMessageShort: "",
      telegramMessageShort: "",
      emailMessageShort: "",
      emailDesignShort: "",
      smsMessageMedium: "",
      whatsappMessageMedium: "",
      emailMessageMedium: "",
      telegramMessageMedium: "",
      emailDesignMedium: "",
      smsMessageLarge: "",
      whatsappMessageLarge: "",
      telegramMessageLarge: "",
      emailMessageLarge: "",
      emailDesignLarge: "",
      locale: "en",
    },
  ],
  programCategoryId: 1,
};

export const newTopicFormSchema = {
  translations: [
    {
      name: "",
      thesisStatment: "",
      smsMessageShort: "",
      whatsappMessageShort: "",
      telegramMessageShort: "",
      smsMessageMedium: "",
      whatsappMessageMedium: "",
      telegramMessageMedium: "",
      smsMessageLarge: "",
      whatsappMessageLarge: "",
      telegramMessageLarge: "",
      locale: "en",
    },
  ],
  programCategoryId: 1,
};

export const newEmailFormSchema = {
  programCategoryId: 0,
  translations: [
    {
      locale: "en",
      emailMessageLarge: "",
      emailDesignLarge: "",
      emailMessageMedium: "",
      emailDesignMedium: "",
      emailMessageShort: "",
      emailDesignShort: "",
    },
  ],
};

export const NewTopicFormSchema = (arrayLength: number) =>
  Yup.object().shape({
    programCategoryId: Yup.number().required("*"),
    translations: Yup.array()
      .required()
      .min(arrayLength)
      .of(
        Yup.object().shape({
          name: Yup.string().required("Topic name is required"),
          thesisStatment: Yup.string().required("Thesis statement is required"),
          smsMessageShort: Yup.string().required("Required field"),
          whatsappMessageShort: Yup.string().required("Required field"),
          telegramMessageShort: Yup.string().required("Required field"),
          smsMessageMedium: Yup.string().required("Required field"),
          whatsappMessageMedium: Yup.string().required("Required field"),
          telegramMessageMedium: Yup.string().required("Required field"),
          smsMessageLarge: Yup.string().required("Required field"),
          whatsappMessageLarge: Yup.string().required("Required field"),
          telegramMessageLarge: Yup.string().required("Required field"),
          locale: Yup.string().required("Required field"),
        })
      ),
  });

export const NewEmailFormSchema = Yup.object().shape({
  translations: Yup.array()
    .required()
    .min(1)
    .of(
      Yup.object().shape({
        emailMessageShort: Yup.string().notRequired(),
        emailMessageMedium: Yup.string().notRequired(),
        emailMessageLarge: Yup.string().notRequired(),
        emailDesignShort: Yup.object().notRequired(),
        emailDesignLarge: Yup.object().notRequired(),
        emailDesignMedium: Yup.object().notRequired(),
        locale: Yup.string().required(),
      })
    ),
});
