import { IconButton, Tooltip } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Link } from "react-router-dom";
import { API_URLS } from "src/api/apiUrls";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import { RoleType } from "src/types/roles";

// ----------------------------------------------------------------------

type Props = {
  row: RoleType;
  onRowDetails?: VoidFunction;
};

export default function RolesTableRow ({ row }: Props) {
  const confirmDelete = useBoolean();

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>{row.id}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>{row.displayName}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>FROM_BE</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>FROM_BE</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Typography>{moment(row.updatedAt).format("YYYY-MM-DD")}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Restricted to={PrmsEnum.DELETE_ROLE}>
          <Tooltip title='Delete' placement='bottom' arrow>
            <IconButton onClick={confirmDelete.onTrue}>
              <Iconify
                icon='material-symbols:delete-outline'
                width='27'
                height='27'
                color='#FFAB8B'
              />
            </IconButton>
          </Tooltip>
        </Restricted>
        <Restricted to={PrmsEnum.UPDATE_ROLE}>
          <Tooltip title='Edit' placement='bottom' arrow>
            <Link to={`roles/${row.id}`}>
              <IconButton onClick={confirmDelete.onTrue}>
                <Iconify
                  icon='ri:edit-2-line'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Link>
          </Tooltip>
        </Restricted>
      </TableCell>
      {/* Confirm */}
      <DeleteDialog
        open={confirmDelete.value}
        onClose={confirmDelete.onFalse}
        url={API_URLS.ROLES}
        name='role'
        id={row.id}
      />
    </TableRow>
  );
}
