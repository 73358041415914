import React, { PropsWithChildren } from "react";
import { Permission } from "src/types/permission-context-type";
import usePermission from "./usePermission";

interface Props extends PropsWithChildren {
  to: Permission | Permission[];
  fallback?: JSX.Element | string;
  loadingComponent?: JSX.Element | string;
  andSchema?: true;
  style?: React.CSSProperties;
}

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({
  to,
  fallback,
  loadingComponent,
  children,
  andSchema,
  style,
}) => {
  // We "connect" to the provider thanks to the PermissionContext
  const [loading, allowed] = usePermission(to, andSchema);

  if (loading) {
    return style ? (
      <div style={style}>{loadingComponent}</div>
    ) : (
      <>{loadingComponent}</>
    );
  }

  // If the user has that permission, render the children
  if (allowed) {
    return style ? <div style={style}>{children}</div> : <>{children}</>;
  }

  // Otherwise, render the fallback
  return style ? <div style={style}>{fallback}</div> : <>{fallback}</>;
};

export default Restricted;
