import {
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import FormProvider from "src/components/hook-form/form-provider";
import Scrollbar from "src/components/scrollbar";
import StatisticCard from "src/components/StatisticCard";
import {
  TableHeadCustom,
  TablePaginationCustom,
  TableSkeleton,
  useTable,
} from "src/components/table";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import usePermission from "src/PermissionProvider/usePermission";
import ProgramUsersTableRow from "src/sections/programs/program-users/ProgramUsersTableRow";
import ProgramUsersTableToolbar from "src/sections/programs/program-users/ProgramUsersTableToolbar";
import { PrmsEnum } from "src/types/permissions";

export default function ProgramUsers () {
  const { programId } = useParams();
  const { useGetListApi } = useApiServices();
  const TABLE_HEAD = [
    { id: "1", label: "User name" },
    { id: "2", label: "Start date" },
    { id: "3", label: "End date" },
    { id: "4", label: "Medium" },
    { id: "5", label: "Program", condition: !programId },
    { id: "6", label: "Category" },
    { id: "7", label: "M.Number" },
    { id: "8", label: "Status" },
    { id: "9", label: "Actions" },
  ].filter(e => (e.condition === undefined ? true : e.condition));

  const methods = useForm({
    defaultValues: {
      messageMedium: undefined,
      programCategoryId: undefined,
      messageNumber: undefined,
      status: undefined,
      startDate: undefined,
      endDate: undefined,
      userId: undefined,
      programId,
    },
  });

  const {
    data: usersCount,
    isLoading,
    error,
  } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.USERS_COUNT,
    parameters: {
      programId,
    },
  });

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
        <FormProvider methods={methods}>
          <Grid container spacing={4} my={1}>
            <>
              {/* Statistics */}
              {usersCount &&
                usersCount?.map((item, index) => (
                  <Grid item xs={12} md={3}>
                    <StatisticCard
                      key={index}
                      cardTitle={item.label}
                      cardCount={+item.value}
                      index={index}
                    />
                  </Grid>
                ))}
              <Grid xs={12} item>
                <Restricted
                  to={PrmsEnum.SHOW_PROGRAM}
                  fallback={
                    <Card>
                      <Stack alignItems='center'>
                        <TableContainer>
                          <Scrollbar>
                            <Table>
                              <TableHeadCustom headLabel={TABLE_HEAD} />
                              <TableBody></TableBody>
                            </Table>
                          </Scrollbar>
                        </TableContainer>
                        <TableSkeleton sx={{ height: 80 }} />
                        <Typography width='100%' textAlign='center' color='error'>
                          You need {PrmsEnum.SHOW_PROGRAM} permission to show
                          this table
                        </Typography>
                      </Stack>
                    </Card>
                  }
                >
                  <ProgramUsersTable methods={methods} />
                </Restricted>
              </Grid>
            </>
          </Grid>
        </FormProvider>
      </AppLoadingAndErrorWrapper>
    </>
  );
}

const ProgramUsersTable = ({ methods }: { methods: any }) => {
  const quickDetails = useBoolean();
  const { useGetListApi } = useApiServices();
  const table = useTable({ defaultRowsPerPage: 10 });
  const { programId } = useParams();

  const TABLE_HEAD = [
    { id: "1", label: "User name" },
    { id: "2", label: "Start date" },
    { id: "3", label: "End date" },
    { id: "4", label: "Medium" },
    { id: "5", label: "Program", condition: !programId },
    { id: "6", label: "Category" },
    { id: "7", label: "M.Number" },
    { id: "8", label: "Status" },
    { id: "9", label: "Actions" },
  ].filter(e => (e.condition === undefined ? true : e.condition));

  const [search, setSearch] = useState({
    messageMedium: undefined,
    programCategoryId: undefined,
    messageNumber: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined,
    userId: undefined,
    programId,
  });

  const { data, isLoading, error } = useGetListApi<ResDataType["programUsers"]>(
    {
      url: API_URLS.PROGRAM_USERS,
      parameters: {
        page: table.page,
        pageSize: table.rowsPerPage,
        ...search,
        endDate: search.endDate ? search.endDate : null,
        startDate: search.startDate ? search.startDate : null,
        status: search.status ? search.status : null,
        programCategoryId: search.programCategoryId
          ? search.programCategoryId
          : null,
        messageMedium: search.messageMedium ? search.messageMedium : null,
        messageNumber: search.messageNumber ? search.messageNumber : null,
      },
    }
  );

  return (
    <Grid item xs={12}>
      <Card>
        <ProgramUsersTableToolbar
          values={methods.watch()}
          setSearch={setSearch}
          reset={methods.reset}
        />
        <TableContainer>
          <Scrollbar>
            <Table>
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {isLoading ? (
                  [...Array(table.rowsPerPage)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: 80 }} />
                  ))
                ) : (
                  <>
                    {data!?.items?.map(row => (
                      <ProgramUsersTableRow
                        key={row.id}
                        row={row}
                        onRowDetails={() => {
                          quickDetails.onTrue();
                        }}
                      />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        {data && (
          <TablePaginationCustom
            count={data?.totalItems}
            page={data ? +data.currentPage - 1 : 1}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table?.onChangePage}
            onRowsPerPageChange={table?.onChangeRowsPerPage}
          />
        )}
      </Card>
    </Grid>
  );
};
