// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// components
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { Link } from "react-router-dom";
import { API_URLS } from "src/api/apiUrls";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { CareerType } from "src/types/careers";
import { PrmsEnum } from "src/types/permissions";

// ----------------------------------------------------------------------

type Props = {
  row: CareerType;
};

export default function VacanciesTableRow ({
  row,
}: Props) {
  const dialog = useBoolean();
  const confirm = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row.title}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.responsibility}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{moment(row.updatedAt).format("YYYY-MM-DD")}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Restricted to={PrmsEnum.DELETE_CAREER}>
            <Tooltip title='Delete' placement='bottom' arrow>
              <IconButton onClick={() => confirm.onTrue()}>
                <Iconify
                  icon='material-symbols:delete-outline'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
          <Restricted to={PrmsEnum.UPDATE_CAREER}>
            <Link to={`${row.id}`}>
              <Tooltip title='Edit' placement='bottom' arrow>
                <IconButton onClick={dialog.onTrue}>
                  <Iconify
                    icon='carbon:edit'
                    width='27'
                    height='27'
                    color='#FFAB8B'
                  />
                </IconButton>
              </Tooltip>
            </Link>
          </Restricted>
        </TableCell>
      </TableRow>
      {/* Confirm */}
      <DeleteDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        url={API_URLS.VACANCIES}
        id={row.id}
        name="vacancy"
      />
    </>
  );
}
