import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Box, Grid, MenuItem, Stack, Switch, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, {
  RHFDatePicker,
  RHFMultiSelect,
  RHFSelect,
  RHFTimePicker
} from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import { ChangeStatusSchema } from "src/schemas";
import { IBaseStatusDto } from "src/types";
import {
  ProgramUserDetailsDtoType
} from "src/types/program-users";
import { IChangeProgramStatusDto, IProgramDto } from "src/types/programs";
import { ProgramDetailsDtoType } from "src/types/programs-dropdown";
import { Block } from "../Block";
import AppLoadingAndErrorWrapper from "../common/AppLoadingAndErrorWrapper";

interface IChangePastProgramStatusDialogProps {
  open: boolean;
  onClose: VoidFunction;
  program: IProgramDto;
  newProgramStatus: string;
  dialogTitle: string;
  hasCurrentProgram: Boolean;
}

// Display when user has past program
const HasCurrentProgram = () => (
  <Stack
    direction='column'
    spacing={3}
    justifyContent='center'
    alignItems='center'
  >
    <Typography fontSize={28} fontWeight={700} align='center'>
      Unfortunately, You can&apos;t restart the program now.
    </Typography>
    <Typography fontSize={20} fontWeight={400} align='center'>
      It appears that you have subscribed to another program at the moment. To
      restart this program, please finish your current program first and then
      try restarting it.
    </Typography>
  </Stack>
);

const ChangePastProgramStatusDialog: React.FC<
  IChangePastProgramStatusDialogProps
> = (props: IChangePastProgramStatusDialogProps) => {
  const {
    open,
    onClose,
    program,
    newProgramStatus,
    dialogTitle,
    hasCurrentProgram,
  } = props;
  // #region States and Hooks
  const { usePatchApi, useGetListApi, useGetItemApi } = useApiServices();
  const confirm = useBoolean();
  const success = useBoolean();
  const showPreferences = useBoolean();
  const showPreferencesButton = useBoolean();
  const [dataToSend, setDataToSend] = useState<IChangeProgramStatusDto>();
  // #endregion States and Hooks

  // #region Form
  const methods = useForm<IChangeProgramStatusDto>({
    resolver: yupResolver(ChangeStatusSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = methods;
  // #endregion From

  // #region Services
  const { mutate, isLoading } = usePatchApi({
    url: API_URLS.PROGRAM_USERS,
    id: program?.id,
    withSuccessNotistack: false,
    onSuccess: () => {
      success.onTrue();
    },
  });

  const {
    data: messageFrequency,
    isLoading: isMessageFrequencyLoading,
    error: messageFrequencyError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.MESSAGES_FREQUENCY,
  });

  const {
    data: weekDays,
    isLoading: isWeekDaysLoading,
    error: weekDaysError,
  } = useGetListApi<IBaseStatusDto[]>({
    url: API_URLS.WEEK_DAYS,
  });

  const {
    data: selectedProgram,
    isLoading: isSelectedProgramLoading,
    error: selectedProgramError,
  } = useGetItemApi<ProgramUserDetailsDtoType>({
    url: API_URLS.PROGRAM_USERS,
    id: program.id,
    onSuccess: (data: ProgramUserDetailsDtoType) => {
      methods.setValue("messageFrequency", data.messageFrequency.value);
      methods.setValue("language", data.programLanaguage.value);
      methods.setValue("topicLength", data.topicLength.value);
      methods.setValue("messageTime", new Date(data.messageTime));
      methods.setValue("weekDay", data.weekDay?.value!);
      methods.setValue(
        "messageMedium",
        data.messageMedium.map(mm => mm.value)
      );
    },
  });

  const {
    data: selectedProgramDetails,
    isLoading: isCurrentPrgLoading,
    error: currentPrgError,
  } = useGetItemApi<ProgramDetailsDtoType>({
    url: API_URLS.PROGRAMS,
    id: program.program.id,
  });

  console.log("Selected user program", selectedProgram);
  console.log("Selected program", selectedProgramDetails);
  // #endregion Services

  // #region Handlers
  const onSubmit = useCallback(
    async (data: IChangeProgramStatusDto) => {
      data.status = newProgramStatus;
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm, newProgramStatus]
  );

  const handleEditPrfs = () => {
    if (watch().startDate) {
      showPreferences.onTrue();
      showPreferencesButton.onFalse();
    } else {
      setError("startDate", { message: "Restart date is required" });
    }
  };
  // #endregion Handlers

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "4px" } }}
        maxWidth={hasCurrentProgram ? "md" : "sm"}
      >
        <DialogContent>
          <AppLoadingAndErrorWrapper
            isLoading={
              isMessageFrequencyLoading ||
              isWeekDaysLoading ||
              isCurrentPrgLoading ||
              isSelectedProgramLoading
            }
            errorMessage={
              messageFrequencyError ||
              weekDaysError ||
              currentPrgError ||
              selectedProgramError
            }
          >
            <Box p={{ md: 8, xs: 3 }}>
              {hasCurrentProgram && newProgramStatus === "IN_PROGRESS" ? (
                <HasCurrentProgram />
              ) : (
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={3}>
                    {!showPreferences.value && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            textAlign='center'
                            fontSize={24}
                            fontWeight={700}
                          >
                            {!showPreferences.value
                              ? dialogTitle
                              : "Edit user program preferences"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <RHFDatePicker name='startDate' minDate={new Date()} />
                          {errors.startDate && (
                            <Typography pt={1} fontSize={12} color='#FF5630'>
                              {errors.startDate.message}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Stack
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Typography>Change program preferences</Typography>
                            <Switch
                              checked={showPreferencesButton.value}
                              onChange={showPreferencesButton.onToggle}
                            />
                          </Stack>
                        </Grid>
                      </>
                    )}
                    {showPreferences.value && (
                      <Grid
                        container
                        xs={12}
                        columnSpacing={{ xs: 0, md: 1 }}
                        rowSpacing={2}
                        sx={{ my: 2 }}
                      >
                        <Grid item xs={12} md={6}>
                          <Block label='Message medium'>
                            <RHFMultiSelect
                              checkbox
                              defaultValue={[]}
                              name='messageMedium'
                              label='Choose'
                              options={
                                selectedProgramDetails?.messageMedium || []
                              }
                            />
                          </Block>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Block label='Topic length'>
                            <RHFSelect name='topicLength'>
                              {selectedProgramDetails?.topicLength?.map(
                                (length: any) => (
                                  <MenuItem
                                    key={length.value}
                                    value={length.value}
                                  >
                                    {length.label}
                                  </MenuItem>
                                )
                              )}
                            </RHFSelect>
                          </Block>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Block label='Program language'>
                            <RHFSelect name='language'>
                              {selectedProgramDetails?.language?.map(
                                (lang: any) => (
                                  <MenuItem key={lang.value} value={lang.value}>
                                    {lang.label}
                                  </MenuItem>
                                )
                              )}
                            </RHFSelect>
                          </Block>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Block label='Message time'>
                            <RHFTimePicker name='messageTime' />
                          </Block>
                        </Grid>
                        <Grid item xs={12}>
                          <Block label='Message frequency'>
                            <RHFSelect label='Choose' name='messageFrequency'>
                              {(messageFrequency || [])?.map(
                                (option, index) => (
                                  <MenuItem key={index} value={option?.value}>
                                    {option?.label}
                                  </MenuItem>
                                )
                              )}
                            </RHFSelect>
                          </Block>
                        </Grid>
                        <Grid item xs={12}>
                          {watch().messageFrequency?.toLowerCase() ===
                            "weekly" && (
                            <Block label='Week day'>
                              <RHFSelect label='Choose' name='weekDay'>
                                {(weekDays || [])?.map(day => (
                                  <MenuItem key={day?.value} value={day?.value}>
                                    {day?.label}
                                  </MenuItem>
                                ))}
                              </RHFSelect>
                            </Block>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <AppButton
                        label='Cancel'
                        color='secondary'
                        onClick={onClose}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {!showPreferencesButton.value ? (
                        <AppLoadingButton
                          onClick={showPreferences.onFalse}
                          label='Save'
                          fullWidth
                          isLoading={isLoading}
                        />
                      ) : (
                        <AppButton
                          label='Edit preferences'
                          fullWidth
                          onClick={handleEditPrfs}
                        />
                      )}
                    </Grid>
                  </Grid>
                </FormProvider>
              )}
            </Box>
          </AppLoadingAndErrorWrapper>
        </DialogContent>
      </Dialog>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value && !success.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content='Are you sure you want to Change program status?'
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading}
              onClick={() => mutate(dataToSend)}
            />
          </>
        }
      />

      {/* Success */}
      <ConfirmDialog
        open={success.value && confirm.value}
        onClose={onClose}
        content={`This user program will restart at ${dataToSend?.startDate?.toDateString()}.`}
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </>
  );
};

export default ChangePastProgramStatusDialog;
