export type PermissionsDtoType = PermissionType[];

export interface PermissionType {
  section: string;
  permissions: Permission[];
}

export interface Permission {
  permission: string;
  name: string;
}

export enum PrmsEnum {
  SHOW_USER = "SHOW_USER",
  CREATE_USER = "CREATE_USER",
  UPDATE_USER = "UPDATE_USER",
  DELETE_USER = "DELETE_USER",
  SHOW_ROLE = "SHOW_ROLE",
  CREATE_ROLE = "CREATE_ROLE",
  UPDATE_ROLE = "UPDATE_ROLE",
  DELETE_ROLE = "DELETE_ROLE",
  SHOW_ADMIN = "SHOW_ADMIN",
  CREATE_ADMIN = "CREATE_ADMIN",
  UPDATE_ADMIN = "UPDATE_ADMIN",
  DELETE_ADMIN = "DELETE_ADMIN",
  SHOW_BLOG = "SHOW_BLOG",
  CREATE_BLOG = "CREATE_BLOG",
  UPDATE_BLOG = "UPDATE_BLOG",
  DELETE_BLOG = "DELETE_BLOG",
  SHOW_CAREER = "SHOW_CAREER",
  CREATE_CAREER = "CREATE_CAREER",
  UPDATE_CAREER = "UPDATE_CAREER",
  DELETE_CAREER = "DELETE_CAREER",
  SHOW_BADGE = "SHOW_BADGE",
  CREATE_BADGE = "CREATE_BADGE",
  UPDATE_BADGE = "UPDATE_BADGE",
  DELETE_BADGE = "DELETE_BADGE",
  SHOW_BLOG_CATEGORY = "SHOW_BLOG_CATEGORY",
  CREATE_BLOG_CATEGORY = "CREATE_BLOG_CATEGORY",
  UPDATE_BLOG_CATEGORY = "UPDATE_BLOG_CATEGORY",
  DELETE_BLOG_CATEGORY = "DELETE_BLOG_CATEGORY",
  SHOW_CONTACT_US = "SHOW_CONTACT_US",
  CREATE_CONTACT_US = "CREATE_CONTACT_US",
  UPDATE_CONTACT_US = "UPDATE_CONTACT_US",
  DELETE_CONTACT_US = "DELETE_CONTACT_US",
  SHOW_CONTACT_US_REPLAY = "SHOW_CONTACT_US_REPLAY",
  CREATE_CONTACT_US_REPLAY = "CREATE_CONTACT_US_REPLAY",
  UPDATE_CONTACT_US_REPLAY = "UPDATE_CONTACT_US_REPLAY",
  DELETE_CONTACT_US_REPLAY = "DELETE_CONTACT_US_REPLAY",
  SHOW_FAQ = "SHOW_FAQ",
  CREATE_FAQ = "CREATE_FAQ",
  UPDATE_FAQ = "UPDATE_FAQ",
  DELETE_FAQ = "DELETE_FAQ",
  SHOW_PROGRAM = "SHOW_PROGRAM",
  CREATE_PROGRAM = "CREATE_PROGRAM",
  UPDATE_PROGRAM = "UPDATE_PROGRAM",
  DELETE_PROGRAM = "DELETE_PROGRAM",
  SHOW_PROGRAM_CATEGORY = "SHOW_PROGRAM_CATEGORY",
  CREATE_PROGRAM_CATEGORY = "CREATE_PROGRAM_CATEGORY",
  UPDATE_PROGRAM_CATEGORY = "UPDATE_PROGRAM_CATEGORY",
  DELETE_PROGRAM_CATEGORY = "DELETE_PROGRAM_CATEGORY",
  SHOW_PROGRAM_TOPIC = "SHOW_PROGRAM_TOPIC",
  CREATE_PROGRAM_TOPIC = "CREATE_PROGRAM_TOPIC",
  UPDATE_PROGRAM_TOPIC = "UPDATE_PROGRAM_TOPIC",
  DELETE_PROGRAM_TOPIC = "DELETE_PROGRAM_TOPIC",
  SHOW_WEBSITE_PAGE = "SHOW_WEBSITE_PAGE",
  CREATE_WEBSITE_PAGE = "CREATE_WEBSITE_PAGE",
  UPDATE_WEBSITE_PAGE = "UPDATE_WEBSITE_PAGE",
  DELETE_WEBSITE_PAGE = "DELETE_WEBSITE_PAGE",
  SHOW_WEBSITE_SETTING = "SHOW_WEBSITE_SETTING",
  CREATE_WEBSITE_SETTING = "CREATE_WEBSITE_SETTING",
  UPDATE_WEBSITE_SETTING = "UPDATE_WEBSITE_SETTING",
  DELETE_WEBSITE_SETTING = "DELETE_WEBSITE_SETTING",
  SHOW_GIFT = "SHOW_GIFT",
  CREATE_GIFT = "CREATE_GIFT",
  UPDATE_GIFT = "UPDATE_GIFT",
  DELETE_GIFT = "DELETE_GIFT",
}
