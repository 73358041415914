import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Container, Grid, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import PhoneNumberInputField from "src/components/PhoneNumberInputField";
import { useBoolean } from "src/hooks/use-boolean";
import { useResponsive } from "src/hooks/use-responsive";
import { AddNewAdminFormType, AdminType } from "src/types/admins";
import {
  newAdminFormSchema,
  NewAdminFormSchemaResolver,
} from "./add-new-admin-schema";

const AddNewAdmin = () => {
  // #region States
  const passwordField = useBoolean();
  const confirmPasswordField = useBoolean();
  const mdDown = useResponsive("down", "md");
  const { adminId } = useParams();
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<AddNewAdminFormType>();
  const { usePostApi, useGetItemApi, usePatchApi, useGetListApi } =
    useApiServices();
  // #endregion States

  // #region Services
  const { mutate: addAdmin, isLoading: isAdding } =
    usePostApi<AddNewAdminFormType>({
      url: API_URLS.ADMINS,
      onSuccess: () => {
        confirm.onFalse();
        methods.reset();
      },
    });

  const { isLoading: isAdminLoading, error } = useGetItemApi<AdminType>({
    url: API_URLS.ADMINS,
    id: adminId,
    onSuccess: (data: AdminType) => {
      methods.setValue("fullName", data.fullName);
      methods.setValue("email", data.email);
      methods.setValue("mobilePhone", data.mobilePhone);
      methods.setValue("roleId", data.roleId);
    },
  });

  const { data: roles, isLoading: areRolesLoading } = useGetListApi<
    ResDataType["roles"]
  >({
    url: API_URLS.ROLES,
  });

  const { mutate: editAdmin, isLoading: isEditing } =
    usePatchApi<AddNewAdminFormType>({
      url: API_URLS.ADMINS,
      id: adminId!,
      onSuccess: () => confirm.onFalse(),
    });

  const mutate = adminId ? editAdmin : addAdmin;
  // #endregion Services

  // #region form
  const methods = useForm<AddNewAdminFormType>({
    defaultValues: newAdminFormSchema,
    resolver: yupResolver(NewAdminFormSchemaResolver(adminId!)) as any,
  });

  console.log(methods.formState.errors);

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (data: AddNewAdminFormType) => {
      if (adminId) {
        console.log("ran");
        delete data.password;
      }
      data.roleId = +data.roleId;
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );
  // #endregion form

  return (
    <AppLoadingAndErrorWrapper isLoading={isAdminLoading} errorMessage={error}>
      <Container>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Stack py={5} spacing={3}>
                <Block label='Name'>
                  <RHFTextField name='fullName' />
                </Block>

                <Block label='Email'>
                  <RHFTextField name='email' />
                </Block>

                <Block label='Role'>
                  <RHFSelect name='roleId'>
                    {roles?.items?.map(role => (
                      <MenuItem key={role.id} value={+role.id}>
                        {role.displayName}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                </Block>

                <PhoneNumberInputField name='mobilePhone' />

                {!adminId && (
                  <>
                    <Block label='Account Password'>
                      <RHFTextField
                        name='password'
                        type={passwordField.value ? "text" : "password"}
                        InputProps={{
                          sx: { borderRadius: "16px" },
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={passwordField.onToggle}
                                edge='end'
                              >
                                {passwordField.value ? (
                                  <VisibilityOutlinedIcon fontSize='small' />
                                ) : (
                                  <VisibilityOffOutlinedIcon fontSize='small' />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Block>

                    <Block label='Confirm Password'>
                      <RHFTextField
                        name='confirmPassword'
                        type={confirmPasswordField.value ? "text" : "password"}
                        InputProps={{
                          sx: { borderRadius: "16px" },
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                onClick={confirmPasswordField.onToggle}
                                edge='end'
                              >
                                {confirmPasswordField.value ? (
                                  <VisibilityOutlinedIcon fontSize='small' />
                                ) : (
                                  <VisibilityOffOutlinedIcon fontSize='small' />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Block>
                  </>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack direction='row' justifyContent='flex-end'>
                <AppLoadingButton
                  fullWidth={mdDown}
                  label={adminId ? "Edit" : "Add"}
                  isLoading={false}
                />
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </Container>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
          }
        }}
        content={`Are you sure you want to ${
          adminId ? "edit" : "add"
        } this admin?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isAdding || isEditing}
              onClick={() =>
                mutate({ ...dataToSend!, confirmPassword: undefined })
              }
            />
          </>
        }
      />
    </AppLoadingAndErrorWrapper>
  );
};

export default AddNewAdmin;
