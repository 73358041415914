import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { useState } from "react";
// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// components
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import Iconify from "src/components/iconify/iconify";
import Image from "src/components/image/image";
import { ASSETS_API } from "src/config-global";
import { useBoolean } from "src/hooks/use-boolean";
import { ProgramFeatureType } from "src/types/program-features";
import AddFeatureDialog from "./AddFeatureDialog";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import { API_URLS } from "src/api/apiUrls";

// ----------------------------------------------------------------------

type Props = {
  row: ProgramFeatureType;
  mutateDelete: any;
  isDeleteLoading: boolean;
};

export default function ProgramFeaturesTableRow ({
  row,
  mutateDelete,
  isDeleteLoading,
}: Props) {
  const dialog = useBoolean();
  const [id, setId] = useState(row.id);
  const confirm = useBoolean();
  const handleDialogOpen = () => {
    dialog.onTrue();
    setId(row.id);
  };
  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Image src={`${ASSETS_API}/${row?.featureImage}`} />
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row?.description}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{moment(row.createdAt).format("YYYY-MM-DD")}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Restricted to={PrmsEnum.UPDATE_PROGRAM}>
            <Tooltip title='Delete' placement='bottom' arrow>
              <IconButton onClick={confirm.onTrue}>
                <Iconify
                  icon='material-symbols:delete-outline'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
          <Restricted to={PrmsEnum.UPDATE_PROGRAM}>
            <Tooltip title='Edit' placement='bottom' arrow>
              <IconButton onClick={handleDialogOpen}>
                <Iconify
                  icon='carbon:edit'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
        </TableCell>
        <AddFeatureDialog
          open={dialog.value}
          onClose={dialog.onFalse}
          dialogTitle='Edit feature'
          id={id}
        />
      </TableRow>
      {/* Confirm */}
      <DeleteDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        name="feature"
        id={row.id}
        url={API_URLS.PROGRAM_FEATURES}
      />
    </>
  );
}
