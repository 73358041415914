/* eslint-disable func-names */
import * as Yup from "yup";

// Change program status
export const ChangeStatusSchema = Yup.object().shape({
  startDate: Yup.date().required("This field is required"),
});

// Login
export const LoginSchema = Yup.object().shape({
  loginId: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
});

// Reset password
// export const ResetPasswordSchema = Yup.object().shape({
//   password: Yup.string().required("This field is required"),
//   confirmPassword: Yup.string()
//     .required("This field is required")
//     .oneOf([Yup.ref("password")], "Passwords must match"),
// });

// Change personal info
export const ChangePersonalInfoSchema = Yup.object().shape({
  fullName: Yup.string().required("This field is required"),
  mobilePhone: Yup.string().required("This field is required"),
});

// Badges
export const AddProgramBadgeSchema = Yup.object().shape({
  featureImage: Yup.string().required("This field is required"),
  programId: Yup.number().required("This field is required"),
});

export const AddCategoryBadgeSchema = Yup.object().shape({
  featureImage: Yup.string().required("This field is required"),
  programCategoryId: Yup.number().required("This field is required"),
});

// Contact us reply schema
export const ReplyToTicketSchema = Yup.object().shape({
  message: Yup.string().required("Message cannot be empty"),
  contactUsId: Yup.number().required("This field is required"),
});

// New role schema
export const NewRoleSchema = Yup.object().shape({
  name: Yup.string().required("Name cannot be empty"),
  displayName: Yup.string().required("Display name cannot be empty"),
  permissions: Yup.array()
    .required("Permissions are required")
    .min(1)
    .of(Yup.string().required()),
});

// New feature schema
export const NewFeatureSchema = (languagesCount: number) =>
  Yup.object().shape({
    featureImage: Yup.string().required("An image should be choosen"),
    programId: Yup.number().required("Program Id is required"),
    translations: Yup.array()
      .required()
      .min(languagesCount)
      .of(
        Yup.object().shape({
          locale: Yup.string().required(""),
          description: Yup.string().required(
            "Feature description cannot be empty"
          ),
        })
      ),
  });

// New program category
export const NewPrgCtgSchema = (languagesCount: number) =>
  Yup.object().shape({
    programId: Yup.number().required("Program Id is required"),
    translations: Yup.array()
      .required()
      .min(languagesCount)
      .of(
        Yup.object().shape({
          locale: Yup.string().required(""),
          name: Yup.string().required("Category name cannot be empty"),
        })
      ),
  });

// New program beneficiary
export const NewBeneficiarySchema = (languagesCount: number) =>
  Yup.object().shape({
    programId: Yup.number().required("Program Id is required"),
    translations: Yup.array()
      .required()
      .min(languagesCount)
      .of(
        Yup.object().shape({
          locale: Yup.string().required(""),
          description: Yup.string().required("Description cannot be empty"),
        })
      ),
  });

// New program badge schema
export const NewPrgBadgeSchema = Yup.object().shape({
  programId: Yup.number().required("Please select a program"),
  featureImage: Yup.string().required("Badge image is required"),
});

// New category badge schema
export const NewCtgBadgeSchema = Yup.object().shape({
  programCategoryId: Yup.number().required("Please select a program category"),
  featureImage: Yup.string().required("Badge image is required"),
});

// Program description schema
export const ProgramFormSchema = (arrayLength: number) =>
  Yup.object().shape({
    featureImage: Yup.string().required("*"),
    language: Yup.array().required().min(1).of(Yup.string().required()),
    topicLength: Yup.array().required().min(1).of(Yup.string().required()),
    messageMedium: Yup.array().required().min(1).of(Yup.string().required()),
    translations: Yup.array()
      .required()
      .min(arrayLength)
      .of(
        Yup.object().shape({
          name: Yup.string().required("Title is required"),
          header: Yup.string().required("Header is required"),
          description: Yup.string().required("Description is required"),
          duration: Yup.string().required("Duration is required"),
          locale: Yup.string().required(),
        })
      ),
  });

// Program description schema
export const LinksFormSchema = Yup.object().shape({
  value: Yup.string().required("This field cannot be empty"),
});

export const VacancyFormSchema = (arrayLength: number) =>
  Yup.object().shape({
    translations: Yup.array()
      .required()
      .min(arrayLength)
      .of(
        Yup.object().shape({
          title: Yup.string().required("Title is required"),
          description: Yup.string().required("Description is required"),
          responsibility: Yup.string()
            .required("Responsibility is required")
            .test(
              "",
              "Answer cannot be empty",
              (value) => value !== "<p><br></p>"
            ),
          minmumQualification: Yup.string()
            .required("Qualifications are required")
            .test(
              "",
              "Answer cannot be empty",
              (value) => value !== "<p><br></p>"
            ),
          locale: Yup.string().required("Locale is required"),
        })
      ),
  });
