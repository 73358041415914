import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// components
import Iconify from "src/components/iconify/iconify";
import IconButton from "@mui/material/IconButton";
import { useBoolean } from "src/hooks/use-boolean";
import { FAQType } from "src/types/faqs";
import { ConfirmDialog } from "src/components/custom-dialog";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { Link } from "react-router-dom";
import { WebsitePageDto } from "src/types/website-pages";
import { SocialMediaLinkType } from "src/types/social-media-links";
import moment from "moment";
import EditSMLinkDialog from "./EditSMLinkDialog";
import Restricted from "src/PermissionProvider/Restricted";
import { PrmsEnum } from "src/types/permissions";

// ----------------------------------------------------------------------

type Props = {
  row: SocialMediaLinkType;
};

export default function SMLinksTableRow ({ row }: Props) {
  const confirm = useBoolean();

  return (
    <TableRow hover>
      <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
        <Typography>{row.key}</Typography>
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        <Typography>{row.value}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
        <Typography>{moment(row.updatedAt).format("YYYY-MM-DD")}</Typography>
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
        <Restricted to={PrmsEnum.UPDATE_WEBSITE_SETTING}>
          <Tooltip title='Edit' placement='bottom' arrow>
            <IconButton onClick={confirm.onTrue}>
              <Iconify
                icon='carbon:edit'
                width='27'
                height='27'
                color='#FFAB8B'
              />
            </IconButton>
          </Tooltip>
        </Restricted>
      </TableCell>
      <EditSMLinkDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        dialogTitle='Edit link'
        id={row.id}
      />
    </TableRow>
  );
}
