// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
// components
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { API_URLS } from "src/api/apiUrls";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import Iconify from "src/components/iconify/iconify";
import { WEBISTE_URL } from "src/config-global";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { BlogType } from "src/types/blogs";
import { PrmsEnum } from "src/types/permissions";
import moment from "moment";

// ----------------------------------------------------------------------

type Props = {
  row: BlogType;
};

export default function BlogsTableRow ({ row }: Props) {
  const confirm = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>{row.title}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row?.blogCategory?.name}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.publishedBy?.email}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.status.label}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{moment(row.updatedAt).format('YYYY-MM-DD')}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.views}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Restricted to={PrmsEnum.DELETE_BLOG}>
            <Tooltip title='Delete' placement='bottom' arrow>
              <IconButton onClick={() => confirm.onTrue()}>
                <Iconify
                  icon='material-symbols:delete-outline'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
          <Restricted to={PrmsEnum.SHOW_BLOG}>
            <Tooltip title='View' placement='bottom' arrow>
              <Link to={`${WEBISTE_URL}blog/${row.id}`}>
                <IconButton>
                  <Iconify
                    icon='mdi:eye-circle'
                    width='27'
                    height='27'
                    color='#FFAB8B'
                  />
                </IconButton>
              </Link>
            </Tooltip>
          </Restricted>
          <Restricted to={PrmsEnum.UPDATE_BLOG}>
            <Tooltip title='Edit' placement='bottom' arrow>
              <Link to={`${row.id}`}>
                <IconButton>
                  <Iconify
                    icon='ri:edit-2-line'
                    width='27'
                    height='27'
                    color='#FFAB8B'
                  />
                </IconButton>
              </Link>
            </Tooltip>
          </Restricted>
        </TableCell>
      </TableRow>
      {/* Confirm */}
      <DeleteDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        name='blog post'
        url={API_URLS.BLOGS}
        id={row.id}
      />
    </>
  );
}
