import { Divider, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Helmet } from "react-helmet-async";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import ChangePersonalInfoDialog from "src/components/profile/ChangePersonalInfoDialog";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import { paths } from "src/routes/paths";
import { IBaseStatusDto } from "src/types";
import { IUserProfileDto } from "src/types/user";

const LabelWithValue = ({ label, value }: IBaseStatusDto) => (
  <Stack
    direction={{ xs: "column", md: "row" }}
    spacing={2}
    textAlign={{ xs: "center", md: "start" }}
  >
    <Typography variant='h4'>{label}</Typography>
    <Typography
      variant='h4'
      fontWeight={400}
      style={{ wordWrap: "break-word" }}
    >
      {value}
    </Typography>
  </Stack>
);

export default function UserProfile () {
  const settings = useSettingsContext();
  const showEditProfile = useBoolean();
  const { useGetItemApi } = useApiServices();

  const { data, isLoading, error } = useGetItemApi<IUserProfileDto>({
    url: API_URLS.ADMIN_PROFILE,
  });

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper isLoading={isLoading} errorMessage={error}>
          <CustomBreadcrumbs
            links={[
              {
                name: "Home",
                href: paths.dashboard.root,
              },
              {
                name: "Profile",
              },
              {
                name: "Personal info",
              },
            ]}
          />
          {data && (
            <Stack spacing={3} mt={7}>
              <LabelWithValue label='Full name' value={data?.fullName} />
              <Divider sx={{ borderStyle: "dashed", width: { md: 568 } }} />
              <LabelWithValue label='Phone number' value={data?.mobilePhone} />
              <Stack mt={2} direction={{ md: "row" }}>
                <AppButton
                  label='Edit profile'
                  sx={{ alignSelf: "center" }}
                  onClick={showEditProfile.onTrue}
                />
              </Stack>
            </Stack>
          )}
        </AppLoadingAndErrorWrapper>
        {showEditProfile && data && (
          <ChangePersonalInfoDialog
            open={showEditProfile.value}
            onClose={showEditProfile.onFalse}
            userData={data}
          />
        )}
      </Container>
    </>
  );
}
