import { Box, Typography } from "@mui/material";
import moment from "moment";

const RowItem = ({ value, name }: { value: string | Date; name: string }) => {
  if (name === "weekDay" && !value) return <> </>;
  const capitalizeWordsWithSlicing = (inputString: string) => {
    const words = inputString.split(/(?=[A-Z])/); // Split on capital letters
    const capitalizedWords = words.map(
      word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join(" ");
  };

  let valueCustomized: any = "";
  if (typeof value === "string")
    valueCustomized = value.slice(0, 1) + value.toLowerCase().slice(1);
  else if (!value) valueCustomized = "";
  else valueCustomized = moment(value).format("YYYY-MM-DD");
  return (
    <Box
      display='flex'
      justifyContent='space-between'
      flexDirection={{ xs: "column", md: "row" }}
      textAlign='end'
    >
      <Typography textAlign='start' fontWeight={500}>
        {capitalizeWordsWithSlicing(name)}
      </Typography>
      <Typography>{valueCustomized || "No information provided"}</Typography>
    </Box>
  );
};
export default RowItem;
