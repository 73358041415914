import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AppButton from "src/components/common/AppButton";
import Iconify from "src/components/iconify";
import { ReturnType } from "src/hooks/use-boolean";
// ----------------------------------------------------------------------

type Props = {
  values: any;
  setSearch: React.Dispatch<React.SetStateAction<any>>;
  setQuery: React.Dispatch<React.SetStateAction<any>>;
  viewAll: ReturnType;
};

export default function CategoryTopicsTableToolbar({
  values,
  setSearch,
  setQuery,
  viewAll,
}: Props) {
  return (
    <Stack
      spacing={2}
      alignItems={{ xs: "flex-end", md: "center" }}
      direction={{
        xs: "column",
        md: "row",
      }}
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        flexGrow={1}
        sx={{ width: 1 }}
      >
        <Box display="flex" gap={2} alignItems="center">
          <TextField value={values} onChange={setSearch} />
          <IconButton onClick={() => setQuery(values)}>
            <Iconify icon="eva:search-fill" sx={{ color: "text.disabled" }} />
          </IconButton>
        </Box>
        <AppButton
          onClick={viewAll.onToggle}
          label={viewAll.value ? "Exit view all" : "View all"}
        />
      </Stack>
    </Stack>
  );
}
