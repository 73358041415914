import { Grid, Skeleton } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query/build/lib/QueryClientProvider";
import { useParams } from "react-router";
import { SortableContainer } from "react-sortable-hoc";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import CategoryTopicsCard from "src/sections/programs/program-categories/CategoryTopicsCard";
import { CategoryTopicsDataType } from "src/types/category-topics";
import { ProgramDetailsDtoType } from "src/types/programs-dropdown";

type CategoryTopicsListPropsType = {
  topics: CategoryTopicsDataType["items"];
  isSorting: boolean;
};

const CategoryTopicsList = SortableContainer<CategoryTopicsListPropsType>(
  ({ topics, isSorting }: CategoryTopicsListPropsType) => {
    const { useGetItemApi } = useApiServices();
    const { programId } = useParams();

    const { data: prgData, isLoading: isPrgLoading } =
      useGetItemApi<ProgramDetailsDtoType>({
        url: API_URLS.PROGRAMS,
        id: programId!,
      });

    const supportedMessageMediums = prgData?.messageMedium.map(
      (mm) => mm.value
    );

    return (
      <Grid container columnSpacing={{ md: 2, xs: 0 }} rowSpacing={2}>
        {topics?.map((programTopic, index) =>
          isSorting || isPrgLoading ? (
            <Grid item xs={12} md={4}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  height: 118,
                  borderRadius: 2,
                }}
              />
            </Grid>
          ) : (
            <CategoryTopicsCard
              index={index}
              key={programTopic.id}
              supportedMessageMediums={supportedMessageMediums!}
              row={programTopic}
            />
          )
        )}
      </Grid>
    );
  }
);

export default CategoryTopicsList;
