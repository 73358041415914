import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import {
  AddProgramTopicFormType,
  AddTopicResDtoType,
  GetTopicDataType,
} from "src/types/category-topics";
import { ProgramDetailsDtoType } from "src/types/programs-dropdown";
import { NewTopicFormSchema, newTopicFormSchema } from "./Add-new-topic-schema";

const AddNewTopic = () => {
  // #region States
  const { programCategoryId, programId } = useParams();
  const confirm = useBoolean();
  const { topicId } = useParams();
  const success = useBoolean();
  const navigate = useNavigate();
  const { usePostApi, useGetListApi, useGetItemApi, usePatchApi } =
    useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");
  // #endregion States

  // #region Form
  const methods = useForm<AddProgramTopicFormType>({
    defaultValues: newTopicFormSchema,
    // TODO resolver
    // resolver: yupResolver(NewTopicFormSchema(2)),
  });
  // #endregion Form

  // #region Services
  const { data: prgData, isLoading: isPrgLoading } =
    useGetItemApi<ProgramDetailsDtoType>({
      url: API_URLS.PROGRAMS,
      id: programId!,
      onSuccess: (data: ProgramDetailsDtoType) => {
        if (!topicId) {
          methods.setValue(
            "translations",
            data.language.map(e => ({
              ...newTopicFormSchema.translations[0],
              locale: e.value,
            }))
          );
        }
        setCurrentTabValue(data.language.map(e => e.value)[0]);
      },
    });

  const hasEmails: boolean = prgData?.messageMedium
    .map(m => m.value)
    .includes("EMAIL_MESSAGE")!;

  const { mutate: addTopic, isLoading: isAdding } =
    usePostApi<AddProgramTopicFormType>({
      url: API_URLS.PROGRAM_TOPICS,
      onSuccess: (data: AddTopicResDtoType) => {
        success.onTrue();
        hasEmails
          ? navigate(
              `/dashboard/programs/${programId}/topics/${programCategoryId}/${data.id}/emails`
            )
          : navigate(
              `/dashboard/programs/${programId}/topics/${programCategoryId}`
            );
      },
    });

  const supportedMessageMediums = prgData?.messageMedium.map(mm => mm.value);
  const supportedTopicLengths = prgData?.topicLength.map(mm => mm.value);

  const indexOfCurrentLocale = (methods.watch().translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);

  const errors = methods.formState.errors.translations!?.[indexOfCurrentLocale];
  // Errors
  const smsErrors =
    errors?.smsMessageLarge ||
    errors?.smsMessageShort ||
    errors?.smsMessageMedium;
  const whatsappErrors =
    errors?.whatsappMessageLarge ||
    errors?.whatsappMessageShort ||
    errors?.whatsappMessageMedium;
  const telegramErrors =
    errors?.telegramMessageLarge ||
    errors?.telegramMessageShort ||
    errors?.telegramMessageMedium;

  const hasErrors = !!Object.keys(methods.formState.errors).length;

  const {
    data: topicData,
    isLoading: isItemLoading,
    error,
    isRefetching,
  } = useGetItemApi({
    url: API_URLS.PROGRAM_TOPICS,
    id: topicId!,
    onSuccess: (data: GetTopicDataType) => {
      if (topicId) {
        methods.setValue(
          `translations`,
          data.translations.filter(t =>
            prgData?.language.map(lang => lang.value).includes(t.locale)
          )
        );
        methods.setValue("programCategoryId", data.programCategoryId);
      }
    },
    enabled: !!prgData,
  });

  const { mutate: editTopic, isLoading: isEditing } =
    usePatchApi<AddProgramTopicFormType>({
      url: API_URLS.PROGRAM_TOPICS,
      onSuccess: (data: any) => {
        confirm.onFalse();
      },
      id: topicId!,
    });

  const mutate = topicId ? editTopic : addTopic;
  // #endregion Services

  // #region Handlers
  const onSubmit = useCallback(
    async (data: AddProgramTopicFormType) => {
      data.translations.forEach((t: any) => {
        delete t.emailMessageShort;
        delete t.emailDesignShort;
        delete t.emailMessageMedium;
        delete t.emailDesignMedium;
        delete t.emailMessageLarge;
        delete t.emailDesignLarge;
      });
      mutate(data);
    },
    [mutate]
  );

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(
      `translations.${+indexOfCurrentLocale!}.locale`,
      currentTabValue
    );
    for (const [key, value] of Object.entries(
      methods.watch().translations!?.[+indexOfCurrentLocale!]
    )) {
      methods.setValue(
        `translations.${+indexOfCurrentLocale!}.${key as "locale"}`,
        value || ""
      );
    }
  }, [topicData, methods, indexOfCurrentLocale, currentTabValue, prgData]);

  useEffect(() => {
    methods.setValue("programCategoryId", +programCategoryId!);
  }, [methods, programCategoryId]);
  // #endregion useEffect

  return (
    <Container>
      <AppLoadingAndErrorWrapper
        isLoading={isItemLoading || isRefetching || isPrgLoading}
        errorMessage={error}
      >
        <FormProvider
          methods={methods}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Tabs
                value={currentTabValue}
                onChange={handleChangeTab}
                sx={{
                  px: 3,
                  boxShadow: theme =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {prgData?.language!?.map(tab => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={3}>
                <Grid item xs={12}>
                  <Block label='Topic name'>
                    <RHFTextField
                      name={`translations.${+indexOfCurrentLocale!}.name`}
                    />
                  </Block>
                </Grid>
                <Grid item xs={12}>
                  <Block label='Thesis statement'>
                    <RHFTextField
                      name={`translations.${+indexOfCurrentLocale!}.thesisStatment`}
                    />
                  </Block>
                </Grid>
                {/* #region SMS accordion */}
                {supportedMessageMediums?.includes("SMS_MESSAGE") && (
                  <Grid item xs={12}>
                    <Accordion
                      sx={{
                        border: `1px solid ${smsErrors ? "#E17678" : "#ddd"}`,
                        borderRadius: 2,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Typography variant='cardDescription'>
                          SMS Message
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ mt: 1 }}>
                        <Stack spacing={3}>
                          {supportedTopicLengths?.includes("SHORT") && (
                            <Block label='Short SMS'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.smsMessageShort`}
                              />
                            </Block>
                          )}
                          {supportedTopicLengths?.includes("MEDIUM") && (
                            <Block label='Medium SMS'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.smsMessageMedium`}
                              />
                            </Block>
                          )}
                          {supportedTopicLengths?.includes("LARGE") && (
                            <Block label='Long SMS'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.smsMessageLarge`}
                              />
                            </Block>
                          )}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {/* #endregion SMS accordion */}
                {/* #region Whatsapp accordion */}
                {supportedMessageMediums?.includes("WHATSAPP_MESSAGE") && (
                  <Grid item xs={12}>
                    <Accordion
                      sx={{
                        border: `1px solid ${
                          whatsappErrors ? "#E17678" : "#ddd"
                        }`,
                        borderRadius: 2,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Typography variant='cardDescription'>
                          Whatsapp Message
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ mt: 1 }}>
                        <Stack spacing={3}>
                          {supportedTopicLengths?.includes("SHORT") && (
                            <Block label='Short Whatsapp message'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.whatsappMessageShort`}
                              />
                            </Block>
                          )}
                          {supportedTopicLengths?.includes("MEDIUM") && (
                            <Block label='Medium Whatsapp message'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.whatsappMessageMedium`}
                              />
                            </Block>
                          )}
                          {supportedTopicLengths?.includes("LARGE") && (
                            <Block label='Long Whatsapp message'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.whatsappMessageLarge`}
                              />
                            </Block>
                          )}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {/* #endregion Whatsapp accordion */}
                {/* #region Telegram accordion */}
                {supportedMessageMediums?.includes("TELEGRAM_MESSAGE") && (
                  <Grid item xs={12}>
                    <Accordion
                      sx={{
                        border: `1px solid ${
                          telegramErrors ? "#E17678" : "#ddd"
                        }`,
                        borderRadius: 2,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='panel2a-content'
                        id='panel2a-header'
                      >
                        <Typography variant='cardDescription'>
                          Telegram Message
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ mt: 1 }}>
                        <Stack spacing={3}>
                          {supportedTopicLengths?.includes("SHORT") && (
                            <Block label='Short Telegram message'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.telegramMessageShort`}
                              />
                            </Block>
                          )}
                          {supportedTopicLengths?.includes("MEDIUM") && (
                            <Block label='Medium Telegram message'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.telegramMessageMedium`}
                              />
                            </Block>
                          )}
                          {supportedTopicLengths?.includes("LARGE") && (
                            <Block label='Long Telegram message'>
                              <RHFTextField
                                name={`translations.${+indexOfCurrentLocale!}.telegramMessageLarge`}
                              />
                            </Block>
                          )}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}
                {/* #endregion Telegram accordion */}
                {hasErrors && <AddDialogsHelperText />}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <AppLoadingButton
                label={`${topicId ? "Edit" : "Add"}`}
                fullWidth
                isLoading={isAdding || isEditing}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </AppLoadingAndErrorWrapper>
      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value && !success.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
          }
        }}
        content={`Are you sure you want to ${
          !topicId ? "add" : "edit"
        } this topic?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
              }}
            />
            <AppLoadingButton label='Yes' isLoading={isAdding || isEditing} />
          </>
        }
      />
      {/* Success */}
      <ConfirmDialog
        open={success.value && confirm.value}
        onClose={() => {}}
        close={() => {
          confirm.onFalse();
          success.onFalse();
        }}
        content='You have added a new topic successfully'
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </Container>
  );
};

export default AddNewTopic;
