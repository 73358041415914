import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { alpha, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import { NewPrgCtgSchema } from "src/schemas";
import { AddFeatureDialogPropsType } from "src/types/feature-dialog";
import {
  AddCategoryFormType,
  GetProgramCategoryDataType,
} from "src/types/program-categories";

const AddProgramCategoryDialog = ({
  open,
  onClose,
  dialogTitle,
  id,
}: AddFeatureDialogPropsType) => {
  // #region States
  const isEditing = !(id === null || id === undefined);
  const { programId } = useParams();
  const confirm = useBoolean();
  const success = useBoolean();
  const [dataToSend, setDataToSend] = useState<AddCategoryFormType>();
  const { usePostApi, useGetListApi, usePatchApi, useGetItemApi } =
    useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");
  // #endregion States
  // #region services
  const {
    mutate: add,
    isLoading,
    error,
  } = usePostApi<AddCategoryFormType>({
    url: API_URLS.PROGRAM_CATEGORIES,
    onSuccess: () => {
      success.onTrue();
      onClose();
      methods.reset();
    },
  });

  const { data: supportedLanguages } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.LANGUAGE_TYPE,
    onSuccess: (data: ResDataType["dropDowns"]) => {
      methods.setValue(
        "translations",
        data.map(e => ({
          name: "",
          locale: e.value,
        }))
      );
      setCurrentTabValue(data.map(e => e.value)[0]);
    },
  });

  const {
    refetch,
    isRefetching,
    isLoading: isItemLoading,
  } = useGetItemApi<GetProgramCategoryDataType>({
    url: API_URLS.PROGRAM_CATEGORIES,
    id: id!,
    onSuccess: (data: GetProgramCategoryDataType) => {
      if (id) {
        methods.setValue("translations", data.translations);
      }
    },
  });

  const {
    mutate: edit,
    isLoading: isEditLoading,
    error: isEditError,
  } = usePatchApi<AddCategoryFormType>({
    url: API_URLS.PROGRAM_CATEGORIES,
    id: id!,
    onSuccess: () => {
      success.onTrue();
      onClose();
    },
  });

  const mutate = isEditing ? edit : add;
  // #endregion services

  // #region handlers
  const handleChangeTab = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion handlers

  // #region Form
  const methods = useForm<AddCategoryFormType>({
    defaultValues: {
      programId: +programId!,
      translations: [
        {
          name: "",
          locale: "",
        },
      ],
    },
    resolver: yupResolver(NewPrgCtgSchema(supportedLanguages?.length!)),
  });

  const { handleSubmit } = methods;
  const hasErrors = Object.keys(methods.formState.errors).length;

  const indexOfCurrentLocale = (methods.watch()?.translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);
  // #endregion Form

  // #region useEffect
  // Set the locale value when the user selects a new tab
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue,
      name:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.name! || "",
    });
  }, [methods, indexOfCurrentLocale, currentTabValue]);

  // Upload photo to the server when it's selected
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // #endregion useEffect

  const onSubmit = useCallback(
    async (data: AddCategoryFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <AppLoadingAndErrorWrapper
              isLoading={
                isLoading || isEditLoading || isItemLoading || isRefetching
              }
              errorMessage={error || isEditError}
              sx={{ height: "auto" }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      textAlign='center'
                      fontSize={24}
                      fontWeight={700}
                    >
                      {dialogTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Tabs
                      value={currentTabValue}
                      onChange={handleChangeTab}
                      sx={{
                        px: 3,
                        boxShadow: theme =>
                          `inset 0 -2px 0 0 ${alpha(
                            theme.palette.grey[500],
                            0.08
                          )}`,
                      }}
                    >
                      {supportedLanguages!?.map(tab => (
                        <Tab
                          key={tab.value}
                          value={tab.value}
                          label={tab.label}
                        />
                      ))}
                    </Tabs>
                    <RHFTextField
                      name={`translations.${+indexOfCurrentLocale!}.name`}
                      sx={{ mt: 3 }}
                    />
                  </Grid>
                  {hasErrors ? <AddDialogsHelperText /> : <></>}
                  <Grid item xs={6}>
                    <AppButton
                      label='Cancel'
                      color='secondary'
                      onClick={onClose}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton
                      label={isEditing ? "Edit" : "Add"}
                      isLoading={isLoading || isEditLoading}
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value && !success.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={`Are you sure you want to ${isEditing ? "edit" : "add"} this ${
          !isEditing ? "new" : ""
        } category?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading || isEditLoading}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />

      {/* Success */}
      <ConfirmDialog
        open={success.value && confirm.value}
        onClose={onClose}
        close={() => {
          confirm.onFalse();
          success.onFalse();
        }}
        content={`You have ${
          !isEditing ? "added a new" : "edited this"
        } category successfully`}
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </>
  );
};

export default AddProgramCategoryDialog;
