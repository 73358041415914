import moment from "moment";
import { ResDataType } from "src/api/apiUrls";
import * as Yup from "yup";

export const SUBSCRIPTION_DETAILS_LIST = (
  userData: ResDataType["userProfile"]
) => [
  {
    label: "First name",
    name: "firstName",
    value: userData?.firstName,
    type: "text",
  },
  {
    label: "Last name",
    name: "lastName",
    value: userData?.lastName,
    type: "dropdown",
    options: "GENDER_TYPE",
  },
  {
    label: "Email",
    name: "email",
    value: userData?.email,
    type: "dropdown",
    options: "ISLAMIC_STATUS_TYPE",
  },
  {
    label: "Phone number",
    name: "mobilePhone",
    value: userData?.mobilePhone,
    type: "text",
  },
  {
    label: "Subscription date",
    name: "createdAt",
    value: moment(userData?.createdAt).format("YYYY-MM-DD"),
    type: "date",
  },
];

export const subscriptionDetailsDefaultValues = (
  userData: ResDataType["userProfile"]
) => ({
  firstName: userData?.firstName,
  lastName: userData?.lastName,
  email: userData?.email,
  mobilePhone: userData?.mobilePhone,
  createdAt: moment(userData?.createdAt).toDate(),
});

export const subscriptionDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  email: Yup.string().email().required("This field is required"),
  mobilePhone: Yup.string().required("This field is required"),
  createdAt: Yup.date().required("This field is required"),
});