import { useContext, useState, useEffect } from "react";
import PermissionContext from "./PermissionContext";
import { Permission } from "src/types/permission-context-type";

const usePermission = (
  permission: Permission | Permission[],
  andSchema = false
) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState<boolean | undefined>(undefined); // Initialize with undefined

  const { isAllowedTo } = useContext(PermissionContext);

  useEffect(() => {
    let isMounted = true;

    const checkPermissions = async () => {
      if (Array.isArray(permission) && andSchema) {
        // Permission is an array of strings
        try {
          const results = await Promise.all(
            permission.map((p) => isAllowedTo(p))
          );
          const isAllowed = results.every((result) => result);
          if (isMounted) {
            setLoading(false);
            setAllowed(isAllowed);
          }
        } catch (error) {
          if (isMounted) {
            setLoading(false);
            setAllowed(false);
          }
        }
      } else if (Array.isArray(permission)) {
        try {
          const results = await Promise.all(
            permission.map((p) => isAllowedTo(p))
          );
          const isAllowed = results.some((result) => result);
          if (isMounted) {
            setLoading(false);
            setAllowed(isAllowed);
          }
        } catch (error) {
          if (isMounted) {
            setLoading(false);
            setAllowed(false);
          }
        }
      } else {
        // Permission is a single string
        try {
          const result = await isAllowedTo(permission);
          if (isMounted) {
            setLoading(false);
            setAllowed(result);
          }
        } catch (error) {
          if (isMounted) {
            setLoading(false);
            setAllowed(false);
          }
        }
      }
    };

    checkPermissions();

    return () => {
      isMounted = false; // Ensure we don't update the state of an unmounted component
    };
  }, [permission, isAllowedTo]);

  return [loading, allowed];
};

export default usePermission;
