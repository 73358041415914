import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { alpha, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import { LinksFormSchema } from "src/schemas";
import {
  AdminSettingsDtoType,
  EditSettingType,
} from "src/types/admin-settings";
import {
  AddBeneficiaryDialogPropsType as editSMLinkPropType,
  AddBeneficiaryFormType,
  GetProgramBeneficiaryDataType,
} from "src/types/program-beneficiaries";

const EditSMLinkDialog = ({
  open,
  onClose,
  dialogTitle,
  id,
}: editSMLinkPropType) => {
  // #region States
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<EditSettingType>();
  const { useGetItemApi, usePatchApi } = useApiServices();
  // #endregion States
  // #region Form
  const methods = useForm<EditSettingType>({
    defaultValues: {
      value: "",
    },
    resolver: yupResolver(LinksFormSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (data: EditSettingType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );
  // #endregion Form

  // #region Services
  const { refetch, isRefetching, error } = useGetItemApi({
    url: API_URLS.SM_LINKS_SETTINGS,
    id: id!,
    onSuccess: (data: AdminSettingsDtoType) => {
      if (id) {
        methods.setValue(`value`, data.value);
      }
    },
  });

  const { mutate: editSMLink, isLoading: isEditLoading } =
    usePatchApi<EditSettingType>({
      url: API_URLS.SM_LINKS_SETTINGS,
      id: id!,
      onSuccess: () => {
        confirm.onFalse();
        onClose();
      },
    });
  // #endregion Services

  // #region useEffect
  useEffect(() => {
    methods.setValue(`value`, methods.watch().value);
  }, [methods]);

  useEffect(() => {
    open && refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // #endregion useEffect

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <AppLoadingAndErrorWrapper
              isLoading={isRefetching || isEditLoading}
              errorMessage={error}
              sx={{ height: "auto" }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      textAlign='center'
                      fontSize={24}
                      fontWeight={700}
                    >
                      {dialogTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Block sx={{ py: 2 }} label='Value'>
                      <RHFTextField name='value' />
                    </Block>
                  </Grid>
                  <Grid item xs={6}>
                    <AppButton
                      label='Cancel'
                      color='secondary'
                      onClick={onClose}
                      fullWidth
                      sx={{ height: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton label='Edit' isLoading={isEditLoading} />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={`Are you sure you want to edit this link?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isEditLoading}
              onClick={() => editSMLink(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default EditSMLinkDialog;
