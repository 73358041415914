import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha, Container, Grid, Stack, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import Editor from "src/components/editor/editor";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import {
  EditWebsitePageFormType,
  GetOneWebsitePageDtoType,
} from "src/types/website-pages";
import { EditLegalSchema, editLegalSchema } from "./edit-legals";

const EditLegal = () => {
  // #region States
  const { legalId } = useParams();
  const confirm = useBoolean();
  const { blogId } = useParams();
  const [dataToSend, setDataToSend] = useState<EditWebsitePageFormType>();
  const { useGetListApi, useGetItemApi, usePatchApi } = useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");
  // #endregion States

  // #region Services
  const { data: supportedLanguages, isLoading: isLanguagesLoading } =
    useGetListApi<ResDataType["dropDowns"]>({
      url: API_URLS.LANGUAGE_TYPE,
      onSuccess: (data: ResDataType["dropDowns"]) => {
        methods.setValue(
          "translations",
          data.map(e => ({
            locale: e.value,
            value: "",
          }))
        );
        setCurrentTabValue(data.map(e => e.value)[0]);
      },
    });

  const { data: pageData, isLoading: isLegalLoading, isRefetching: isLegalRefetching } =
    useGetItemApi<GetOneWebsitePageDtoType>({
      url: API_URLS.WEBSITE_PAGES,
      id: legalId!,
      onSuccess: (data: GetOneWebsitePageDtoType) => {
        if (legalId) {
          methods.setValue(`translations`, data.translations);
        }
      },
    });

  const { mutate: editLegal, isLoading: isEditing } =
    usePatchApi<EditWebsitePageFormType>({
      url: API_URLS.WEBSITE_PAGES,
      onSuccess: (data: any) => {
        confirm.onFalse();
      },
      id: legalId!,
    });
  // #endregion Services

  // #region form
  const methods = useForm<EditWebsitePageFormType>({
    defaultValues: editLegalSchema,
    resolver: yupResolver(EditLegalSchema(supportedLanguages?.length!)),
  });

  const {
    formState: { errors },
  } = methods;

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (data: EditWebsitePageFormType) => {
      confirm.onTrue();
      setDataToSend(data);
    },
    [confirm]
  );

  const indexOfCurrentLocale = (methods.watch().translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);
  // #endregion form

  // #region handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue,
      value:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.value! || "",
    });
  }, [pageData, methods, indexOfCurrentLocale, currentTabValue]);
  // #endregion useEffect

  return (
    <>
      <Helmet>
        <title>Edit legal</title>
      </Helmet>
      <Container>
        <AppLoadingAndErrorWrapper
          isLoading={isLanguagesLoading || isLegalLoading || isLegalRefetching}
          sx={{ height: "auto" }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Tabs
                  value={currentTabValue}
                  onChange={handleChangeTab}
                  sx={{
                    px: 3,
                    boxShadow: theme =>
                      `inset 0 -2px 0 0 ${alpha(
                        theme.palette.grey[500],
                        0.08
                      )}`,
                  }}
                >
                  {supportedLanguages!?.map(tab => (
                    <Tab key={tab.value} value={tab.value} label={tab.label} />
                  ))}
                </Tabs>
                <Stack py={5} spacing={3}>
                  <Block label='Create post section'>
                    <Controller
                      name={`translations.${+indexOfCurrentLocale!}.value`}
                      render={({ field, fieldState }) => (
                        <Editor
                          error={
                            !!errors.translations!?.[indexOfCurrentLocale]
                              ?.value
                          }
                          value={field.value || null}
                          onChange={field.onChange}
                          id='full-editor'
                        />
                      )}
                    />
                  </Block>
                </Stack>
              </Grid>
              {Object.keys(methods.formState.errors).length ? (
                <AddDialogsHelperText />
              ) : (
                <></>
              )}
              <Grid item xs={6}>
                <AppLoadingButton label='Save' isLoading={isEditing} />
              </Grid>
            </Grid>
          </FormProvider>
          {/* Confirm */}
          <ConfirmDialog
            open={confirm.value}
            onClose={(
              event: React.SyntheticEvent<Element, Event>,
              reason: string
            ) => {
              if (reason && reason === "backdropClick") {
                confirm.onFalse();
              }
            }}
            content={`Are you sure you want to edit this legal?`}
            icon={
              <BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />
            }
            action={
              <>
                <AppButton
                  fullWidth
                  label='No'
                  color='secondary'
                  onClick={() => {
                    confirm.onFalse();
                  }}
                />
                <AppLoadingButton
                  label='Yes'
                  isLoading={isEditing}
                  onClick={() => editLegal(dataToSend!)}
                />
              </>
            }
          />
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default EditLegal;
