import * as Yup from "yup";

export const editLegalSchema = {
  translations: [
    {
      value: "",
      locale: "",
    },
  ],
};

export const EditLegalSchema = (arrayLength: number) =>
  Yup.object().shape({
    translations: Yup.array()
      .required()
      .min(arrayLength)
      .of(
        Yup.object().shape({
          value: Yup.string()
            .required()
            .test(
              "",
              "Value cannot be empty",
              (value) => value !== "<p><br></p>"
            ),
          locale: Yup.string().required(),
        })
      ),
  });
