import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Box, Grid, Typography, Dialog, DialogContent } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider from "src/components/hook-form";
import { useBoolean } from "src/hooks/use-boolean";
import {
  subscriptionDetailsDefaultValues,
  subscriptionDetailsSchema,
} from "../../subscription-details/subscription-details-list";
import {
  personalPreferencesDefaultValues,
  personalPreferencesSchema,
} from "../PreferencesLists";
import PersonalPreferencesForm from "./PersonalPreferencesForm";
import SubscriptionDetailsForm from "./SubscriptionDetailsForm";

type changeType = {
  name: "subscriptionDetails" | "personalPreferences";
  label?: string;
  value?: string;
  options?: string;
  type?: string;
} | null;

interface IChangePreferencesDialogStatus {
  open: boolean;
  onClose: VoidFunction;
  change: changeType;
  userData: any;
  dialogTitle: string;
}

const ChangePreferencesDialog = (props: IChangePreferencesDialogStatus) => {
  const { open, onClose, change, userData, dialogTitle } = props;
  const { usePatchApi } = useApiServices();
  const { userId } = useParams();
  const confirm = useBoolean();
  const success = useBoolean();
  const [dataToSend, setDataToSend] = useState<any>();

  const { mutate, isLoading } = usePatchApi({
    url: API_URLS.USER_PROFILE,
    id: userId!,
    withSuccessNotistack: false,
    onSuccess: () => {
      success.onTrue();
    },
  });

  const customResolver =
    change?.name === "personalPreferences"
      ? personalPreferencesSchema
      : subscriptionDetailsSchema;

  const methods = useForm({
    defaultValues: {
      ...personalPreferencesDefaultValues(userData!),
      ...subscriptionDetailsDefaultValues(userData!),
    },
    resolver: yupResolver(customResolver as any),
  });

  const onSubmit = useCallback(
    async (data: any) => {
      data.dateOfBirth = moment(data.dateOfBirth).toDate();
      if (!(data.messageFrequency === "weekly")) delete data.weekDay;
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const handleMutate = (dataToMutate: any) => {
    dataToMutate.messageTime = moment(dataToMutate.messageTime).format(
      "HH:mm:ssZZ"
    );
    mutate(dataToSend);
  };

  useEffect(() => {
    userData &&
      methods.reset({
        ...personalPreferencesDefaultValues(userData!),
        ...subscriptionDetailsDefaultValues(userData!),
      });
  }, [userData, open]);

  return (
    <>
      <Dialog
        open={open && !confirm?.value}
        onClose={onClose}
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <FormProvider
              methods={methods}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography textAlign='center' fontSize={24} fontWeight={700}>
                    {dialogTitle}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  sx={{ display: "flex", justifyContent: "center", mb: 2 }}
                >
                  {
                    // eslint-disable-next-line no-nested-ternary
                    change?.name === "personalPreferences" ? (
                      <PersonalPreferencesForm />
                    ) : change?.name === "subscriptionDetails" ? (
                      <SubscriptionDetailsForm />
                    ) : null
                  }
                  <Grid item xs={6}>
                    <AppButton
                      label='Cancel'
                      color='secondary'
                      onClick={onClose}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton label='Change' isLoading={isLoading} />
                  </Grid>
                </Grid>
              </Grid>
            </FormProvider>
          </Box>
        </DialogContent>
      </Dialog>

      <ConfirmDialog
        open={confirm.value && !success.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={`Are you sure you want to change this user ${
          change?.name === "subscriptionDetails" ? "details" : "preferences"
        }?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading}
              onClick={() => handleMutate(dataToSend)}
            />
          </>
        }
      />

      <ConfirmDialog
        open={success?.value && confirm?.value}
        onClose={onClose}
        close={() => {
          success.onFalse();
          confirm.onFalse();
          onClose();
        }}
        content={"Congratulations! user's preferences was edited successfully"}
        icon={<CheckCircleOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
      />
    </>
  );
};

export default ChangePreferencesDialog;
