import { Icon } from "@iconify/react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AppButton from "src/components/common/AppButton";

const EmptyNotifications = () => {
  return (
    <Grid
      item
      container
      gap={3}
      direction='column'
      justifyContent='center'
      alignItems='center'
      mt={10}
    >
      <Icon icon='charm:image' fontSize='80px' />
      <Typography
        fontSize={32}
        fontWeight={600}
        width={{ md: 564 }}
        align='center'
      >
        You don’t have any notification until now.
      </Typography>
    </Grid>
  );
};

export default EmptyNotifications;
