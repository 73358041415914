import { Container, Grid, Stack, Tab, Tabs } from "@mui/material";
import { alpha } from "@mui/material/styles";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/custom-breadcrumbs";
import Header from "src/components/Header";
import { useSettingsContext } from "src/components/settings";
import StatisticCard from "src/components/StatisticCard";
import usePermission from "src/PermissionProvider/usePermission";
import { paths } from "src/routes/paths";
import { BlogsCountDtoType } from "src/types/blogs-count";
import { PrmsEnum } from "src/types/permissions";
import Blogs from "./blogs/Blogs";
import BlogsCategories from "./categories/BlogsCategories";

const BlogsAndCategoriesPagination = () => {
  const settings = useSettingsContext();
  const { useGetListApi } = useApiServices();
  const {
    data: blogsStatistics,
    isLoading,
    error,
  } = useGetListApi<BlogsCountDtoType>({
    url: API_URLS.BLOGS_STATISTICS,
  });

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const [currentTabValue, setCurrentTabValue] = useState("blogs");

  const TABS = [
    {
      value: "blogs",
      label: "Blogs",
      element: <Blogs />,
      permission: usePermission(PrmsEnum.SHOW_BLOG)[1],
    },
    {
      value: "categories",
      label: "Categories",
      element: <BlogsCategories />,
      permission: usePermission(PrmsEnum.SHOW_BLOG_CATEGORY)[1],
    },
  ];

  const RenderedElement = TABS.filter(tab => tab.value === currentTabValue)[0]
    .element;

  const CurrentTabLabel = TABS.filter(tab => tab.value === currentTabValue)[0]
    .label;

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Stack spacing={4}>
        <CustomBreadcrumbs
          links={[
            {
              name: "Home",
              href: paths.dashboard.root,
            },
            {
              name: CurrentTabLabel,
            },
          ]}
        />
        <AppLoadingAndErrorWrapper errorMessage={error} isLoading={isLoading}>
          <Container
            maxWidth={settings.themeStretch ? false : "xl"}
            sx={{ height: "100%" }}
          >
            <Stack spacing={4}>
              <Header header={`Today, ${moment().format("YYYY-MM-DD")}`} />
              <Grid container spacing={2}>
                {blogsStatistics?.statistics.map((blog, index) => (
                  <Grid item xs={12} md={3}>
                    <StatisticCard
                      cardCount={
                        isNaN(+blog.value)
                          ? moment(blog.value).format("YYYY-MM-DD")
                          : blog.value
                      }
                      cardTitle={blog.label}
                      index={index}
                    />
                  </Grid>
                ))}
              </Grid>
              <Tabs
                value={currentTabValue}
                onChange={handleChangeTab}
                sx={{
                  px: 3,
                  boxShadow: theme =>
                    `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {TABS.map(
                  tab =>
                    tab.permission && (
                      <Tab
                        key={tab.value}
                        value={tab.value}
                        label={tab.label}
                      />
                    )
                )}
              </Tabs>
              {RenderedElement}
            </Stack>
          </Container>
        </AppLoadingAndErrorWrapper>
      </Stack>
    </>
  );
};

export default BlogsAndCategoriesPagination;
