import { AddBlogFormType } from "src/types/blogs";
import * as Yup from "yup";

export const newBlogFormSchema: AddBlogFormType = {
  status: "DRAFT",
  postUrl: "",
  blogCategoryId: 0,
  featureImage: "",
  translations: [
    {
      title: "",
      description: "",
      posts: [],
      locale: "",
    },
  ],
};

export const NewBlogFormSchema = (arrayLength: number) =>
  Yup.object().shape({
    status: Yup.string().required("Status is required"),
    postUrl: Yup.string().required("Post URL is required"),
    blogCategoryId: Yup.number().required("Blog category is required"),
    featureImage: Yup.string().required("Blog image is required"),
    translations: Yup.array()
      .required()
      .min(arrayLength)
      .of(
        Yup.object().shape({
          title: Yup.string().required("Blog title is required"),
          description: Yup.string().required("Description is required"),
          posts: Yup.array()
            .required("Post section cannot be empty")
            .of(
              Yup.string()
                .required()
                .test(
                  "",
                  "Answer cannot be empty",
                  (value) => value !== "<p><br></p>"
                )
            ),
          locale: Yup.string().required("Locale is required"),
        })
      ),
  });
