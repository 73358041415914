import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { paths } from "src/routes/paths";

export const axiosApi: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_HOST_API, // Base URL
});

axiosApi.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  console.log("Request configs", config);
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosApi.interceptors.response.use(
  (response: AxiosResponse) => {
    console.log("Request successfully completed");
    console.log("Response data", response.data.data);
    return response.data.data;
  },
  (error: AxiosError) => {
    // If token is expired
    console.log("An error occurred");
    console.log(error);
    if (error.response?.status === 403) {
      localStorage.removeItem("accessToken");
      window.location.href = paths.auth.jwt.login;
    }
    // Handle the error response
    console.log("Error:", error.response?.data);
    return Promise.reject(error.response?.data);
  }
);
