import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { API_URLS } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { ChangePersonalInfoSchema } from "src/schemas";
import { IChangeUserProfileDto, IUserProfileDto } from "src/types/user";
import PhoneNumberInputField from "../PhoneNumberInputField";

interface IChangePersonalInfoProps {
  open: boolean;
  onClose: VoidFunction;
  userData: IUserProfileDto;
}

const ChangePersonalInfoDialog = ({
  open,
  onClose,
  userData,
}: IChangePersonalInfoProps) => {
  const { usePostApi } = useApiServices();

  const methods = useForm<IChangeUserProfileDto>({
    resolver: yupResolver(ChangePersonalInfoSchema),
    defaultValues: {
      fullName: userData?.fullName,
      mobilePhone: userData?.mobilePhone,
    },
  });

  const { handleSubmit } = methods;

  const { mutate, isLoading } = usePostApi({
    url: API_URLS.ADMIN_PROFILE,
    onSuccess: onClose,
  });

  const onSubmit = useCallback(
    async (data: IChangeUserProfileDto) => {
      mutate(data);
    },
    [mutate]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{ sx: { borderRadius: "16px" } }}
    >
      <DialogContent>
        <Box p={{ md: 8, xs: 3 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontSize={28} fontWeight={500} align='center'>
                  Edit Personal information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Block label='Full name'>
                  <RHFTextField name='fullName' label='Enter your full name' />
                </Block>
              </Grid>
              <Grid item xs={12}>
                <PhoneNumberInputField name='mobilePhone' />
              </Grid>
              <Grid item xs={6}>
                <AppButton
                  label='Cancel'
                  color='secondary'
                  onClick={onClose}
                  sx={{ height: "100%" }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <AppLoadingButton label='Save' isLoading={isLoading} />
              </Grid>
            </Grid>
          </FormProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePersonalInfoDialog;
