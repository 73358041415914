import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface IProgramCircularProgress {
  progressValue: number;
  progressLabel: string;
  percentageIcon?: string;
  programName?: string;
  withPreference?: boolean;
}

const ProgramCircularProgress: React.FC<IProgramCircularProgress> = (props) => {
  const {
    progressValue,
    progressLabel,
    percentageIcon,
    programName,
    withPreference = true,
  } = props;

  return (
    <>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={progressValue}
          size="217px"
          sx={{ color: '#DDB6FF' }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography fontSize={24} fontWeight={500}>
            {progressLabel}
          </Typography>
          <Typography fontSize={24} fontWeight={600}>
            {progressValue} {percentageIcon && percentageIcon}
          </Typography>
        </Box>
      </Box>
      {withPreference && (
        <Grid item container justifyContent="center" alignItems="center" mt={3}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <SellOutlinedIcon />{' '}
            <Typography fontSize={20} fontWeight={600}>
              {programName}
            </Typography>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default ProgramCircularProgress;
