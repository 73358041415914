import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios/index";
import { serialize } from "object-to-formdata";
import { useSnackbar } from "src/components/snackbar";
import {
  IDeleteParams,
  IGetItemParams,
  IGetListParams,
  IPatchParams,
  IPostParams,
} from "src/types/api";
import { axiosApi } from "./axios";

const useApiServices = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Get list service
  const useGetListApi = <T,>({
    parameters,
    url,
    onSuccess,
    onError,
    enabled = true,
  }: IGetListParams) => {
    return useQuery<T, AxiosError>(
      [url, parameters],
      async () => await axiosApi.get(url, { params: parameters }),
      {
        onSuccess: onSuccess,
        onError: onError,
        enabled,
      }
    );
  };

  // Get item service
  const useGetItemApi = <T,>({
    url,
    id,
    onSuccess,
    onError,
    enabled = true,
  }: IGetItemParams) => {
    return useQuery<T, AxiosError>(
      [url],
      async () => await axiosApi.get(`${url}${id ? `/${id}` : ""}`),
      {
        onSuccess: onSuccess,
        onError: onError,
        enabled,
      }
    );
  };

  // Post service
  const usePostApi = <T,>({
    url,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true,
    withFormData = false,
  }: IPostParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, T>(
      (requestData) => {
        return axiosApi.post(
          url,
          withFormData
            ? serialize(requestData, {}, undefined, "file")
            : requestData
        );
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([urlAfterSuccess ?? url]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          onSuccess && onSuccess(data);
        },
        onError: (error) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error);
        },
      }
    );
  };

  // Patch service
  const usePatchApi = <T,>({
    url,
    id,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true,
  }: IPatchParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, T>(
      (requestData) => axiosApi.patch(`${url}/${id ? id : ""}`, requestData),
      {
        onSuccess: () => {
          queryClient.invalidateQueries([urlAfterSuccess ?? url]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          onSuccess && onSuccess();
        },
        onError: (error) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error);
        },
      }
    );
  };

  // Delete service
  const useDeleteApi = <T,>({
    url,
    urlAfterSuccess,
    onSuccess,
    onError,
    withSuccessNotistack = true,
    withErrorNotistack = true,
  }: IDeleteParams) => {
    const queryClient = useQueryClient();
    return useMutation<T, AxiosError, T>(
      (id) => axiosApi.delete(`${url}/${id}`),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([urlAfterSuccess ?? url]);
          withSuccessNotistack && handleApiSuccesswithNotistack();
          onSuccess && onSuccess(data);
        },
        onError: (error) => {
          withErrorNotistack && handleApiErrorwithNotistack(error);
          onError && onError(error);
        },
      }
    );
  };

  // For show snackbar when api return a error
  const handleApiErrorwithNotistack = (error: AxiosError) => {
    enqueueSnackbar({
      variant: "error",
      message: error?.message,
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
  };

  // For show snackbar when api successfully
  const handleApiSuccesswithNotistack = () => {
    enqueueSnackbar({
      variant: "success",
      message: "Completed successfully",
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
  };

  return {
    useGetListApi,
    useGetItemApi,
    usePostApi,
    usePatchApi,
    useDeleteApi,
  };
};

export default useApiServices;
