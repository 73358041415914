import { Suspense } from "react";
import { Outlet } from "react-router-dom";
// auth
import { AuthGuard } from "src/auth/guard";
// layouts
import DashboardLayout from "src/layouts/dashboard";
// components
import { Container } from "@mui/material";
import { LoadingScreen } from "src/components/loading-screen";
import AddBlog from "src/pages/dashboard/blogs-and-blogs-categories/blogs/AddBlog";
import BlogsAndCategoriesPagination from "src/pages/dashboard/blogs-and-blogs-categories/BlogsAndCategoriesPagination";
import GiftDetails from "src/pages/dashboard/gifts/gift-details/GiftDetails";
import Gifts from "src/pages/dashboard/gifts/Gifts";
import AddFaq from "src/pages/dashboard/pages/faqs/AddFaq";
import PagesPagination from "src/pages/dashboard/pages/PagesPagination";
import Home from "src/pages/dashboard/home/Home";
import UserProfile from "src/pages/dashboard/profile/userProfile";
import AddNewProgram from "src/pages/dashboard/programs/AddNewProgram";
import AddNewTopic from "src/pages/dashboard/programs/categories/AddNewTopic";
import CategoryTopics from "src/pages/dashboard/programs/categories/CategoryTopics";
import ProgramDescription from "src/pages/dashboard/programs/description/ProgramDescription";
import FeaturesAndBeneficiariesPagination from "src/pages/dashboard/programs/features&beneficiaries/FeaturesAndBeneficiariesPagination";
import ProgramUsers from "src/pages/dashboard/programs/ProgramUsers";
import Users from "src/pages/dashboard/users/Users";
import UsersDetails from "src/pages/dashboard/users/users-details/UsersDetails";
import ProgramsPagination from "src/sections/programs/ProgramsPagination";
import ContactUs from "src/pages/dashboard/contact-us/ContactUs";
import LegalContent from "src/pages/dashboard/pages/legal-content/LegalContent";
import BadgesPage from "src/pages/dashboard/badges/BadgesPage";
import VacanciesPage from "src/pages/dashboard/careers/vacancies/VacanciesPage";
import AddVacancy from "src/sections/careers/vacancies/AddVacancy";
import SettingsPagination from "src/pages/dashboard/settings/SettingsPagination";
import ApplicationsPage from "src/pages/dashboard/careers/applications/ApplicationsPage";
import NotificationsView from "src/pages/dashboard/notifications/NotificationsView";
import ApplicationDetails from "src/pages/dashboard/careers/applications/application-details/ApplicationDetails";
import ProgramAnalytics from "src/pages/dashboard/programs/analytics/ProgramAnalytics";
import ContactUsReply from "src/pages/dashboard/contact-us/CotnactUsReply";
import RolesPagination from "src/pages/dashboard/roles-and-permissions/RolesPagination";
import AddNewRole from "src/pages/dashboard/roles-and-permissions/roles/AddNewRole";
import AddNewAdmin from "src/pages/dashboard/roles-and-permissions/admins/AddNewAdmin";
import AddTopicEmails from "src/pages/dashboard/programs/categories/AddTopicEmails";
import EditLegal from "src/sections/pages/legal-content/EditLegal";

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Home />, index: true },
      {
        path: "profile",
        children: [{ element: <UserProfile />, index: true }],
      },
      {
        path: "programs/new",
        element: <AddNewProgram />,
      },
      {
        path: "users",
        children: [
          {
            element: <Users />,
            index: true,
          },
          {
            path: ":userId",
            element: <UsersDetails />,
          },
        ],
      },
      {
        path: "roles-and-permissions",
        children: [
          {
            element: <RolesPagination />,
            index: true,
          },
          {
            path: "roles/new",
            element: <AddNewRole />,
          },
          {
            path: "roles/:roleId",
            element: <AddNewRole />,
          },
          {
            path: "admins/new",
            element: <AddNewAdmin />,
          },
          {
            path: "admins/:adminId",
            element: <AddNewAdmin />,
          },
        ],
      },
      {
        path: "gifts",
        children: [
          {
            element: <Gifts />,
            index: true,
          },
          {
            path: ":giftId",
            element: <GiftDetails />,
            index: true,
          },
        ],
      },
      {
        path: "blogs",
        children: [
          {
            index: true,
            element: <BlogsAndCategoriesPagination />,
          },
          {
            path: "new",
            element: <AddBlog />,
          },
          {
            path: ":blogId",
            element: <AddBlog />,
          },
        ],
      },
      {
        path: "contact-us",
        children: [
          {
            index: true,
            element: <ContactUs />,
          },
          {
            path: ":contactUsId",
            element: <ContactUsReply />,
          },
        ],
      },
      {
        path: "careers",
        children: [
          {
            path: "vacancies",
            children: [
              {
                index: true,
                element: <VacanciesPage />,
              },
              {
                path: "new",
                element: <AddVacancy />,
              },
              {
                path: ":vacancyId",
                element: <AddVacancy />,
              },
            ],
          },
          {
            path: "applicant-tracking",
            children: [
              {
                index: true,
                element: <ApplicationsPage />,
              },
              {
                path: ":applicantId",
                element: <ApplicationDetails />,
              },
            ],
          },
        ],
      },
      {
        path: "pages",
        children: [
          {
            index: true,
            element: <PagesPagination />,
          },
          {
            path: "faqs/new",
            element: <AddFaq />,
          },
          {
            path: "faqs/:faqId",
            element: <AddFaq />,
          },
          {
            path: "legal-content/:legalId",
            element: <EditLegal />,
          },
        ],
      },
      {
        path: "programs",
        element: <ProgramsPagination />,
        children: [
          {
            path: ":programId",
            children: [
              {
                index: true,
                path: "analytics",
                element: <ProgramAnalytics />,
              },
              {
                path: "users",
                element: (
                  <Container>
                    <ProgramUsers />,
                  </Container>
                ),
              },
              {
                path: "featuresAndBeneficiaries",
                element: <FeaturesAndBeneficiariesPagination />,
              },
              {
                path: "description",
                element: <ProgramDescription />,
              },
              {
                path: "topics",
                children: [
                  {
                    element: <CategoryTopics />,
                    index: true,
                  },
                  {
                    path: ":programCategoryId",
                    element: <CategoryTopics />,
                    index: true,
                  },
                  {
                    path: ":programCategoryId/new",
                    element: <AddNewTopic />,
                  },
                  {
                    path: ":programCategoryId/:topicId",
                    element: <AddNewTopic />,
                  },
                  {
                    path: ":programCategoryId/:topicId/emails",
                    element: <AddTopicEmails />,
                  },
                ],
              },
            ],
          },
        ],
      },
      // Badges
      {
        path: "badges",
        element: <BadgesPage />,
      },
      // Settings
      {
        path: "settings",
        element: <SettingsPagination />,
      },
      // Notifications
      {
        path: "notifications",
        element: <NotificationsView />,
      },
    ],
  },
];
