import * as Yup from "yup";

export const newProgramFormSchema = {
  language: [],
  featureImage: "",
  topicLength: [],
  messageMedium: [],
  translations: [
    {
      name: "",
      header: "",
      description: "",
      duration: "",
      locale: "",
    },
  ],
};

export const NewProgramFormSchema = (arrayLength: number) =>
  Yup.object().shape({
    featureImage: Yup.string().required("*"),
    language: Yup.array().required().min(1).of(Yup.string().required()),
    topicLength: Yup.array().required().min(1).of(Yup.string().required()),
    messageMedium: Yup.array().required().min(1).of(Yup.string().required()),
    translations: Yup.array()
      .required()
      .min(arrayLength)
      .of(
        Yup.object().shape({
          name: Yup.string().required("Title is required"),
          header: Yup.string().required("Header is required"),
          description: Yup.string().required("Description is required"),
          duration: Yup.string().required("Duration is required"),
          locale: Yup.string().required(),
        })
      ),
  });
