import * as Yup from 'yup';

// ----------------------------------------------------------------------

export type ChangePasswordFormType = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

export const defaultValues: ChangePasswordFormType = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('This field is required'),
  password: Yup.string().required('This field is required'),
  confirmPassword: Yup.string().required('This field is required  ').oneOf([Yup.ref('password')], 'Must match the new password'),
});
