// @mui
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
// components
import { API_URLS } from "src/api/apiUrls";
import Iconify from "src/components/iconify";
import Image from "src/components/image/image";
import { ASSETS_API } from "src/config-global";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import { IBadgeItem } from "src/types/badges";
import { PrmsEnum } from "src/types/permissions";
import AddProgramBadge from "./AddProgramBadge";

// ----------------------------------------------------------------------

type Props = {
  row: IBadgeItem;
};

export default function ProgramBadgesTableRow ({ row }: Props) {
  const confirm = useBoolean();
  const editDialog = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Image
            alt={"Badge"}
            src={`${ASSETS_API}${row?.featureImage}`}
            sx={{ width: 71 }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          {row?.program?.name}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          {row?.createdAt ? new Date(row?.createdAt)?.toLocaleDateString() : ""}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          {row?.updatedAt ? new Date(row?.updatedAt)?.toLocaleDateString() : ""}
        </TableCell>

        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Restricted to={PrmsEnum.DELETE_BADGE}>
            <Tooltip title='Delete' placement='bottom' arrow>
              <IconButton onClick={confirm.onTrue}>
                <Iconify
                  icon='material-symbols:delete-outline'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
          <Restricted to={PrmsEnum.UPDATE_BADGE}>
            <Tooltip title='edit' placement='bottom' arrow>
              <IconButton onClick={editDialog.onTrue}>
                <Iconify
                  icon='ri:edit-2-line'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
        </TableCell>
      </TableRow>

      {/* Confirm */}
      <DeleteDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        url={API_URLS.BADGES}
        id={row.id}
        name='badge'
      />
      <AddProgramBadge
        open={editDialog.value}
        onClose={editDialog.onFalse}
        id={row.id}
      />
    </>
  );
}
