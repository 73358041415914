import { Card, Grid, Typography } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import { useSettingsContext } from "src/components/settings";
import { IBaseStatusDto } from "src/types";

export default function DetailsCard ({
  items,
  cardTitle,
}: {
  items: IBaseStatusDto[];
  cardTitle: string;
}) {
  const settings = useSettingsContext();

  return (
    <Container maxWidth={settings.themeStretch ? false : "xl"} sx={{height: '100%'}}>
      <Card sx={{ p: 3, height: '100%' }}>
        <CardHeader title={cardTitle} sx={{px: 0}} />
        <Grid container spacing={4} my={1}>
          {items.map(e => (
            <Grid item xs={12} display='flex' justifyContent='space-between'>
              <Typography>{e.label}</Typography>
              <Typography>{e.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Card>
    </Container>
  );
}
