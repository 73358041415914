// @mui
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Image from "src/components/image/image";
// components
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { Link } from "react-router-dom";
import { API_URLS } from "src/api/apiUrls";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import Iconify from "src/components/iconify/iconify";
import { ASSETS_API } from "src/config-global";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import { GiftType } from "src/types/gifts";
import { PrmsEnum } from "src/types/permissions";

// ----------------------------------------------------------------------

type Props = {
  row: GiftType;
};

export default function GiftsTableRow ({ row }: Props) {
  const dialog = useBoolean();
  const confirm = useBoolean();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Image src={`${ASSETS_API}${row.cardImage}`} width='50%' />
        </TableCell>
        <TableCell sx={{ whiteSpace: "wrap", textAlign: "center" }}>
          <Typography>
            {(row.sender.firstName ? row.sender.firstName : "-") +
              (row.sender.lastName ? row.sender.lastName : "-")}
          </Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>
            {(row.recipient.firstName ? row.recipient.firstName : "-") +
              (row.recipient.lastName ? row.recipient.lastName : "-")}
          </Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.program.name}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.messageMedium.label}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>{row.status.label}</Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Typography>
            {moment(row.sendingDate).format("YYYY-MM-DD")}
          </Typography>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
          <Restricted to={PrmsEnum.DELETE_GIFT}>
            <Tooltip title='Delete' placement='bottom' arrow>
              <IconButton onClick={() => confirm.onTrue()}>
                <Iconify
                  icon='material-symbols:delete-outline'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Tooltip>
          </Restricted>
          <Tooltip title='View' placement='bottom' arrow>
            <Link to={`/dashboard/gifts/${row.id}`}>
              <IconButton onClick={dialog.onTrue}>
                <Iconify
                  icon='mdi:eye-circle'
                  width='27'
                  height='27'
                  color='#FFAB8B'
                />
              </IconButton>
            </Link>
          </Tooltip>
        </TableCell>
      </TableRow>
      {/* Confirm */}
      <DeleteDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        name='gift'
        url={API_URLS.GIFTS}
        id={row.id}
      />
    </>
  );
}
