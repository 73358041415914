import * as Yup from "yup";

export const newFaqFormSchema = {
  type: "Program",
  translations: [
    {
      locale: "",
      question: "",
      answer: "",
    },
  ],
};

export const NewFaqFormSchema = (arrayLength: number) =>
  Yup.object().shape({
    type: Yup.string().required("*"),
    translations: Yup.array()
      .required()
      .min(arrayLength)
      .of(
        Yup.object().shape({
          question: Yup.string().required("Question is required"),
          answer: Yup.string()
            .required("Answer is required")
            .test(
              "",
              "Answer cannot be empty",
              (value) => value !== "<p><br></p>"
            ),
          locale: Yup.string().required(),
        })
      ),
  });
