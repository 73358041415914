import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha, Grid, Stack, Tab, Tabs } from "@mui/material";
import Container from "@mui/material/Container";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import { RHFMultiSelect, RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/form-provider";
import { useSettingsContext } from "src/components/settings";
import { Upload } from "src/components/upload";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import { ProgramFormSchema } from "src/schemas";
import { AddProgramFormType, ProgramAdminDSType } from "src/types/program";
import { UploadFileResponseType } from "src/types/upload-file-res";

export default function ProgramDescription () {
  // #region States
  const { programId } = useParams();
  const settings = useSettingsContext();
  const { useGetItemApi, useGetListApi, usePatchApi, usePostApi } =
    useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");
  const [dataToSend, setDataToSend] = useState<AddProgramFormType>();
  const [featureImage, setFeatureImage] = useState<File | string | null>(null);
  const confirm = useBoolean();
  // #endregion States

  // #region Form
  const methods = useForm<AddProgramFormType>({
    defaultValues: {
      featureImage: "",
      language: [],
      translations: [
        {
          locale: "",
          name: "",
          description: "",
          duration: "",
          header: "",
        },
      ],
    },
    resolver: yupResolver(ProgramFormSchema(3)),
  });

  const {
    handleSubmit,
    formState: {
      errors: { featureImage: featureImageError },
    },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddProgramFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.watch()]
  );

  const indexOfCurrentLocale = methods
    .watch()
    .translations?.map(trans => trans.locale)
    .indexOf(currentTabValue!);
  // #endregion Form

  // #region Services
  const {
    data: programData,
    isLoading,
    error,
    isRefetching,
  } = useGetItemApi<ResDataType["programAdminType"]>({
    url: API_URLS.PROGRAMS,
    id: programId,
    onSuccess: (data: ResDataType["programAdminType"]) => {
      methods.setValue("translations", data.translations);
      methods.setValue("featureImage", data.featureImage);
      methods.setValue(
        "language",
        data.language.map(e => e.value)
      );
      methods.setValue(
        "messageMedium",
        data.messageMedium.map(e => e.value)
      );
      methods.setValue(
        "topicLength",
        data.topicLength.map(e => e.value)
      );
      setFeatureImage(data.featureImage);
      // setDataToSend({
      //   language: data.language,
      //   featureImage: data.featureImage,
      //   translations: data.translations
      // })
    },
  });

  const { data: supportedLanguages } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.LANGUAGE_TYPE,
    onSuccess: (data: ResDataType["dropDowns"]) => {
      methods.setValue(
        "translations",
        data.map(e => ({
          name: "",
          description: "",
          duration: "",
          header: "",
          locale: e.value,
        }))
      );
      setCurrentTabValue(data.map(e => e.value)[0]);
    },
  });

  const { data: messageMediums, isLoading: areChannelsLoading } = useGetListApi<
    ResDataType["dropDowns"]
  >({
    url: API_URLS.MESSAGE_MEDIUM,
    onSuccess: (data: ResDataType["dropDowns"]) => {
    },
  });

  const { data: topicLengths, isLoading: areTpcLntLoading } = useGetListApi<
    ResDataType["dropDowns"]
  >({
    url: API_URLS.TOPIC_LENGTH,
    onSuccess: (data: ResDataType["dropDowns"]) => {
    },
  });

  const { mutate, isLoading: isPatching } = usePatchApi<AddProgramFormType>({
    url: API_URLS.PROGRAMS,
    id: programId!,
    onSuccess: () => {
      confirm.onFalse();
    },
  });

  const {
    mutate: uploadPhoto,
    isLoading: isUploadingPhoto,
    error: uploadError,
  } = usePostApi({
    url: API_URLS.UPLOAD_FILE,
    withFormData: true,
    onSuccess: (data: UploadFileResponseType) => {
      methods.setValue("featureImage", data.filePath);
    },
  });
  // #endregion Services

  // #region Handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFeatureImage(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue!,
      description:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.description! ||
        "",
      duration:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.duration! ||
        "",
      name:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.name! || "",
      header:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.header! || "",
    });
  }, [programData, methods, indexOfCurrentLocale, currentTabValue]);

  useEffect(() => {
    if (featureImage && typeof featureImage !== "string") {
      uploadPhoto(featureImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureImage]);
  // #endregion useEffect
  return (
    <>
      <Helmet>
        <title>Description</title>
      </Helmet>
      <Container maxWidth={settings.themeStretch ? false : "xl"}>
        <AppLoadingAndErrorWrapper
          isLoading={isLoading || isRefetching}
          errorMessage={error}
        >
          <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods}>
            <Grid container spacing={4} my={1}>
              <Grid item md={4}>
                <Block label='Choose supported languages'>
                  <RHFMultiSelect
                    checkbox
                    name='language'
                    options={supportedLanguages || []}
                  />
                </Block>
              </Grid>
              <Grid item md={4}>
                <Block label='Choose supported channels'>
                  <RHFMultiSelect
                    checkbox
                    name='messageMedium'
                    options={messageMediums || []}
                  />
                </Block>
              </Grid>
              <Grid item md={4}>
                <Block label='Choose supported topic lengths'>
                  <RHFMultiSelect
                    checkbox
                    name='topicLength'
                    options={topicLengths || []}
                  />
                </Block>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Tabs
                    value={currentTabValue}
                    onChange={handleChangeTab}
                    sx={{
                      px: 3,
                      boxShadow: theme =>
                        `inset 0 -2px 0 0 ${alpha(
                          theme.palette.grey[500],
                          0.08
                        )}`,
                    }}
                  >
                    {supportedLanguages!?.map(tab => (
                      <Tab
                        key={tab.value}
                        value={tab.value}
                        label={tab.label}
                      />
                    ))}
                  </Tabs>
                  <Block label='Upload image'>
                    <AppLoadingAndErrorWrapper
                      isLoading={isUploadingPhoto}
                      errorMessage={uploadError}
                      sx={{ height: "fit-content", py: 2 }}
                    >
                      <Upload
                        file={featureImage}
                        error={!!featureImageError}
                        onDrop={handleDropSingleFile}
                        onDelete={() => {
                          setFeatureImage(null);
                          methods.setValue("featureImage", "");
                        }}
                      />
                    </AppLoadingAndErrorWrapper>
                  </Block>
                  <Block label='Title'>
                    <RHFTextField
                      name={`translations.${indexOfCurrentLocale}.name`}
                    />
                  </Block>
                  <Block label='Header'>
                    <RHFTextField
                      name={`translations.${indexOfCurrentLocale}.header`}
                    />
                  </Block>
                  <Block label='Duration'>
                    <RHFTextField
                      name={`translations.${indexOfCurrentLocale}.duration`}
                    />
                  </Block>
                  <Block label='Description'>
                    <RHFTextField
                      name={`translations.${indexOfCurrentLocale}.description`}
                      multiline
                      rows={12}
                    />
                  </Block>
                  {Object.keys(methods.formState.errors).length ? (
                    <AddDialogsHelperText />
                  ) : (
                    <></>
                  )}
                  <AppLoadingButton
                    label='Save changes'
                    isLoading={isLoading}
                  />
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </AppLoadingAndErrorWrapper>
        <ConfirmDialog
          open={confirm.value}
          onClose={(
            event: React.SyntheticEvent<Element, Event>,
            reason: string
          ) => {
            if (reason && reason === "backdropClick") {
              confirm.onFalse();
            }
          }}
          content='Do you want to save changes?'
          icon={
            <BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />
          }
          action={
            <>
              <AppButton
                fullWidth
                label='No'
                color='secondary'
                onClick={() => {
                  confirm.onFalse();
                }}
              />
              <AppLoadingButton
                label='Yes'
                isLoading={isPatching}
                onClick={() => mutate(dataToSend!)}
              />
            </>
          }
        />
      </Container>
    </>
  );
}
