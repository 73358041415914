import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha, Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import Iconify from "src/components/iconify/iconify";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import { NewBeneficiarySchema } from "src/schemas";
import {
  AddBeneficiaryDialogPropsType,
  AddBeneficiaryFormType,
  GetProgramBeneficiaryDataType,
} from "src/types/program-beneficiaries";

const AddBeneficiaryDialog = ({
  open,
  onClose,
  dialogTitle,
  id,
}: AddBeneficiaryDialogPropsType) => {
  // #region States
  const isEditing = !(id === null || id === undefined);
  const { programId } = useParams();
  const confirm = useBoolean();
  const [dataToSend, setDataToSend] = useState<AddBeneficiaryFormType>();
  const { usePostApi, useGetListApi, useGetItemApi, usePatchApi } =
    useApiServices();
  const [currentTabValue, setCurrentTabValue] = useState("en");
  // #endregion States

  // #region Services
  const {
    mutate: addBeneficiary,
    isLoading,
    error,
  } = usePostApi<AddBeneficiaryFormType>({
    url: API_URLS.PROGRAM_BENEFICIARIES,
    onSuccess: () => {
      onClose();
      confirm.onFalse();
    },
  });

  const { refetch, isRefetching } = useGetItemApi({
    url: API_URLS.PROGRAM_BENEFICIARIES,
    id: id!,
    onSuccess: (data: GetProgramBeneficiaryDataType) => {
      if (id) {
        methods.setValue(`translations`, data.translations);
      }
    },
  });

  const { data: supportedLanguages } = useGetListApi<ResDataType["dropDowns"]>({
    url: API_URLS.LANGUAGE_TYPE,
    onSuccess: (data: ResDataType["dropDowns"]) => {
      methods.setValue(
        "translations",
        data.map(e => ({
          description: "",
          locale: e.value,
        }))
      );
      setCurrentTabValue(data.map(e => e.value)[0]);
    },
  });

  const { mutate: editBeneficiary, isLoading: isEditLoading } =
    usePatchApi<AddBeneficiaryFormType>({
      url: API_URLS.PROGRAM_BENEFICIARIES,
      id: id!,
      onSuccess: () => {
        confirm.onFalse();
        onClose();
      },
    });

  const mutate = isEditing ? editBeneficiary : addBeneficiary;
  // #endregion Services

  // #region Form
  const methods = useForm<AddBeneficiaryFormType>({
    defaultValues: {
      programId: +programId!,
      translations: [
        {
          description: "",
          locale: "",
        },
      ],
    },
    resolver: yupResolver(NewBeneficiarySchema(supportedLanguages?.length!)),
  });

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (data: AddBeneficiaryFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const indexOfCurrentLocale = (methods.watch()?.translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);
  // #endregion Form

  // #region Handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );
  // #endregion Handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue,
      description:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.description! ||
        "",
    });
  }, [methods, indexOfCurrentLocale, currentTabValue]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // #endregion useEffect

  return (
    <>
      <Dialog
        open={open && !confirm.value}
        onClose={onClose}
        fullWidth
        PaperProps={{ sx: { borderRadius: "16px" } }}
      >
        <DialogContent>
          <Box p={{ md: 8, xs: 3 }}>
            <AppLoadingAndErrorWrapper
              isLoading={isLoading || isRefetching || isEditLoading}
              errorMessage={error}
              sx={{ height: "auto" }}
            >
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      textAlign='center'
                      fontSize={24}
                      fontWeight={700}
                    >
                      {dialogTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Tabs
                      value={currentTabValue}
                      onChange={handleChangeTab}
                      sx={{
                        px: 3,
                        boxShadow: theme =>
                          `inset 0 -2px 0 0 ${alpha(
                            theme.palette.grey[500],
                            0.08
                          )}`,
                      }}
                    >
                      {supportedLanguages!?.map(tab => (
                        <Tab
                          key={tab.value}
                          value={tab.value}
                          label={tab.label}
                        />
                      ))}
                    </Tabs>
                    <Block sx={{ py: 2 }} label='Description'>
                      <RHFTextField
                        name={`translations.${indexOfCurrentLocale}.description`}
                      />
                    </Block>
                  </Grid>
                  {Object.keys(methods.formState.errors).length ? (
                    <AddDialogsHelperText />
                  ) : (
                    <></>
                  )}
                  <Grid item xs={6}>
                    <AppButton
                      label='Cancel'
                      color='secondary'
                      onClick={onClose}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <AppLoadingButton
                      label='Add'
                      isLoading={isLoading || isEditLoading}
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </AppLoadingAndErrorWrapper>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Confirm */}
      <ConfirmDialog
        open={confirm.value}
        onClose={(
          event: React.SyntheticEvent<Element, Event>,
          reason: string
        ) => {
          if (reason && reason === "backdropClick") {
            confirm.onFalse();
            onClose();
          }
        }}
        content={`Are you sure you want to ${
          isEditing ? "edit" : "add"
        } this beneficiary?`}
        icon={<BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />}
        action={
          <>
            <AppButton
              fullWidth
              label='No'
              color='secondary'
              onClick={() => {
                confirm.onFalse();
                onClose();
              }}
            />
            <AppLoadingButton
              label='Yes'
              isLoading={isLoading || isEditLoading}
              onClick={() => mutate(dataToSend!)}
            />
          </>
        }
      />
    </>
  );
};

export default AddBeneficiaryDialog;
