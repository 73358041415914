import { yupResolver } from "@hookform/resolvers/yup";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { alpha, Container, Grid, Stack, Tab, Tabs } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import { Block } from "src/components/Block";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import AppLoadingButton from "src/components/common/AppLoadingButton";
import { ConfirmDialog } from "src/components/custom-dialog";
import Editor from "src/components/editor/editor";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { Upload } from "src/components/upload";
import { useBoolean } from "src/hooks/use-boolean";
import AddDialogsHelperText from "src/layouts/_common/AddDialogsHelperText";
import { AddBlogFormType, GetOneBlogDataType } from "src/types/blogs";
import { UploadFileResponseType } from "src/types/upload-file-res";
import { NewBlogFormSchema, newBlogFormSchema } from "./Add-new-blog-schema";

const AddBlog = () => {
  // #region States
  const confirm = useBoolean();
  const { blogId } = useParams();
  const [dataToSend, setDataToSend] = useState<AddBlogFormType>();
  const navigate = useNavigate();
  const { usePostApi, useGetListApi, useGetItemApi, usePatchApi } =
    useApiServices();
  const [featureImage, setFeatureImage] = useState<File | string | null>(null);
  const [currentTabValue, setCurrentTabValue] = useState("en");
  // #endregion States

  // #region Services
  const { data: supportedLanguages, isLoading: isLanguagesLoading } =
    useGetListApi<ResDataType["dropDowns"]>({
      url: API_URLS.LANGUAGE_TYPE,
      onSuccess: (data: ResDataType["dropDowns"]) => {
        methods.setValue(
          "translations",
          data.map(e => ({
            description: "",
            posts: [],
            title: "",
            locale: e.value,
          }))
        );
        setCurrentTabValue(data.map(e => e.value)[0]);
      },
    });

  const { data: categories } = useGetListApi<ResDataType["blogCategories"]>({
    url: API_URLS.BLOGS_CATEGORIES,
    onSuccess (data: ResDataType["blogCategories"]) {
      methods.setValue("blogCategoryId", data.items[0].id);
    },
  });

  const {
    data: blogData,
    isLoading: isBlogLoading,
    isRefetching,
  } = useGetItemApi<GetOneBlogDataType>({
    url: API_URLS.BLOGS,
    id: blogId!,
    onSuccess: (data: GetOneBlogDataType) => {
      if (blogId) {
        methods.setValue(`translations`, data.translations);
        setFeatureImage(data.featureImage);
        methods.setValue(`postUrl`, data.postUrl);
        methods.setValue(`featureImage`, data.featureImage);
      }
    },
  });

  const { mutate: addBlog, isLoading } = usePostApi<AddBlogFormType>({
    url: API_URLS.BLOGS,
    onSuccess: (data: any) => {
      confirm.onFalse();
      navigate("/dashboard/blogs");
    },
  });

  const { mutate: uploadPhoto, isLoading: isUploadingPhoto } = usePostApi({
    url: API_URLS.UPLOAD_FILE,
    withFormData: true,
    onSuccess: (data: UploadFileResponseType) => {
      methods.setValue("featureImage", data.filePath);
    },
  });

  const {
    mutate: editBlog,
    isLoading: isEditing,
    error: patchError,
  } = usePatchApi<AddBlogFormType>({
    url: API_URLS.BLOGS,
    onSuccess: (data: any) => {
      confirm.onFalse();
    },
    id: blogId!,
  });

  const mutate = blogId ? editBlog : addBlog;
  // #endregion Services

  // #region form
  const methods = useForm<AddBlogFormType>({
    defaultValues: newBlogFormSchema,
    resolver: yupResolver(NewBlogFormSchema(supportedLanguages?.length!)),
  });

  const categoriesOptions = categories?.items.map(e => ({
    value: e.id,
    label: e.name,
  }));

  const {
    handleSubmit,
    formState: {
      errors: { featureImage: featureImageError },
    },
  } = methods;

  const onSubmit = useCallback(
    async (data: AddBlogFormType) => {
      setDataToSend(data);
      confirm.onTrue();
    },
    [confirm]
  );

  const indexOfCurrentLocale = (methods.watch().translations || [])
    ?.map(trans => trans.locale)
    .indexOf(currentTabValue);
  // #endregion form

  // #region handlers
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentTabValue(newValue);
    },
    []
  );

  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFeatureImage(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
      methods.clearErrors("featureImage");
    }
  }, []);

  const saveAsDraft = () => {
    methods.setValue("status", "DRAFT");
  };

  const publish = () => {
    methods.setValue("status", "PUBLISHED");
  };
  // #endregion handlers

  // #region useEffect
  useEffect(() => {
    methods.setValue(`translations.${+indexOfCurrentLocale!}`, {
      locale: currentTabValue,
      description:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.description! ||
        "",
      posts: methods.watch().translations!?.[+indexOfCurrentLocale!]?.posts
        ? methods.watch().translations!?.[+indexOfCurrentLocale!]?.posts!
        : ["<p><br/></p>"],
      title:
        methods.watch().translations!?.[+indexOfCurrentLocale!]?.title! || "",
    });
  }, [blogData, methods, indexOfCurrentLocale, currentTabValue]);

  useEffect(() => {
    if (featureImage && typeof featureImage !== "string") {
      uploadPhoto(featureImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureImage]);
  // #endregion useEffect

  return (
    <>
      <Helmet>
        <title>Add blog</title>
      </Helmet>
      <Container>
        <AppLoadingAndErrorWrapper
          isLoading={
            isLanguagesLoading ||
            isUploadingPhoto ||
            isBlogLoading ||
            isRefetching
          }
          sx={{ height: "auto" }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Tabs
                  value={currentTabValue}
                  onChange={handleChangeTab}
                  sx={{
                    px: 3,
                    boxShadow: theme =>
                      `inset 0 -2px 0 0 ${alpha(
                        theme.palette.grey[500],
                        0.08
                      )}`,
                  }}
                >
                  {supportedLanguages!?.map(tab => (
                    <Tab key={tab.value} value={tab.value} label={tab.label} />
                  ))}
                </Tabs>
                <Stack py={5} spacing={3}>
                  <Block label='Upload image'>
                    <Upload
                      file={featureImage}
                      error={!!featureImageError}
                      onDrop={handleDropSingleFile}
                      onDelete={() => {
                        setFeatureImage(null);
                        methods.setValue("featureImage", "");
                      }}
                    />
                  </Block>

                  <Block label='Blog title'>
                    <RHFTextField
                      name={`translations.${+indexOfCurrentLocale!}.title`}
                    />
                  </Block>

                  <Block label='Blog description'>
                    <RHFTextField
                      name={`translations.${+indexOfCurrentLocale!}.description`}
                    />
                  </Block>

                  <Block label='Blog post URL'>
                    <RHFTextField name='postUrl' />
                  </Block>

                  <Block label='Blog category'>
                    <RHFSelect name='blogCategoryId'>
                      {categoriesOptions?.map(category => (
                        <MenuItem key={category.value} value={category.value}>
                          {category.label}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Block>

                  {supportedLanguages?.map(lang => {
                    const indexOfCurrentLocale = (
                      methods.watch().translations || []
                    )
                      ?.map(trans => trans.locale)
                      .indexOf(lang.value);
                    return (
                      lang.value === currentTabValue && (
                        <Block label='Create post section'>
                          <Controller
                            name={`translations.${indexOfCurrentLocale}.posts[0]`}
                            render={({ field, fieldState }) => (
                              <Editor
                                value={field.value}
                                onChange={field.onChange}
                                id={`full-editor-${lang.value}`}
                                error={
                                  !!methods.formState.errors.translations!?.[
                                    indexOfCurrentLocale
                                  ]?.posts
                                }
                              />
                            )}
                          />
                        </Block>
                      )
                    );
                  })}
                </Stack>
              </Grid>
              {Object.keys(methods.formState.errors).length ? (
                <AddDialogsHelperText />
              ) : (
                <></>
              )}
              <Grid item xs={6}>
                <AppLoadingButton
                  label='Save as draft'
                  isLoading={false}
                  onClick={saveAsDraft}
                />
              </Grid>
              <Grid item xs={6}>
                <AppLoadingButton
                  label='Publish edits'
                  isLoading={false}
                  color='secondary'
                  onClick={publish}
                />
              </Grid>
            </Grid>
          </FormProvider>
          {/* Confirm */}
          <ConfirmDialog
            open={confirm.value}
            onClose={(
              event: React.SyntheticEvent<Element, Event>,
              reason: string
            ) => {
              if (reason && reason === "backdropClick") {
                confirm.onFalse();
              }
            }}
            content={`Are you sure you want to ${
              blogId ? "edit" : "add"
            } this blog?`}
            icon={
              <BorderColorOutlinedIcon fontSize='large' sx={{ mx: "auto" }} />
            }
            action={
              <>
                <AppButton
                  fullWidth
                  label='No'
                  color='secondary'
                  onClick={() => {
                    confirm.onFalse();
                  }}
                />
                <AppLoadingButton
                  label='Yes'
                  isLoading={isLoading || isEditing}
                  onClick={() => mutate(dataToSend!)}
                />
              </>
            }
          />
        </AppLoadingAndErrorWrapper>
      </Container>
    </>
  );
};

export default AddBlog;
