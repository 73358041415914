// @mui
import Stack from '@mui/material/Stack'
import { alpha, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
// auth
// hooks
import { useResponsive } from 'src/hooks/use-responsive'
// theme
import { bgGradient } from 'src/theme/css'
// components

// ----------------------------------------------------------------------

type Props = {
  title?: string
  children: React.ReactNode
}

export default function AuthClassicLayout({ children, title }: Props) {
  const theme = useTheme()

  const upMd = useResponsive('up', 'md')

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        px: { xs: 2, md: 8 },
        alignItems: 'center',
      }}
    >
      {children}
    </Stack>
  )

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      width="100%"
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
        height: '100%',
      }}
    >
      <Stack spacing='20px'>
        <Typography
          variant="h1"
          fontWeight={700}
          sx={{ maxWidth: 480, textAlign: 'center' }}
        >
          Days of Iman
        </Typography>
        <Typography
          variant="h5"
          fontWeight={400}
          sx={{ maxWidth: 480, textAlign: 'center' }}
        >
          Assist in increasing the understanding of Islamic principles among a
          greater number of Muslims.
        </Typography>
      </Stack>
    </Stack>
  )

  return (
    <Stack component="main" direction="row" height="100%" alignItems="center">
      {upMd && renderSection}

      {renderContent}
    </Stack>
  )
}
