import {
  alpha,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import { API_URLS, ResDataType } from "src/api/apiUrls";
import useApiServices from "src/api/useApiServices";
import AppButton from "src/components/common/AppButton";
import AppLoadingAndErrorWrapper from "src/components/common/AppLoadingAndErrorWrapper";
import Header from "src/components/Header";
import Iconify from "src/components/iconify/iconify";
import { useSettingsContext } from "src/components/settings";
import { useBoolean } from "src/hooks/use-boolean";
import Restricted from "src/PermissionProvider/Restricted";
import AddProgramCategoryDialog from "src/sections/programs/program-categories/AddProgramCategoryDialog";
import DeleteDialog from "src/components/delete-dialog/DeleteDialog";
import { PrmsEnum } from "src/types/permissions";
import CategoryTopicsTable from "./CategoryTopicsTable";

export default function CategoryTopics () {
  // #region States
  const { themeMode } = useSettingsContext();
  const isLight = themeMode === "light";
  const addDialog = useBoolean();
  const deleteDialog = useBoolean();
  const editDialog = useBoolean();
  const { programCategoryId, programId } = useParams();
  const [currentCategValue, setCurrentCategValue] = useState("");
  const { useGetListApi } = useApiServices();
  const navigate = useNavigate();
  const ICONS = [
    {
      onClick: addDialog.onTrue,
      icon: "simple-line-icons:plus",
      condition: true,
      permission: PrmsEnum.CREATE_PROGRAM_CATEGORY,
    },
    {
      onClick: editDialog.onTrue,
      icon: "ri:edit-2-line",
      condition: programCategoryId,
      permission: PrmsEnum.UPDATE_PROGRAM_CATEGORY,
    },
    {
      onClick: deleteDialog.onTrue,
      icon: "material-symbols:delete-outline",
      condition: programCategoryId,
      permission: PrmsEnum.DELETE_PROGRAM_CATEGORY,
    },
  ];
  // #endregion States

  // #region services
  const { data: programCategories, isLoading: areCategoriesLoading } =
    useGetListApi<ResDataType["programCategoriesType"]>({
      url: API_URLS.PROGRAM_CATEGORIES,
      parameters: {
        programId,
      },
    });
  // #endregion Services

  // #region Handlers
  const handleChangeCateg = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setCurrentCategValue(newValue);
    },
    []
  );
  // #endregion Handlers

  return (
    <>
      <Helmet>
        <title>Topics</title>
      </Helmet>
      <AppLoadingAndErrorWrapper isLoading={areCategoriesLoading}>
        <Grid container spacing={4} my={1}>
          <Grid item xs={12}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent='flex-end'
              alignItems='center'
              spacing={2}
              py={2}
            >
              {ICONS.map(
                icon =>
                  icon.condition && (
                    <Restricted to={icon.permission}>
                      <IconButton
                        onClick={icon.onClick}
                        sx={{ bgcolor: "#ffab8b" }}
                      >
                        <Iconify
                          icon={icon.icon}
                          width='27'
                          height='27'
                          color={isLight ? "black" : "white"}
                        />
                      </IconButton>
                    </Restricted>
                  )
              )}
            </Stack>
            <Stack spacing={2}>
              <Restricted to={PrmsEnum.SHOW_PROGRAM_CATEGORY}>
                <Stack flexDirection='row' spacing={2} alignItems='center'>
                  <Typography variant='h4'>Categories:</Typography>
                  <Tabs
                    value={currentCategValue}
                    onChange={handleChangeCateg}
                    sx={{
                      px: 3,
                      boxShadow: theme =>
                        `inset 0 -2px 0 0 ${alpha(
                          theme.palette.grey[500],
                          0.08
                        )}`,
                      width: "100%",
                    }}
                  >
                    {programCategories?.items.map(tab => (
                      <Tab
                        key={tab.id}
                        value={tab.id}
                        label={tab.name}
                        onClick={() =>
                          navigate(
                            `/dashboard/programs/${programId}/topics/${tab.id}`
                          )
                        }
                      />
                    ))}
                  </Tabs>
                </Stack>
              </Restricted>
              <Restricted to={PrmsEnum.SHOW_PROGRAM_TOPIC}>
                <Grid item xs={12}>
                  {programCategoryId && (
                    <>
                      <Restricted to={PrmsEnum.CREATE_PROGRAM_TOPIC}>
                        <Header
                          header=''
                          buttonLabel='New topic'
                          withButton
                          onCLick={() =>
                            navigate(
                              `/dashboard/programs/${programId}/topics/${programCategoryId}/new`
                            )
                          }
                        />
                      </Restricted>
                      <CategoryTopicsTable />
                    </>
                  )}
                </Grid>
              </Restricted>
            </Stack>
          </Grid>
        </Grid>
      </AppLoadingAndErrorWrapper>
      {/* Add category dialog */}
      <AddProgramCategoryDialog
        open={addDialog.value}
        onClose={addDialog.onFalse}
        dialogTitle='Add a new category'
      />
      {/* Delete category dialog */}
      <DeleteDialog
        open={deleteDialog.value}
        onClose={deleteDialog.onFalse}
        name='program category'
        id={programCategoryId!}
        onSuccess={()=>navigate(`/dashboard/programs/${programId}/topics`)}
        url={API_URLS.PROGRAM_CATEGORIES}
      />
      {/* Edit program category */}
      <AddProgramCategoryDialog
        open={editDialog.value}
        onClose={editDialog.onFalse}
        dialogTitle='Edit program Category'
        id={programCategoryId!}
      />
    </>
  );
}
