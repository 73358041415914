// ----------------------------------------------------------------------

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({
  xs,
  sm,
  md,
  lg,
}: {
  xs?: number;
  sm: number;
  md: number;
  lg: number;
}) {
  return {
    '@media (min-width:0px)': {
      fontSize: pxToRem(xs || sm),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    paragraph: true;
    cardHeader: true;
    cardDescription: true;
    giftHeroHeader: true;
    formInputLabel: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontWeightSemiBold: React.CSSProperties['fontWeight'];
  }
}

// ----------------------------------------------------------------------

// LEARN MORE
// https://nextjs.org/docs/basic-features/font-optimization#google-fonts

export const typography = {
  fontFamily: 'clashDisplay',
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(64),
    ...responsiveFontSizes({ xs: 40, sm: 44, md: 54, lg: 64 }),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(48),
    ...responsiveFontSizes({ xs: 36, sm: 36, md: 40, lg: 48 }),
  },
  h3: {
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ xs: 22, sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ xs: 20, sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'unset',
  },
  paragraph: {
    fontWeight: 400,
    lineHeight: 24 / 14,
    fontSize: pxToRem(24),
    textTransform: 'unset',
    ...responsiveFontSizes({ xs: 16, sm: 18, md: 24, lg: 24 }),
  },
  // Custom sizes
  cardHeader: {
    fontWeight: 500,
    lineHeight: 24 / 14,
    fontSize: pxToRem(30),
    textTransform: 'unset',
    ...responsiveFontSizes({ xs: 24, sm: 28, md: 30, lg: 30 }),
  },
  cardDescription: {
    fontWeight: 400,
    lineHeight: 24 / 14,
    fontSize: pxToRem(18),
    textTransform: 'unset',
    ...responsiveFontSizes({ xs: 16, sm: 18, md: 18, lg: 20 }),
  },
  giftHeroHeader: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(64),
    textTransform: 'unset',
    ...responsiveFontSizes({ xs: 24, sm: 44, md: 54, lg: 64 }),
  },
  formInputLabel: {
    fontWeight: 500,
    lineHeight: 24 / 14,
    fontSize: pxToRem(18),
    textTransform: 'unset',
    ...responsiveFontSizes({ xs: 14, sm: 16, md: 16, lg: 18 }),
  },
};
