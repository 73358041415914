import Checkbox from "@mui/material/Checkbox/Checkbox";
import Switch from "@mui/material/Switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useFormContext } from "react-hook-form";
import AddNewRole from "src/pages/dashboard/roles-and-permissions/roles/AddNewRole";
import { PermissionType } from "src/types/permissions";
import { RoleType } from "src/types/roles";
// ----------------------------------------------------------------------

type Props = {
  row: PermissionType;
  roleData?: RoleType;
};

export default function PermissionsTableRow ({ row }: Props) {
  const createPrm = row.permissions.filter(prm => prm.name === "create")?.[0]
    ?.permission;
  const showPrm = row.permissions.filter(prm => prm.name === "show")?.[0]
    ?.permission;
  const updatePrm = row.permissions.filter(prm => prm.name === "update")?.[0]
    ?.permission;
  const deletePrm = row.permissions.filter(prm => prm.name === "delete")?.[0]
    ?.permission;

  const methods = useFormContext<AddNewRole>();
  const { watch, setValue, clearErrors } = methods

  const handleAddPermission = (permission: string) => {
    if (watch().permissions?.includes(permission)) {
      const index = watch().permissions?.indexOf(permission);
      watch().permissions?.splice(index, 1);
    } else {
      setValue('permissions', [...watch().permissions, permission])
      watch().permissions.length && clearErrors('permissions')
    }
  };

  return (
    <TableRow hover>
      <TableCell sx={{ textAlign: "center" }}>
        <Typography>{row.section}</Typography>
      </TableCell>

      <TableCell sx={{ textAlign: "center" }}>
        {!!showPrm && (
          <Checkbox
            onChange={() => handleAddPermission(showPrm)}
            defaultChecked={watch().permissions?.includes(showPrm)}
          />
        )}
      </TableCell>

      <TableCell sx={{ textAlign: "center" }}>
        {!!createPrm && (
          <Checkbox
            onChange={() => handleAddPermission(createPrm)}
            defaultChecked={watch().permissions?.includes(createPrm)}
          />
        )}
      </TableCell>

      <TableCell sx={{ textAlign: "center" }}>
        {!!updatePrm && (
          <Checkbox
            onChange={() => handleAddPermission(updatePrm)}
            defaultChecked={watch().permissions?.includes(updatePrm)}
          />
        )}
      </TableCell>

      <TableCell sx={{ textAlign: "center" }}>
        {!!deletePrm && (
          <Checkbox
            onChange={() => handleAddPermission(deletePrm)}
            defaultChecked={watch().permissions?.includes(deletePrm)}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
