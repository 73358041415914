import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { Block } from "src/components/Block";
import { RHFTextField } from "src/components/hook-form";

const SubscriptionDetailsForm = () => (
  <Container sx={{ py: 2 }}>
    <Stack spacing={2} textAlign='left'>
      <Block label='First name'>
        <RHFTextField name='firstName' />
      </Block>
      <Block label='Last name'>
        <RHFTextField name='lastName' />
      </Block>
      <Block label='Email'>
        <RHFTextField name='email' />
      </Block>
      <Block label='Mobile phone'>
        <RHFTextField name='mobilePhone' />
      </Block>
    </Stack>
  </Container>
);

export default SubscriptionDetailsForm;
